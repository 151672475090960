import { SubscriptionWindowsSetProdiver } from "../contexts/SubscriptionWindowsTemplateSetContext";
import Dashboard, { DashboardPropsType } from "./Dashboard";
import React from "react";
const SubscriptionWindowsLayout: React.FunctionComponent<DashboardPropsType> = (
  props
) => {
  return (
    <SubscriptionWindowsSetProdiver>
      <Dashboard {...props} />
    </SubscriptionWindowsSetProdiver>
  );
};

export default SubscriptionWindowsLayout;

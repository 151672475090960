import * as React from "react";
import {
  AuthResponse,
  AuthStorage,
  LoginRequest,
  RegisterRequest,
  RegisterResponse,
} from "../types/auth";
import { loginApi, refreshTokenApi, registerApi } from "../utils/api";
import {
  useGetUserInfoWithToken,
  useNewRefreshTokenApi,
} from "../utils/queries";
import { useHistory, useLocation } from "react-router-dom";
import { useLoading } from "./LoadingContext";
import { useQuery, useQueryClient } from "react-query";
import Loader from "../components/Loader";
import queryString from "query-string";
import routes from "../constants/routes";
import useLocalStorageState from "../hooks/useLocalStorageState";
import useSessionStorageState from "../hooks/useSessionStorageState";

const defaultAuthStorage = { token: "", expirationDate: "", rememberMe: false };

const AuthContext = React.createContext<{
  auth: AuthStorage;
  login: (payload: LoginRequest) => Promise<AuthResponse>;
  register: (payload: RegisterRequest) => Promise<RegisterResponse>;
  logout: () => void;
  isEmailVerified: boolean;
}>({
  auth: {} as AuthStorage,
  login: () => Promise.resolve({} as AuthResponse),
  register: () => Promise.resolve({} as RegisterResponse),
  logout: () => {},
  isEmailVerified: true,
});
AuthContext.displayName = "AuthContext";

function AuthProvider(props: Object) {
  const queryClient = useQueryClient();
  const history = useHistory();
  const { search, pathname } = useLocation();
  const userToken = (queryString.parse(search)?.token ?? "").toString();
  const linkExpired =
    (queryString.parse(search)?.link_expired ?? "").toString() === "1";

  const { onSuccess } = useLoading();

  const [authStorage, setAuthStorage] = useLocalStorageState<AuthStorage>(
    "auth",
    defaultAuthStorage
  );
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [staffMemberStorage, setStaffMemberStorage] = useSessionStorageState(
    "staffMember",
    false
  );
  const [isEmailVerified, setIsEmailVerified] = React.useState(true);

  const { token: storageToken, expirationDate, rememberMe } = authStorage;
  const shouldAutoRefreshToken =
    storageToken &&
    expirationDate &&
    new Date(expirationDate) < new Date() &&
    rememberMe;

  const { isLoading, isIdle, isError, isSuccess, status, error } = useQuery(
    "bootstrap",
    refreshTokenApi,
    {
      enabled: !!shouldAutoRefreshToken,
      onSuccess: (data) => {
        setAuthStorage({
          ...authStorage,
          token: data.token,
          expirationDate: data.expiration_date,
        });
      },
    }
  );

  const { isLoading: isLoadingGettoken } = useNewRefreshTokenApi(
    {
      onSuccess: (data) => {
        if (data) {
          setAuthStorage({
            ...authStorage,
            token: data.token,
            expirationDate: data.expiration_date,
            linkExpired,
          });
          history.replace({
            token: "",
          });
        }
      },
    },
    userToken
  );

  const { isLoading: isGetUserInfoWithToken } = useGetUserInfoWithToken(
    storageToken,
    {
      onSuccess: (data) => {
        setAuthStorage({
          ...authStorage,
          email: data?.email,
        });
        setIsEmailVerified(data?.email_verified === true ? true : false);
        if (data?.email_verified === true && pathname === routes.VERIFY_EMAIL) {
          onSuccess("You've successfully confirmed your account.");
        }

        setStaffMemberStorage(data?.is_staff === true);
      },
    }
  );

  const login = React.useCallback(
    async ({
      email,
      password,
      rememberMe,
    }: LoginRequest): Promise<AuthResponse> => {
      const response = await loginApi({ email, password });
      setAuthStorage({
        ...authStorage,
        email,
        token: response.token,
        expirationDate: response.expiration_date,
        rememberMe: !!rememberMe,
        linkExpired: false,
      });
      return response;
    },
    [authStorage, setAuthStorage]
  );

  const register = React.useCallback(
    async (payload: RegisterRequest): Promise<RegisterResponse> => {
      const response = await registerApi(payload);
      return response;
    },
    []
  );

  const logout = React.useCallback(() => {
    setStaffMemberStorage(false);
    queryClient.clear();
    setAuthStorage({
      ...defaultAuthStorage,
      rememberMe: authStorage.rememberMe,
      linkExpired: false,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authStorage.rememberMe, queryClient, setAuthStorage]);

  const value = React.useMemo(
    () => ({
      auth: authStorage,
      login,
      logout,
      register,
      isEmailVerified,
    }),
    [authStorage, login, logout, register, isEmailVerified]
  );

  if (isLoading || isLoadingGettoken || isGetUserInfoWithToken) {
    return <Loader />;
  }

  if (isError) {
    throw error;
  }

  if (isIdle || isSuccess) {
    return <AuthContext.Provider value={value} {...props} />;
  }

  throw new Error(`Unhandled status: ${status}`);
}

function useAuth() {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`);
  }
  return context;
}

export { AuthProvider, useAuth };

const overrides = {
  MuiCard: {
    root: {
      borderRadius: "6px",
      boxShadow:
        "rgba(50, 50, 93, 0.025) 0px 2px 5px -1px, rgba(0, 0, 0, 0.05) 0px 1px 3px -1px",
    },
  },
  MuiPickersBasePicker: {
    container: {
      boxShadow: "0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)",
    },
  },
  MuiPickersToolbar: {
    toolbar: {
      "& h4": {
        fontSize: 32,
        fontWeight: 400,
        lineHeight: "40px",
      },
      "& h3": {
        fontSize: 48,
        lineHeight: "56px",
      },
      backgroundColor: "white",
      color: "black",
    },
  },
  MuiPickersToolbarText: {
    toolbarTxt: {
      fontSize: 16,
      fontWeight: 400,
      lineHeight: "20px",
      color: "#656565",
    },
    toolbarBtnSelected: {
      color: "black",
    },
  },
  MuiPickerDTTabs: {
    tabs: {
      backgroundColor: "white",
      color: "black",
      "& .Mui-selected": {
        backgroundColor: "#EFEFEF",
      },
    },
  },
  MuiInputBase: {
    input: {
      height: 19,
      // FIX-11-06-2021 : PP-1133 Input styles improvement-#1.3
      "-webkit-box-shadow": "0 0 0 30px white inset !important",
    },
  },
  MuiOutlinedInput: {
    input: {
      padding: "15.5px 14px",
    },
    root: {
      "$notchedOutline :not($focused)": {
        borderColor: "#C4C4C4",
      },
      "&$focused $notchedOutline": {
        borderColor: "#656565",
      },
      "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
        borderColor: "#656565",
      },
    },
  },
  MuiAutocomplete: {
    inputRoot: {
      paddingTop: "6px !important",
      paddingBottom: "6px !important",
    },
    popper: {
      boxShadow: "0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)",
    },
  },
  MuiPaper: {
    elevation8: {
      boxShadow: "0 3px 6px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 23%)",
    },
  },
  MuiButton: {
    containedPrimary: {
      "-webkit-text-fill-color": "#FFF",
    },
  },
  // FIX-11-06-2021 : PP-1133 Input styles improvement
  MuiFormHelperText: {
    contained: {
      marginLeft: "0px !important",
      fontSize: "14px !important",
    },
  },
  MuiTablePagination: {
    select: {
      border: "1px solid #656565",
      borderRadius: "3px !important",
    },
  },
};

export default overrides;

import { ArchiveRelatedObj } from "../types/channel";
import { createContext, useContext, useState } from "react";

const ArchiveRelatedContext = createContext<{
  openArchiveRelatedDialog: boolean;
  setOpenArchiveRelatedDialog: Function;
  archiveRelatedObj?: ArchiveRelatedObj;
  setArchiveRelatedObj: Function;
  currentArchive?: string;
  setCurrentArchive: Function;
  currentArchiveName?: string;
  setCurrentArchiveName: Function;
  handleClose: Function;
}>({
  openArchiveRelatedDialog: false,
  setOpenArchiveRelatedDialog: () => {},
  archiveRelatedObj: {},
  setArchiveRelatedObj: () => {},
  currentArchive: "",
  setCurrentArchive: () => {},
  currentArchiveName: "",
  setCurrentArchiveName: () => {},
  handleClose: () => {},
});

const ArchiveRelatedProdiver = (props: Object) => {
  const [openArchiveRelatedDialog, setOpenArchiveRelatedDialog] = useState(
    false
  );
  const [
    archiveRelatedObj,
    setArchiveRelatedObj,
  ] = useState<ArchiveRelatedObj>();
  const [currentArchive, setCurrentArchive] = useState<string>("");
  const [currentArchiveName, setCurrentArchiveName] = useState<string>("");

  const handleClose = () => {
    setCurrentArchive("");
    setCurrentArchiveName("");
    setArchiveRelatedObj({});
    setOpenArchiveRelatedDialog(false);
  };

  return (
    <ArchiveRelatedContext.Provider
      value={{
        handleClose,
        archiveRelatedObj,
        setArchiveRelatedObj,
        openArchiveRelatedDialog,
        setOpenArchiveRelatedDialog,
        currentArchive,
        setCurrentArchive,
        currentArchiveName,
        setCurrentArchiveName,
      }}
      {...props}
    />
  );
};

const useArchiveRelated = () => {
  const context = useContext(ArchiveRelatedContext);

  if (context === undefined) {
    throw new Error(
      `useArchiveRelated must be used within a ArchiveRelatedProdiver`
    );
  }
  return context;
};

export { ArchiveRelatedProdiver, useArchiveRelated };

import "../../vendor/perfect-scrollbar.css";
import {
  Collapse,
  IconButton,
  ListItem,
  ListItemText,
  Box as MuiBox,
  Drawer as MuiDrawer,
  List as MuiList,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { ReactComponent as Logo } from "../../vendor/logo.svg";
import { NavLink, RouteComponentProps, withRouter } from "react-router-dom";
import { RouteChildType, RouteType } from "../../types/routes";
import { makeStyles } from "@material-ui/core/styles";
import { rgba } from "polished";
import { sidebarRoutes as routes } from "../../routes/index";
import { spacing } from "@material-ui/system";
import { useLimitations } from "../../contexts/LimitationsContext";
import { useMenuProdiver } from "../../contexts/MenuContext";
import MenuIcon from "@material-ui/icons/Menu";
import MenuOpenIcon from "@material-ui/icons/MenuOpen";
import PerfectScrollbar from "react-perfect-scrollbar";
import React, { useEffect, useState } from "react";
import SidebarAddButton from "./SidebarAddButton";
import SidebarCategory, { LinkBadge } from "./SidebarCategory";
import UserButton from "./UserButton";
import cn from "classnames";
import styled from "styled-components/macro";

const Box = styled(MuiBox)(spacing);

const Drawer = styled(MuiDrawer)`
  border-right: 0;
  overflow: hidden;
  > div {
    border-right: 0;
  }
`;

const Scrollbar = styled(PerfectScrollbar)`
  background-color: ${(props) => props.theme.sidebar.background};
  border-right: 1px solid rgba(0, 0, 0, 0.12);
`;

const List = styled(MuiList)`
  background-color: ${(props) => props.theme.sidebar.background};
`;

const Items = styled.div`
  overflow: hidden;
  padding-top: ${(props) => props.theme.spacing(2.5)}px;
  padding-bottom: ${(props) => props.theme.spacing(2.5)}px;
  svg {
    color: ${(props) => props.theme.sidebar.color};
  }
`;

const Brand = styled(ListItem)<{
  button?: boolean;
  component?: React.ReactNode;
  to?: string;
}>`
  font-size: 20px;
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  color: ${(props) => props.theme.sidebar.header.color};
  background-color: ${(props) => props.theme.sidebar.header.background};
  font-family: ${(props) => props.theme.typography.fontFamily};
  min-height: 56px;
  padding-left: ${(props) => props.theme.spacing(3)}px;
  padding-right: ${(props) => props.theme.spacing(8)}px;
  padding-top: ${(props) => props.theme.spacing(2.5)}px;
  padding-bottom: ${(props) => props.theme.spacing(2.5)}px;
  cursor: pointer;

  ${(props) => props.theme.breakpoints.up("sm")} {
    min-height: 64px;
  }

  &:hover {
    background-color: ${(props) => props.theme.sidebar.header.background};
  }
`;

const BrandIcon = styled(Logo)`
  margin-right: ${(props) => props.theme.spacing(2)}px;
  color: ${(props) => props.theme.sidebar.header.brand.color};
  fill: ${(props) => props.theme.sidebar.header.brand.color};
  width: 48px;
  height: 48px;
`;

const Link = styled(ListItem)<{
  activeClassName: string;
  component: typeof NavLink;
  exact: boolean;
  to: string;
}>`
  padding-left: ${(props) => props.theme.spacing(15)}px;
  padding-top: ${(props) => props.theme.spacing(5)}px;
  padding-bottom: ${(props) => props.theme.spacing(5)}px;
  padding-right: ${(props) => props.theme.spacing(7)}px;
  span {
    color: ${(props) => rgba(props.theme.sidebar.color, 0.9)};
  }

  &:hover span {
    color: ${(props) => rgba(props.theme.sidebar.color, 0.9)};
  }

  &:hover {
    background-color: #353535;
  }

  &.${(props) => props.activeClassName} {
    background-color: #353535;

    span {
      color: ${(props) => props.theme.sidebar.color};
    }
  }
`;

const LinkText = styled(ListItemText)`
  color: ${(props) => props.theme.sidebar.color};
  span {
    font-size: 13px;
  }
  margin-top: 0;
  margin-bottom: 0;
  padding: 0 12px;
`;

const SidebarSection = styled(Typography)`
  color: ${(props) => props.theme.sidebar.color};
  padding: ${(props) => props.theme.spacing(4)}px
    ${(props) => props.theme.spacing(7)}px
    ${(props) => props.theme.spacing(1)}px;
  opacity: 0.9;
  display: block;
`;

const useStyles = makeStyles((theme) => ({
  drawer: {
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: ({ style: { width } }: any) => width,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: 80,
  },
  tooltip: {
    fontSize: "1em",
    marginLeft: "0px",
  },
}));

type SidebarLinkPropsType = {
  name: string;
  to: string;
  badge?: string | number;
  icon?: JSX.Element;
  id?: string;
  disabled?: boolean;
};

const SidebarLink: React.FC<SidebarLinkPropsType> = ({
  name,
  to,
  badge,
  id,
  icon,
  disabled,
}) => {
  return (
    <Link
      button
      dense
      component={NavLink}
      exact
      to={to}
      activeClassName="active"
      id={id}
      style={{ padding: "8px 60px" }}
      disabled={disabled}
    >
      {icon}
      <LinkText>{name}</LinkText>
      {badge ? <LinkBadge label={badge} /> : ""}
    </Link>
  );
};

type SidebarPropsType = {
  staticContext: string;
  location: {
    pathname: string;
  };
  routes?: Array<RouteType>;
  PaperProps: {
    style: {
      width: number;
    };
  };
  variant?: "permanent" | "persistent" | "temporary";
  open?: boolean;
  onClose?: () => void;
};

type tplotOptions = {
  [key: number]: boolean;
};

const Sidebar: React.FC<RouteComponentProps & SidebarPropsType> = ({
  staticContext,
  location,
  PaperProps,
  ...rest
}) => {
  const classes = useStyles(PaperProps);
  const { isSuspended, staffMemberStorageLimit } = useLimitations();
  const { setIsOpen } = useMenuProdiver();
  const [drawerOpen, setDrawerOpen] = useState(true);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // const [staffMemberStorage] = useSessionStorageState("staffMember", false);
  const initOpenRoutes = (): tplotOptions => {
    /* Open collapse element that matches current url */
    const pathName = location.pathname;

    let _routes = {};

    routes.forEach((route: RouteType, index) => {
      const isActive = pathName.indexOf(route.path) === 0;
      const isOpen = route.open;
      const isHome = route.containsHome && pathName === "/";

      _routes = Object.assign({}, _routes, {
        [index]: isActive || isOpen || isHome,
      });
    });

    return _routes;
  };

  const [openRoutes, setOpenRoutes] = useState(() => initOpenRoutes());

  const toggle = (index: number) => {
    // Collapse all elements
    Object.keys(openRoutes).forEach(
      (item) =>
        openRoutes[index] ||
        setOpenRoutes((openRoutes) =>
          Object.assign({}, openRoutes, { [item]: false })
        )
    );

    // Toggle selected element
    setOpenRoutes((openRoutes) =>
      Object.assign({}, openRoutes, { [index]: !openRoutes[index] })
    );
  };

  useEffect(() => {
    setIsOpen(drawerOpen);
  }, [drawerOpen, setIsOpen]);

  return (
    <Drawer
      {...rest}
      variant="permanent"
      className={cn(classes.drawer, {
        [classes.drawerOpen]: drawerOpen,
        [classes.drawerClose]: !drawerOpen,
      })}
      classes={{
        paper: cn({
          [classes.drawerOpen]: drawerOpen,
          [classes.drawerClose]: !drawerOpen,
        }),
      }}
    >
      <List disablePadding>
        <Items>
          <Box
            display="flex"
            alignItems="center"
            paddingLeft={3}
            paddingY={drawerOpen ? 0 : 2.5}
          >
            <Tooltip
              title={drawerOpen ? "" : "Expand"}
              placement="right"
              classes={{ tooltip: classes.tooltip }}
            >
              <IconButton
                onClick={() => {
                  setDrawerOpen((prev) => !prev);
                }}
              >
                {drawerOpen ? <MenuIcon /> : <MenuOpenIcon />}
              </IconButton>
            </Tooltip>
            {drawerOpen && (
              <Brand component={NavLink} to="/" button disableGutters>
                <BrandIcon /> <Box ml={1}>Nashpush</Box>
              </Brand>
            )}
          </Box>
        </Items>
      </List>
      <List disablePadding>
        <Items>
          <UserButton drawerOpen={drawerOpen} />
        </Items>
      </List>
      <SidebarAddButton
        drawerOpen={drawerOpen}
        isSuspended={isSuspended}
        isMobile={false}
      />
      <Scrollbar>
        <List disablePadding>
          <Items>
            {routes.map((category: RouteType, index) => (
              <React.Fragment key={index}>
                {category.header ? (
                  <SidebarSection>{category.header}</SidebarSection>
                ) : null}

                {category.children && category.icon ? (
                  <React.Fragment key={index}>
                    <SidebarCategory
                      path={category.path}
                      isOpen={!openRoutes[index]}
                      isCollapsable={true}
                      name={category.id}
                      icon={category.icon}
                      onClick={() => toggle(index)}
                      id={category.autoId}
                      miniCategory={!drawerOpen}
                      routeChildren={category.children}
                      isSuspended={isSuspended}
                    />

                    {drawerOpen && (
                      <Collapse
                        in={openRoutes[index]}
                        timeout="auto"
                        unmountOnExit
                      >
                        {category.children.map(
                          (route: RouteChildType, index: number) =>
                            staffMemberStorageLimit || !route.onlyForAdmin ? (
                              <SidebarLink
                                key={index}
                                name={route.name}
                                to={route.path}
                                icon={route.icon}
                                badge={route.badge}
                                id={route.autoId}
                                disabled={!route.path}
                              />
                            ) : undefined
                        )}
                      </Collapse>
                    )}
                  </React.Fragment>
                ) : category.icon &&
                  (staffMemberStorageLimit || !category.onlyForAdmin) ? (
                  <SidebarCategory
                    name={category.id}
                    to={category.path}
                    activeClassName="active"
                    component={NavLink}
                    icon={category.icon}
                    exact
                    badge={category.badge}
                    id={category.autoId}
                    miniCategory={!drawerOpen}
                    isSuspended={isSuspended}
                  />
                ) : null}
              </React.Fragment>
            ))}
          </Items>
        </List>
      </Scrollbar>
      <List disablePadding>
        <Items>
          <SidebarCategory
            name="Contact Us "
            icon={
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.875 22L12.625 19.25H11.625C9.24167 19.25 7.20833 18.4083 5.525 16.725C3.84167 15.0417 3 13.0083 3 10.625C3 8.24167 3.84583 6.20833 5.5375 4.525C7.22917 2.84167 9.275 2 11.675 2C12.8583 2 13.9542 2.2125 14.9625 2.6375C15.9708 3.0625 16.85 3.65833 17.6 4.425C18.35 5.19167 18.9375 6.09583 19.3625 7.1375C19.7875 8.17917 20 9.31667 20 10.55C20 11.65 19.8375 12.75 19.5125 13.85C19.1875 14.95 18.7167 16 18.1 17C17.4833 18 16.7333 18.925 15.85 19.775C14.9667 20.625 13.975 21.3667 12.875 22ZM14.125 19.3C15.4917 18.15 16.5625 16.7958 17.3375 15.2375C18.1125 13.6792 18.5 12.1167 18.5 10.55C18.5 8.48333 17.8542 6.79167 16.5625 5.475C15.2708 4.15833 13.6417 3.5 11.675 3.5C9.675 3.5 7.97917 4.19583 6.5875 5.5875C5.19583 6.97917 4.5 8.65833 4.5 10.625C4.5 12.5917 5.19583 14.2708 6.5875 15.6625C7.97917 17.0542 9.65833 17.75 11.625 17.75H14.125V19.3ZM11.675 16.425C11.9417 16.425 12.1667 16.3333 12.35 16.15C12.5333 15.9667 12.625 15.7417 12.625 15.475C12.625 15.2083 12.5333 14.9833 12.35 14.8C12.1667 14.6167 11.9417 14.525 11.675 14.525C11.4083 14.525 11.1833 14.6167 11 14.8C10.8167 14.9833 10.725 15.2083 10.725 15.475C10.725 15.7417 10.8167 15.9667 11 16.15C11.1833 16.3333 11.4083 16.425 11.675 16.425ZM11 13.025H12.25C12.25 12.6083 12.3208 12.2625 12.4625 11.9875C12.6042 11.7125 12.9 11.35 13.35 10.9C13.8 10.45 14.1167 10.0375 14.3 9.6625C14.4833 9.2875 14.575 8.88333 14.575 8.45C14.575 7.7 14.3208 7.08333 13.8125 6.6C13.3042 6.11667 12.625 5.875 11.775 5.875C11.075 5.875 10.45 6.05833 9.9 6.425C9.35 6.79167 8.94167 7.29167 8.675 7.925L9.825 8.4C10.0083 7.96667 10.2625 7.64583 10.5875 7.4375C10.9125 7.22917 11.2833 7.125 11.7 7.125C12.2 7.125 12.5917 7.24583 12.875 7.4875C13.1583 7.72917 13.3 8.05 13.3 8.45C13.3 8.76667 13.2083 9.0875 13.025 9.4125C12.8417 9.7375 12.5167 10.1417 12.05 10.625C11.6 11.0917 11.3125 11.4667 11.1875 11.75C11.0625 12.0333 11 12.4583 11 13.025Z"
                  fill="white"
                />
              </svg>
            }
            onClick={() => {
              window.location.href = "mailto:support@nashpush.com";
            }}
            id="menuUser"
            miniCategory={!drawerOpen}
            isSuspended={isSuspended}
          />
        </Items>
      </List>
    </Drawer>
  );
};

export default withRouter(Sidebar);

import { UserPageConfig } from "../types/dashboard";
import { createContext, useCallback, useContext, useState } from "react";
import { useAuth } from "./AuthContext";
import {
  useCreatePageConfig,
  useListPageConfig,
  useUpdatePageConfig,
} from "../utils/queries";

const PageConfigsContext = createContext<{
  pageConfig: UserPageConfig[] | null;
  setUserPageConfig: (page: UserPageConfig) => void;
  getUserPageConfig: (name: string) => UserPageConfig | null;
  createUserPageConfig: (
    page: Omit<UserPageConfig, "id">
  ) => UserPageConfig | null;
}>({
  pageConfig: [],
  setUserPageConfig: () => null,
  getUserPageConfig: () => null,
  createUserPageConfig: () => null,
});

const PageConfigsProdiver = (props: Object) => {
  const { auth } = useAuth();
  const [pageConfig, setPageConfig] = useState<UserPageConfig[] | null>(null);
  const { mutate: updatePageConfig } = useUpdatePageConfig();
  const { mutate: createPageConfig } = useCreatePageConfig({
    onSuccess: (data) => {
      if (data) {
        setPageConfig([...(pageConfig ?? []), data]);
      }
    },
  });

  useListPageConfig(auth.token, {
    onSuccess: (data) => {
      if (data) {
        setPageConfig(data);
      }
    },
  });

  const setUserPageConfig = useCallback(
    (page: UserPageConfig) => {
      if (page && !!pageConfig) {
        pageConfig.forEach((config) => {
          if (config.page === page.page) {
            config.agrid_config = page.agrid_config;
          }
        });
        updatePageConfig(page);
      }
    },
    [pageConfig, updatePageConfig]
  );

  const getUserPageConfig = useCallback(
    (name: string) => {
      return pageConfig?.find((config) => config.page === name) ?? null;
    },
    [pageConfig]
  );

  const createUserPageConfig = useCallback(
    (page: Omit<UserPageConfig, "id">) => {
      if (page && !!pageConfig) {
        let pageTmp = pageConfig.find((config) => config.page === page.page);
        if (pageTmp) {
          return pageTmp;
        } else {
          createPageConfig(page);
        }
      }
      return null;
    },
    [createPageConfig, pageConfig]
  );

  return (
    <PageConfigsContext.Provider
      value={{
        pageConfig,
        setUserPageConfig,
        getUserPageConfig,
        createUserPageConfig,
      }}
      {...props}
    />
  );
};

const usePageConfigs = () => {
  const context = useContext(PageConfigsContext);

  if (context === undefined) {
    throw new Error(`useCampaignSet must be used within a PageConfigsProdiver`);
  }
  return context;
};

export { PageConfigsProdiver, usePageConfigs };

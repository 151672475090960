const env = {
  API_URL: process.env.REACT_APP_API_URL,
  AG_GRID_KEY: process.env.REACT_APP_AG_GRID_KEY,
  POSTBACK_URL: process.env.REACT_APP_POSTBACK_URL,
  WORKER_URL: process.env.REACT_APP_WORKER_URL,
  NASHPUSH_DASH_VERSION: process.env.NASHPUSH_DASH_VERSION,
  ANDROID_SDK_VERSION: process.env.REACT_APP_ANDROID_SDK_VERSION,
  NASHPUSH_ENVIRONMENT: process.env.REACT_APP_NASHPUSH_ENVIRONMENT,
  API_MANAGER_URL: process.env.REACT_APP_API_MANAGER_URL,
};

export const API_URL: string | undefined = env.API_URL;
export const AG_GRID_KEY: string | undefined = env.AG_GRID_KEY;
export const POSTBACK_URL: string | undefined = env.POSTBACK_URL;
export const WORKER_URL: string | undefined = env.WORKER_URL;
export const NASHPUSH_DASH_VERSION: string | undefined =
  env.NASHPUSH_DASH_VERSION;
export const ANDROID_SDK_VERSION: string | undefined = env.ANDROID_SDK_VERSION;
export const NASHPUSH_ENVIRONMENT: string | undefined =
  env.NASHPUSH_ENVIRONMENT;
export const API_MANAGER_URL: string | undefined = env.API_MANAGER_URL;

import { Button, Grid, Paper, Typography } from "@material-ui/core";
import { goToPaymentDetailsPage } from "../../utils/routes";
import { useHistory } from "react-router-dom";
import React, { FunctionComponent } from "react";

export interface SuspendedBackdropProps {}

export const SuspendedBackdrop: FunctionComponent<SuspendedBackdropProps> = () => {
  const history = useHistory();

  return (
    <Paper
      variant="outlined"
      style={{
        position: "absolute",
        display: "flex",
        background: "rgba(255, 255, 255, 0.9)",
        zIndex: 99999,
        width: "100%",
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Grid
        justify="space-between"
        alignItems="center"
        direction="column"
        container
        spacing={6}
        style={{
          width: "100%",
        }}
      >
        <Grid item>
          <Typography
            align="center"
            variant="h3"
            style={{ lineHeight: "32px", fontWeight: 500 }}
          >
            Your account is suspended
          </Typography>
        </Grid>
        <Grid item style={{ maxWidth: "600px" }}>
          <Typography
            variant="subtitle1"
            align="center"
            style={{ marginBottom: 16 }}
          >
            Subscribers are no longer collected and campaigns have stopped.
          </Typography>
          <Typography variant="subtitle1" align="center">
            To reactivate access to your subscribers and data, review your
            billing information and provide a working payment method.
          </Typography>
        </Grid>
        <Grid item style={{ alignSelf: "center" }}>
          <Grid container spacing={4}>
            <Grid item>
              <Button
                id="ContactSupport"
                variant="outlined"
                style={{
                  height: 40,
                  border: "1px solid #000000",
                  borderRadius: "4px",
                  padding: "12px 24px",
                }}
                onClick={() => {
                  window.location.href = "mailto:support@nashpush.com";
                }}
              >
                Contact Support
              </Button>
            </Grid>
            <Grid item>
              <Button
                id="Updatebillinginfo"
                variant="contained"
                color="primary"
                style={{
                  height: 40,
                  borderRadius: "4px",
                  padding: "12px 24px",
                }}
                onClick={() => {
                  goToPaymentDetailsPage(history);
                }}
              >
                <Typography
                  style={{
                    fontSize: 14,
                    lineHeight: "16px",
                  }}
                >
                  Update billing info
                </Typography>
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

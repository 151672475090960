import { Journey } from "../types/journey";
import { ParameterContext, SetContext } from "../types/channel";
import { createContext, useContext, useState } from "react";
import { useListAllJourneyAutocomplete } from "../utils/queries";
import _ from "lodash";

const JourneyDashboardSetContext = createContext<{
  journey: Journey[];
  total: number;
  isNoJourney: boolean;
  keySets: SetContext;
  isOpen: boolean;
  setIsOpen: Function;
  setJourneyObject: Function;
  journeyObject: Journey;
}>({
  total: 0,
  journey: [],
  isNoJourney: false,
  keySets: {},
  isOpen: false,
  setIsOpen: () => {},
  setJourneyObject: () => {},
  journeyObject: {},
});

const JourneyDashboardSetProdiver = (props: Object) => {
  const [total, setTotal] = useState(0);
  const [journey, setJourney] = useState<Journey[]>([]);
  const [isNoJourney, setIsNoJourney] = useState(false);
  const [journeyObject, setJourneyObject] = useState<Journey>({});
  const [keySets, setKeySets] = useState({} as SetContext);
  const [isOpen, setIsOpen] = useState(false);

  useListAllJourneyAutocomplete({
    onSuccess: (data) => {
      if (data) {
        setJourney(data);
        setTotal(data.length);
        setIsNoJourney(data.length === 0);
        const attributes = _.transform(
          data,
          (result, attribute) => {
            _.forEach(attribute, (value, key) => {
              if (typeof value === "string" && key !== "status") {
                if (!result[key]) {
                  result[key] = [];
                }
                if (value && !result[key].includes(value)) {
                  result[key].push(value);
                }
              }
            });
          },
          {} as ParameterContext
        );
        setKeySets(attributes);
      }
    },
  });

  return (
    <>
      <JourneyDashboardSetContext.Provider
        value={{
          total,
          journey,
          isNoJourney,
          keySets,
          isOpen,
          setIsOpen,
          journeyObject,
          setJourneyObject,
        }}
        {...props}
      />
    </>
  );
};

const useJourneyDashboardSet = () => {
  const context = useContext(JourneyDashboardSetContext);

  if (context === undefined) {
    throw new Error(
      `useJourneyDashboardSet must be used within a JourneyDashboardSetProdiver`
    );
  }
  return context;
};

export { JourneyDashboardSetProdiver, useJourneyDashboardSet };

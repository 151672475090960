import { DialogContentProps } from "@material-ui/core";
import { Theme, createStyles, withStyles } from "@material-ui/core/styles";
import MuiDialogActions from "@material-ui/core/DialogActions";

const styles = (theme: Theme) =>
  createStyles({
    root: { marginTop: 10, marginLeft: 16 },
  });

const DialogActions = withStyles(styles)(
  ({ children, ...props }: DialogContentProps) => {
    return <MuiDialogActions {...props}>{children}</MuiDialogActions>;
  }
);

export default DialogActions;

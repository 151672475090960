import { ChannelSetProdiver } from "../contexts/ChannelSetContext";
import Dashboard, { DashboardPropsType } from "./Dashboard";
import React from "react";

const UserBaseLayout: React.FunctionComponent<DashboardPropsType> = (props) => {
  return (
    <ChannelSetProdiver>
      <Dashboard {...props} />
    </ChannelSetProdiver>
  );
};

export default UserBaseLayout;

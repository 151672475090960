import Footer from "../components/Footer";
import React from "react";
import styled from "styled-components/macro";
// import Settings from "../components/Settings";

import {
  LinearProgress,
  Paper as MuiPaper,
  withWidth,
} from "@material-ui/core";
import { spacing } from "@material-ui/system";

import { SuspendedBackdrop } from "../components/renders/SuspendedBackdrop";
import { useLimitations } from "../contexts/LimitationsContext";
import { useLoading } from "../contexts/LoadingContext";

const AppContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  ${(props) => props.theme.breakpoints.up("sm")} {
    height: 100%;
  }
  ${(props) => props.theme.breakpoints.down("sm")} {
    height: calc(100% - 88px);
  }
  position: relative;
`;

const Paper = styled(MuiPaper)(spacing);

const MainContent = styled(Paper)`
  flex: 1;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background: ${(props) => props.theme.palette.background.default};
  ${(props) => props.theme.breakpoints.up("sm")} {
    padding: 40px 40px 0px 40px;
  }
  ${(props) => props.theme.breakpoints.down("sm")} {
    padding: 24px 16px 0px 24px;
  }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }

  .MuiPaper-root .MuiPaper-root {
    box-shadow: none;
  }
`;

export type DashboardPropsType = {
  showBackdrop: boolean;
  footer?: boolean;
  children: any;
  background?: string;
};

const Dashboard: React.FC<DashboardPropsType> = ({
  children,
  showBackdrop = true,
  footer = true,
  background,
}) => {
  const { isLoading } = useLoading();
  const { isSuspended } = useLimitations();

  return (
    <AppContent>
      {showBackdrop && isSuspended && <SuspendedBackdrop />}
      {isLoading && <LinearProgress />}
      <MainContent
        style={background ? { backgroundColor: background } : undefined}
      >
        {children}
      </MainContent>
      {footer ? <Footer /> : null}
    </AppContent>
  );
};

export default withWidth()(Dashboard);

import { SubscriptionWindow } from "../types/channel";
import { UseFormMethods } from "react-hook-form";
import { difference } from "./channelServices";
import { registerField } from "./forms";

export const registerSubscriptionWindowFields = (
  data: SubscriptionWindow | undefined,
  form: UseFormMethods<Record<string, any>>
) => {
  form.reset(data);
  if (data?.id) {
    registerField(form, "id", data?.id);
  }

  registerField(form, "name", data?.name ?? "");
  registerField(form, "description", data?.description ?? "");
  registerField(form, "window_image", data?.window_image);
  registerField(form, "image_url", data?.image_url);
  registerField(form, "confirm_button_text", data?.confirm_button_text);
  registerField(form, "cancel_button_text", data?.cancel_button_text ?? "");
  registerField(form, "appearance_event", data?.appearance_event);
  registerField(form, "message", data?.message ?? "");
  const windowStyles = data?.styles;
  registerField(
    form,
    "styles.confirm_button_color",
    windowStyles?.confirm_button_color ?? "#2b67e8"
  );
  registerField(
    form,
    "styles.confirm_text_color",
    windowStyles?.confirm_text_color ?? "#FFFFFF"
  );
  registerField(
    form,
    "styles.cancel_button_color",
    windowStyles?.cancel_button_color ?? "#FFFFFF"
  );
  registerField(
    form,
    "styles.cancel_text_color",
    windowStyles?.cancel_text_color ?? "#000000"
  );
  registerField(form, "status", "draft");
};

export const checkChangeSave = (
  SubscriptionWindow: SubscriptionWindow,
  originSubscriptionWindow: SubscriptionWindow | undefined,
  isDraft: boolean
) => {
  if (!!originSubscriptionWindow) {
    const pureSubscriptionWindow = makePureSubscriptionWindow(
      SubscriptionWindow,
      originSubscriptionWindow
    ) as SubscriptionWindow;

    const newSubscriptionWindow = provideDefaultValue(
      pureSubscriptionWindow,
      originSubscriptionWindow,
      isDraft
    );

    return newSubscriptionWindow;
  }
  return null;
};

export const makePureSubscriptionWindow = (
  object: SubscriptionWindow,
  base: SubscriptionWindow | undefined
) => {
  if (base) {
    const diffObject = difference(object, base);
    return diffObject;
  }
  return object;
};

export const provideDefaultValue = (
  object: SubscriptionWindow,
  base: SubscriptionWindow,
  isDraft: boolean
): SubscriptionWindow => {
  const result = {
    ...object,
  } as any;

  Object.keys(result).forEach(
    (key) => result[key] === undefined && delete result[key]
  );
  return result;
};

export function convertData(
  subscriptionWindow: SubscriptionWindow,
  show_after_cancel_period: any,
  appearance_timeout_period: any
) {
  if (subscriptionWindow?.show_after_cancel?.every !== undefined) {
    if (!!show_after_cancel_period) {
      subscriptionWindow.show_after_cancel.period = show_after_cancel_period;
    } else {
      subscriptionWindow.show_after_cancel.period = "days";
    }
  }
  if (subscriptionWindow?.appearance_timeout && !!appearance_timeout_period) {
    subscriptionWindow.appearance_timeout = fancyTimeFormat(
      subscriptionWindow?.appearance_timeout,
      appearance_timeout_period
    );
  }
  if (
    !subscriptionWindow?.show_after_cancel ||
    subscriptionWindow?.show_after_cancel === 0
  ) {
    subscriptionWindow.show_after_cancel = undefined;
  }
  return subscriptionWindow;
}

export function fancyTimeFormat(duration: number, unitTo: string) {
  if (unitTo === "microseconds") {
    return duration / 1000000;
  } else if (unitTo === "minutes") {
    return duration * 60;
  } else if (unitTo === "hours") {
    return duration * 3600;
  } else if (unitTo === "days") {
    return duration * 86400;
  }
  return duration;
}

export const arrayMove = (arr: any[], old_index: number, new_index: number) => {
  if (new_index >= arr.length) {
    var k = new_index - arr.length + 1;
    while (k--) {
      arr.push(undefined);
    }
  }
  arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
  return arr;
};

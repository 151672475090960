import {
  Box,
  Menu,
  MenuItem,
  Divider as MuiDivider,
  Typography,
} from "@material-ui/core";
import {
  goToFirebaseAccountsDashboardPage,
  goToPaymentDetailsPage,
} from "../../utils/routes";
import { useAuth } from "../../contexts/AuthContext";
import { useGetUserInfo, useNotificationsCount } from "../../utils/queries";
import { useHistory } from "react-router-dom";
import { useLimitations } from "../../contexts/LimitationsContext";
import { useSnackbar } from "notistack";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import NotificationDialog from "../dialogs/NotificationDialog";
import PersonIcon from "@material-ui/icons/Person";
import React, { useState } from "react";
// import ShareIcon from "@material-ui/icons/Share";
import SidebarCategory from "./SidebarCategory";
import SkypeSupportDialog from "../dialogs/SkypeSupportDialog";
import routes from "../../constants/routes";
import styled from "styled-components";
import useSessionStorageState from "../../hooks/useSessionStorageState";

const Divider = styled(MuiDivider)`
  border: 1px solid rgb(246 233 233 / 12%);
`;

const Item = styled(MenuItem)`
  padding: 10px;
`;

const MenuText = styled(Typography)`
  margin-left: 10px;
`;
const UserButtonBox = styled(Box)`
  &:hover {
    background-color: #353535;
  }
`;

const UserButton: React.FunctionComponent<{
  drawerOpen: boolean;
}> = ({ drawerOpen }) => {
  const history = useHistory();
  const auth = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const { setIsSuspended } = useLimitations();
  const [anchorEl, setAnchorEl] = useState<
    Element | ((element: Element) => Element) | null | undefined
  >(null);

  const [notificationAnchorEl, setNotificationAnchorEl] = useState<
    Element | ((element: Element) => Element) | null | undefined
  >(null);

  const [openSupportDialog, setOpenSupportDialog] = useState(false);
  const [userName, setUserName] = useState("User");
  const handleClick = (event?: React.MouseEvent<Element, MouseEvent>) => {
    setAnchorEl(event?.currentTarget);
  };

  const [notificationCount, setNotificationCount] = useState<number>(0);
  const { setOpenLimitDialog, isLimit } = useLimitations();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [staffMemberStorage, setStaffMemberStorage] = useSessionStorageState(
    "staffMember",
    false
  );

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNotificationClick = (
    event?: React.MouseEvent<Element, MouseEvent>
  ) => {
    setNotificationAnchorEl(event?.currentTarget);
  };

  const handleNotificationClose = () => {
    setNotificationAnchorEl(null);
  };

  const handleLogout = () => {
    auth.logout();
    enqueueSnackbar("You have successfully logged out", {
      variant: "success",
    });
  };

  const { isLoading: isGetUserInfoLoading } = useGetUserInfo({
    onSuccess: (data) => {
      setUserName(data?.name ?? "User");
      setStaffMemberStorage(data?.is_staff ?? false);
      // tmp
      setIsSuspended(data?.suspended);
    },
  });

  const menus = [
    {
      id: "menuUserProfile",
      Icon: PersonIcon,
      title: "Profile details",
      action: () => {
        handleClose();
        history.push(routes.PROFILE);
      },
    },
    {
      id: "menuUserRequest",
      Icon: () => {
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2.5 20C2.08333 20 1.72917 19.8542 1.4375 19.5625C1.14583 19.2708 1 18.9167 1 18.5V7H2.5V18.5H20V20H2.5ZM5.5 17C5.08333 17 4.72917 16.8542 4.4375 16.5625C4.14583 16.2708 4 15.9167 4 15.5V5.5C4 5.08333 4.14583 4.72917 4.4375 4.4375C4.72917 4.14583 5.08333 4 5.5 4H21.5C21.9167 4 22.2708 4.14583 22.5625 4.4375C22.8542 4.72917 23 5.08333 23 5.5V15.5C23 15.9167 22.8542 16.2708 22.5625 16.5625C22.2708 16.8542 21.9167 17 21.5 17H5.5ZM8 15.5C8 14.8 7.75833 14.2083 7.275 13.725C6.79167 13.2417 6.2 13 5.5 13V15.5H8ZM19 15.5H21.5V13C20.8 13 20.2083 13.2417 19.725 13.725C19.2417 14.2083 19 14.8 19 15.5ZM13.5 13.5C14.3333 13.5 15.0417 13.2083 15.625 12.625C16.2083 12.0417 16.5 11.3333 16.5 10.5C16.5 9.66667 16.2083 8.95833 15.625 8.375C15.0417 7.79167 14.3333 7.5 13.5 7.5C12.6667 7.5 11.9583 7.79167 11.375 8.375C10.7917 8.95833 10.5 9.66667 10.5 10.5C10.5 11.3333 10.7917 12.0417 11.375 12.625C11.9583 13.2083 12.6667 13.5 13.5 13.5ZM5.5 8C6.2 8 6.79167 7.75833 7.275 7.275C7.75833 6.79167 8 6.2 8 5.5H5.5V8ZM21.5 8V5.5H19C19 6.2 19.2417 6.79167 19.725 7.275C20.2083 7.75833 20.8 8 21.5 8Z"
              fill="black"
            />
          </svg>
        );
      },
      title: "Payment",
      action: () => {
        handleClose();
        goToPaymentDetailsPage(history);
      },
      color: "#000000",
    },
    {
      id: "menuFirebaseAccounts",
      Icon: () => {
        return (
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.36828 15.4397L6.5925 1.18641C6.66937 0.700321 7.32141 0.585478 7.55156 1.02001L9.94219 5.49423L4.36828 15.4397ZM20.25 18.75L18.0591 5.77548C18.0432 5.68274 18.0022 5.59611 17.9406 5.52499C17.879 5.45386 17.7991 5.40095 17.7096 5.37198C17.6201 5.343 17.5243 5.33908 17.4328 5.36063C17.3412 5.38218 17.2572 5.42838 17.19 5.49423L3.75 18.75L11.22 23.0583C11.4492 23.184 11.7065 23.2499 11.9679 23.2499C12.2293 23.2499 12.4866 23.184 12.7158 23.0583L20.25 18.75ZM14.1731 7.44985L12.4603 4.19017C12.4177 4.10643 12.3528 4.03611 12.2727 3.98699C12.1926 3.93787 12.1005 3.91187 12.0066 3.91187C11.9126 3.91187 11.8205 3.93787 11.7404 3.98699C11.6603 4.03611 11.5954 4.10643 11.5528 4.19017L4.02328 17.6128L14.1731 7.44985Z"
              fill="black"
            />
          </svg>
        );
      },
      title: "Firebase accounts",
      action: () => {
        handleClose();
        goToFirebaseAccountsDashboardPage(history);
      },
    },
    {
      id: "menuUserLogout",
      Icon: ExitToAppIcon,
      title: "Log out",
      action: handleLogout,
    },
  ];

  const {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    isLoading: notificationsLoading,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    isFetching: isNotificationsFetching,
  } = useNotificationsCount({
    onSuccess: (data) => {
      setNotificationCount(data?.channels_pending_count ?? 0);
    },
  });

  return (
    <UserButtonBox>
      <Divider />
      <SidebarCategory
        isSuspended={false}
        name={userName}
        icon={<PersonIcon />}
        onClick={handleClick}
        id="menuUser"
        miniCategory={!drawerOpen}
        orderOnClick={handleNotificationClick}
        orderIcon={
          <Box
            display="flex"
            padding="3px"
            style={{
              background: "#353535",
              borderRadius: "16px",
              height: "24px",
            }}
          >
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9 16.5C9.825 16.5 10.5 15.825 10.5 15H7.5C7.5 15.825 8.1675 16.5 9 16.5ZM13.5 12V8.25C13.5 5.9475 12.27 4.02 10.125 3.51V3C10.125 2.3775 9.6225 1.875 9 1.875C8.3775 1.875 7.875 2.3775 7.875 3V3.51C5.7225 4.02 4.5 5.94 4.5 8.25V12L3 13.5V14.25H15V13.5L13.5 12Z"
                fill="white"
              />
            </svg>

            <Typography
              style={{
                color: "#FFFFFF",
                fontSize: "14px",
                fontWeight: 500,
                marginRight: 4,
              }}
            >
              {notificationCount > 9 ? "9+" : notificationCount}
            </Typography>
          </Box>
        }
      />
      <Divider />
      {!isGetUserInfoLoading && (
        <NotificationDialog
          notificationCount={notificationCount}
          anchorEl={notificationAnchorEl}
          setAnchorEl={handleNotificationClose}
          setOpenLimitDialog={setOpenLimitDialog}
          isLimit={isLimit}
        />
      )}
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        elevation={8}
        getContentAnchorEl={null}
        anchorOrigin={
          drawerOpen
            ? {
                vertical: "bottom",
                horizontal: "center",
              }
            : {
                vertical: "center",
                horizontal: "right",
              }
        }
        transformOrigin={
          drawerOpen
            ? {
                vertical: "top",
                horizontal: "center",
              }
            : {
                vertical: "center",
                horizontal: "left",
              }
        }
        PaperProps={{
          style: {
            width: 240,
          },
        }}
      >
        {menus.map(({ action, title, Icon, color, id }) => (
          <Item
            id={id}
            key={title}
            onClick={action}
            style={{ padding: "14px 16px" }}
          >
            <Icon style={{ color }} />
            <MenuText style={{ color }}>{title}</MenuText>
          </Item>
        ))}
      </Menu>
      <SkypeSupportDialog
        open={openSupportDialog}
        onClose={() => setOpenSupportDialog(false)}
      />
    </UserButtonBox>
  );
};

export default UserButton;

import { Route, RouteComponentProps, Switch } from "react-router-dom";
import { appRoutes } from "./index";
import React from "react";

import { RouteTypeWrapper } from "../types/routes";
import AuthLayout from "../layouts/Auth";
import Page404 from "../pages/auth/Page404";
import ThanksSignUpDialog from "../components/dialogs/ThanksSignUpDialog";

const childRoutes = (routes: Array<RouteTypeWrapper>) =>
  routes.map(
    (
      { route: { component: Component, guard, children, path }, Layout },
      index: number
    ) => {
      const Guard = guard || React.Fragment;

      return children ? (
        children.map((element, index: number) => {
          const Guard = element.guard || React.Fragment;

          return (
            <Route
              key={index}
              path={element.path}
              exact
              render={(props: RouteComponentProps) => (
                <Layout>
                  <Guard>
                    <element.component {...props} />
                  </Guard>
                </Layout>
              )}
            />
          );
        })
      ) : Component ? (
        <Route
          key={index}
          path={path}
          exact
          render={(props) => (
            <Layout>
              <Guard>
                <Component {...props} />
              </Guard>
            </Layout>
          )}
        />
      ) : null;
    }
  );

const parentRoutes = (routes: Array<RouteTypeWrapper>) =>
  routes.map(
    (
      { route: { component: Component, guard, children, path }, Layout },
      index: number
    ) => {
      const Guard = guard || React.Fragment;

      return children && Component ? (
        <Route
          key={index}
          path={path}
          exact
          render={(props) => (
            <Layout>
              <Guard>
                <Component {...props} />
              </Guard>
            </Layout>
          )}
        />
      ) : null;
    }
  );

const Routes = () => (
  <>
    <Switch>
      {parentRoutes(appRoutes)}
      {childRoutes(appRoutes)}
      <Route
        render={() => (
          <AuthLayout>
            <Page404 />
          </AuthLayout>
        )}
      />
    </Switch>
    <Switch>
      <Route path={`/auth/sign-up/thanks`}>
        <ThanksSignUpDialog
          open={true}
          onClose={() => {
            window.location.replace(`/auth/sign-in`);
          }}
        />
      </Route>
    </Switch>
  </>
);

export default Routes;

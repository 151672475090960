import { EventObject } from "../types/events";
import { Journey, JourneyElements } from "../types/journey";
import { JourneyNodeType } from "../pages/journey/settings/enum";
import { checkNodeType, getParentId } from "../utils/journeyService";
import { createContext, useContext, useState } from "react";
import {
  useCreateJourneyElements,
  useDeleteJourneyElements,
  useJourney,
  useJourneyElementsStructure,
  useListAllEvents,
  useUpdateJourneyElements,
} from "../utils/queries";
import { useLoading } from "./LoadingContext";
import { useParams } from "react-router-dom";
import { useReactFlow } from "reactflow";

const JourneySettingSetContext = createContext<{
  journeyElements: JourneyElements[];
  onDeleteElements: Function;
  onCreateElements: Function;
  onUpdateElements: Function;
  isLoading: boolean;
  listEvents: EventObject[];
  isActive: boolean;
  setIsActive: Function;
  journeyId: string;
  isAddNode: boolean;
  setIsAddNode: Function;
  isError: boolean;
  journey?: Journey;
}>({
  journeyElements: [],
  onDeleteElements: (value: JourneyElements, id: string) => {},
  onCreateElements: (value: JourneyElements, onConfirm: Function) => {},
  onUpdateElements: (value: JourneyElements, onConfirm: Function) => {},
  isLoading: false,
  listEvents: [],
  isActive: false,
  setIsActive: (value: boolean) => {},
  journeyId: "",
  isAddNode: true,
  isError: false,
  setIsAddNode: (value: boolean) => {},
  journey: undefined,
});

const JourneySettingSetProdiver = (props: Object) => {
  const { onError, onSuccess } = useLoading();
  const { setNodes, setEdges, getNode } = useReactFlow();
  const { journeyID } = useParams<{ journeyID: string }>();
  const [journey, setJourney] = useState<Journey>();
  const [journeyElements, setJourneyElements] = useState<JourneyElements[]>([]);
  const [listEvents, setListEvents] = useState<EventObject[]>([]);

  const [isActive, setIsActive] = useState<boolean>(false);
  const [isAddNode, setIsAddNode] = useState<boolean>(true);

  const { isLoading: isGetLoading, isError: isJourneyError } = useJourney(
    journeyID,
    {
      onSuccess: (data) => {
        if (data) {
          setJourney(data);
          if (data.status === "active") {
            setIsActive(true);
          }
        }
      },
    }
  );

  const {
    mutate: updateJourneyElements,
    isLoading: isUpdateLoading,
  } = useUpdateJourneyElements({
    onSuccess: async (data) => {
      onSuccess("Element has been successfully updated");
      refetch();
    },
    onError: (error) => {
      onError(error?.message);
    },
  });

  const {
    mutate: createJourneyElements,
    isLoading: isCreateLoading,
  } = useCreateJourneyElements({
    onSuccess: async (data) => {
      onSuccess("Element has been successfully updated");
      refetch();
    },
    onError: (error) => {
      onError(error?.message);
    },
  });
  const {
    mutate: deleteJourneyElements,
    isLoading: isDeleteLoading,
  } = useDeleteJourneyElements({
    onSuccess: async (data) => {
      onSuccess("Element has been successfully deleted");
      refetch();
    },
    onError: (error) => {
      onError(error?.message);
    },
  });

  const { refetch, isLoading } = useJourneyElementsStructure(journey?.id, {
    onSuccess: (data) => {
      if (data && data.length > 0) {
        setJourneyElements(data);
      }
    },
  });

  const { isLoading: isGetListAllEventsLoading } = useListAllEvents({
    onSuccess: (data) => {
      if (data) {
        if (data) {
          setListEvents(data);
        }
      }
    },
  });

  const onCreateElements = (value: JourneyElements, onConfirm: Function) => {
    createJourneyElements(value);
  };
  const onUpdateElements = (value: JourneyElements, onConfirm: Function) => {
    updateJourneyElements(value);
  };

  const onDeleteElements = (value: JourneyElements, id: string) => {
    const currentNode = getNode(id) as any;
    if (!value.id) {
      const parent = journeyElements.find(
        (item) => item.id === getParentId(currentNode?.data.parent_id)
      );
      const parentType = parent && checkNodeType(parent);
      if (parentType === JourneyNodeType.triggerCheck) {
        setEdges((edges) =>
          edges.map((edge) =>
            edge.source === id?.toString()
              ? {
                  ...edge,
                  source: currentNode?.data.parent_id,
                  type: "trigger-line",
                  sourceHandle: `trigger-check-node-source-${
                    currentNode?.data?.branch ? "yes" : "no"
                  }`,
                }
              : edge
          )
        );
      } else {
        setEdges((edges) =>
          edges.map((edge) =>
            edge.source === id?.toString()
              ? { ...edge, source: currentNode?.data.parent_id }
              : edge
          )
        );
      }
      setNodes((nodes) => nodes.filter((node) => node.id !== id?.toString()));
    } else {
      deleteJourneyElements(value);
      const parent = journeyElements.find(
        (item) => item.id === value.parent_id
      );
      const parentType = parent && checkNodeType(parent);
      if (parentType === JourneyNodeType.triggerCheck) {
        setEdges((edges) => {
          return edges.map((edge) =>
            edge.source === id?.toString()
              ? {
                  ...edge,
                  source: `${parentType}--${parent?.id}--1`,
                  type: "trigger-line",
                  sourceHandle: `trigger-check-node-source-${
                    value.branch ? "yes" : "no"
                  }`,
                }
              : edge
          );
        });
      } else {
        setEdges((edges) =>
          edges.map((edge) => {
            return edge.source === id?.toString()
              ? {
                  ...edge,
                  source: `${parentType}--${parent?.id}--1`,
                }
              : edge;
          })
        );
      }
      setNodes((nodes) =>
        nodes
          .map((item) => {
            if (
              getParentId(item.id).toString() ===
              getParentId(id?.toString()).toString()
            ) {
              return {
                ...item,
                position: {
                  x: item.position.x - 240,
                  y:
                    item.id.toString().split("--")[3] === "no"
                      ? item.position.y - 50
                      : item.position.y,
                },
              };
            }
            return item;
          })
          .filter((node) => {
            if (
              currentNode &&
              currentNode.type === JourneyNodeType.triggerCheck &&
              getParentId(node.id).toString() ===
                getParentId(id?.toString()).toString() &&
              node.id.includes("--no")
            ) {
              return false;
            }
            return !!node && node.id !== id?.toString();
          })
      );
    }
  };

  return (
    <>
      <JourneySettingSetContext.Provider
        value={{
          journeyElements,
          onDeleteElements,
          onCreateElements,
          onUpdateElements,
          isLoading:
            isGetLoading ||
            isLoading ||
            isGetListAllEventsLoading ||
            isDeleteLoading ||
            isCreateLoading ||
            isUpdateLoading,
          listEvents,
          setIsActive,
          isActive,
          journeyId: journeyID,
          isAddNode,
          setIsAddNode,
          journey,
          isError: isJourneyError,
        }}
        {...props}
      />
    </>
  );
};

const useJourneySetting = () => {
  const context = useContext(JourneySettingSetContext);

  if (context === undefined) {
    throw new Error(
      `useJourneySettingSet must be used within a JourneySettingSetProdiver`
    );
  }
  return context;
};

export { JourneySettingSetProdiver, useJourneySetting };

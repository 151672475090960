import { UseFormMethods } from "react-hook-form";

export const registerField = (
  form: UseFormMethods<any>,
  key: string,
  value: any
) => {
  form.unregister(key);
  form.register(key);
  form.setValue(key, value);
};

import { Button, ButtonProps, CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";

export interface LoadingButtonProps {
  isLoading: boolean;
}

const useStyle = makeStyles({
  buttonProgress: {
    marginRight: 12,
  },
});

export default React.forwardRef<any, LoadingButtonProps & ButtonProps>(
  ({ isLoading, ...props }, ref) => {
    const classes = useStyle();
    return (
      <Button ref={ref} variant="contained" {...props}>
        {isLoading && (
          <CircularProgress
            size={18}
            color="inherit"
            className={classes.buttonProgress}
          />
        )}
        {props.children}
      </Button>
    );
  }
);

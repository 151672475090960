import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from "@material-ui/core";
import { CloseIcon } from "@material-ui/data-grid";
import CustomDialog from "../customizeds/CustomDialog";
import React from "react";

export interface SkypeSupportDialogProps {
  open: boolean;
  onClose: () => void;
}

const SkypeSupportDialog: React.FunctionComponent<SkypeSupportDialogProps> = ({
  open,
  onClose,
}) => {
  return (
    <CustomDialog
      maxWidth="xs"
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <Grid container alignItems="center">
          <Grid xs={12} container item justify="flex-end">
            <IconButton aria-label="close" onClick={onClose}>
              <CloseIcon color="disabled" />
            </IconButton>
          </Grid>
          <Grid item xs={12}>
            <Typography align="center" variant="h4">
              We’re still working on this
            </Typography>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid container justify="center" spacing={4}>
          <Grid container justify="center" item xs={12}>
            <img
              src="/static/img/brands/skype-icon.png"
              width={80}
              height={80}
              alt="skype"
            />
          </Grid>
          <Grid item xs={8}>
            <Typography align="center" id="alert-dialog-description">
              You can contact our support via Skype chat to leave a request for
              new features or improvements
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container justify="center">
          <Button
            onClick={onClose}
            color="primary"
            variant="contained"
            href="https://join.skype.com/kKX53V4X4OSr"
            target="_blank"
            size="large"
            style={{
              marginBottom: 32,
            }}
          >
            Reach our support
          </Button>
        </Grid>
      </DialogActions>
    </CustomDialog>
  );
};

export default SkypeSupportDialog;

import * as React from "react";
import styled, { withTheme } from "styled-components/macro";

import {
  Button,
  Grid,
  AppBar as MuiAppBar,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { Link as RouterLink, useLocation } from "react-router-dom";
import Logo from "./Logo";
import routes from "../constants/routes";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const AppBar = styled(MuiAppBar)`
  background: ${(props) => props.theme.header.background};
  color: ${(props) => props.theme.header.color};
  padding: 8px 80px;
`;

type AppBarProps = {
  theme: {};
};

const RegisterButton = () => {
  const { search } = useLocation();

  return (
    <Button
      variant="contained"
      to={`${routes.SIGNUP}${search}`}
      component={RouterLink}
    >
      Register
    </Button>
  );
};

const LoginButton = () => {
  const { search } = useLocation();

  return (
    <Button
      variant="outlined"
      to={`${routes.SIGNIN}${search}`}
      component={RouterLink}
    >
      Login
    </Button>
  );
};

const AuthAppBarComponent: React.FC<AppBarProps> = () => {
  const location = useLocation();
  const isLoginRoute =
    location.pathname === routes.SIGNIN ||
    location.pathname === routes.VERIFY_EMAIL;
  const isAuthRoute =
    location.pathname === routes.SIGNIN ||
    location.pathname === routes.SIGNUP ||
    location.pathname === routes.VERIFY_EMAIL;

  const text = isLoginRoute ? "Not a member?" : "Already joined?";
  const mdScreen = useMediaQuery("(min-width:768px)");

  return (
    <React.Fragment>
      <AppBar
        position="sticky"
        elevation={0}
        style={{
          paddingLeft: !mdScreen ? "17px" : undefined,
          paddingTop: !mdScreen ? "17px" : undefined,
        }}
      >
        <Toolbar>
          <Grid container alignItems="center">
            <Grid item>
              <a href="https://www.nashpush.com" target="blank">
                <Logo isMobile={!mdScreen} />
              </a>
            </Grid>
            <Grid item xs />
            {isAuthRoute && mdScreen ? (
              <Grid item>
                <Grid
                  container
                  justify="space-between"
                  alignItems="center"
                  spacing={4}
                >
                  <Grid item>
                    <Typography variant="subtitle2">{text}</Typography>
                  </Grid>
                  <Grid item>
                    {isLoginRoute ? <RegisterButton /> : <LoginButton />}
                  </Grid>
                </Grid>
              </Grid>
            ) : null}
          </Grid>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
};

export default withTheme(AuthAppBarComponent);

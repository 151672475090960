import { FirebaseAccountsDashboardSetProdiver } from "../contexts/FirebaseAccountsDashboardSetContext";
import Dashboard, { DashboardPropsType } from "./Dashboard";
import React from "react";

const FirebaseAccountsLayout: React.FunctionComponent<DashboardPropsType> = (
  props
) => {
  return (
    <FirebaseAccountsDashboardSetProdiver>
      <Dashboard {...props} />
    </FirebaseAccountsDashboardSetProdiver>
  );
};

export default FirebaseAccountsLayout;

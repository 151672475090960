import { History } from "history";
import { replace } from "lodash";
import routes from "../constants/routes";

export const createPathParams = (
  route: string,
  params: { [key: string]: string }
) => {
  let result = route;
  Object.keys(params).forEach((key) => {
    result = replace(result, `:${key}`, params[key]);
  });
  return result;
};

export const goToRoute = (
  route: string,
  params: { [key: string]: string },
  history: History
) => {
  const settingRoute = createPathParams(route, params);
  history.push(settingRoute);
};

export const goToChannelPage = (history: History) =>
  goToRoute(routes.USER_BASES, {}, history);

export const goToBaseSettingPage = (history: History, baseId: string) =>
  goToRoute(routes.USER_BASES_SETTING, { baseId }, history);

export const goToCodeSnippetPage = (history: History, baseId: string) =>
  goToRoute(routes.USER_BASES_CODE_SNIPPET, { baseId }, history);

export const goToViewChannelPage = (history: History, baseId: string) =>
  goToRoute(routes.USER_BASES_VIEW, { baseId }, history);

export const goToCampaignPage = (history: History) =>
  goToRoute(routes.CAMPAIGNS, {}, history);

export const goToCampaignSettingPage = (history: History, campaignId: string) =>
  goToRoute(routes.CAMPAIGN_SETTINGS, { campaignId }, history);

export const goToCampaignCreatePickPage = (
  history: History,
  channelId: string,
  campaignName: string
) =>
  goToRoute(routes.CAMPAIGN_CREATE_PICK, { channelId, campaignName }, history);

export const goTopushNotificationPage = (history: History) =>
  goToRoute(routes.PUSH_NOTIFICATION, {}, history);

export const goTopushNotificationSettingPage = (
  history: History,
  notificationId: string
) => goToRoute(routes.PUSH_NOTIFICATION_SETTINGS, { notificationId }, history);

export const goTopushNotificationCreatePage = (history: History) =>
  goToRoute(routes.PUSH_NOTIFICATION_CREATE, {}, history);

export const goToIndicatorAnalyticsPage = (history: History, baseId: string) =>
  goToRoute(routes.INDICATOR_ANALYTICS, { baseId }, history);

export const goToIndicatorAnalyticsAllPage = (history: History) =>
  goToRoute(routes.INDICATOR_ANALYTICS_ALL, {}, history);

export const goToToolPushContentGeneratorPage = (history: History) =>
  goToRoute(routes.TOOL_PUSH_CONTENT_GENERATOR, {}, history);

export const goToSubscribersSourcePage = (history: History, baseId: string) =>
  goToRoute(routes.SUBSCRIBERS_SOURCE, { baseId }, history);

export const goToABTestDashboardPage = (history: History) =>
  goToRoute(routes.AB_TEST_DASHBARD, {}, history);

export const goToABTestReportPage = (history: History, baseId: string) =>
  goToRoute(routes.AB_TEST_REPORT, { baseId }, history);

export const goToABTestSettingPage = (history: History, abId: string) =>
  goToRoute(routes.AB_TEST_SETTINGS, { abId }, history);

export const goToABTestCreatePage = (history: History) =>
  goToRoute(routes.AB_TEST_CREATE, {}, history);

export const goTosubscriptionWindowsPage = (history: History) =>
  goToRoute(routes.SUBSCRIPTION_WINDOWS, {}, history);

export const goTosubscriptionWindowsSettingPage = (
  history: History,
  subscriptionWindowsId: string
) =>
  goToRoute(
    routes.SUBSCRIPTION_WINDOWS_SETTINGS,
    { subscriptionWindowsId },
    history
  );

export const goTosubscriptionWindowsCreatePage = (history: History) =>
  goToRoute(routes.SUBSCRIPTION_WINDOWS_CREATE, {}, history);

export const goToCampaignAnalyticsPage = (
  history: History,
  campaignId: string
) => goToRoute(routes.CAMPAIGN_ANALYTICS, { campaignId }, history);

export const goToCampaignAnalyticsAllPage = (history: History) =>
  goToRoute(routes.CAMPAIGN_ANALYTICS_ALL, {}, history);

export const goToAnalyticsPage = (history: History) =>
  goToRoute(routes.ANALYTICS, {}, history);
export const goToResetPassword = (history: History) =>
  goToRoute(routes.RESET_PASSWORD, {}, history);

export const goToSignInPage = (history: History) =>
  goToRoute(routes.SIGNIN, {}, history);

export const goToSignUpPage = (history: History) =>
  goToRoute(routes.SIGNUP, {}, history);

export const goToThanksPage = (history: History) =>
  goToRoute(routes.THANKS, {}, history);

export const goToContentInsightsPage = (history: History, campaignId: string) =>
  goToRoute(routes.CONTENT_INSIGHTS, { campaignId }, history);

export const goToSegmentsPage = (history: History) =>
  goToRoute(routes.SEGMENTS_DASHBOARD, {}, history);

export const goToSegmentsSettingPage = (history: History, segmentID: string) =>
  goToRoute(routes.SEGMENTS_SETTINGS, { segmentID }, history);

export const goToSegmentsCreatePage = (history: History) =>
  goToRoute(routes.SEGMENTS_CREATE, {}, history);

export const goToPushPoolsContentInsightsPage = (
  history: History,
  channelId: string
) => goToRoute(routes.PUSH_POOLS_CONTENT_INSIGHTS, { channelId }, history);

export const goToPaymentPage = (history: History, status: string) =>
  goToRoute(routes.PAYMENT, { status }, history);

export const goToPaymentPricingPlan = (history: History) =>
  goToRoute(routes.PRICING_PLAN, {}, history);

export const goToPaymentDetailsPage = (history: History, status?: string) =>
  goToRoute(routes.PAYMENT_DETAIL, { status: status ?? " " }, history);

export const goToUpdatePayment = (history: History, status: string) =>
  goToRoute(routes.UPDATE_PAYMENT, { status }, history);

export const goToFirebaseAccountsDashboardPage = (history: History) =>
  goToRoute(routes.FIREBASE_ACCOUNTS_DASHBOARD, {}, history);

export const goToEventPage = (history: History) =>
  goToRoute(routes.EVENTS_DASHBOARD, {}, history);

export const goToEventSettingPage = (history: History, eventID: string) =>
  goToRoute(routes.EVENT_SETTINGS, { eventID }, history);

export const goToEventCreatePage = (history: History) =>
  goToRoute(routes.EVENT_CREATE, {}, history);

export const goToEventAnalyticsPage = (history: History, eventID: string) =>
  goToRoute(routes.EVENT_ANALYTICS, { eventID }, history);

export const goToVerifyEmail = (history: History) =>
  goToRoute(routes.VERIFY_EMAIL, {}, history);

export const goToJourneyDashboardPage = (history: History) =>
  goToRoute(routes.JOURNEYS_DASHBOARD, {}, history);

export const goToJourneySettingPage = (history: History, journeyID: string) =>
  goToRoute(routes.JOURNEYS_SETTINGS, { journeyID }, history);

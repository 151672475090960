import { ParameterContext, SetContext } from "../types/channel";
import { useListAllChannel } from "../utils/queries";
import React, { createContext, useContext, useState } from "react";
import _ from "lodash";

const ChannelSetContext = createContext<{
  keySets: SetContext;
  isNoChannel: boolean;
  total: number;
  isLoading: boolean;
}>({
  keySets: {},
  isNoChannel: false,
  total: 0,
  isLoading: false,
});

const ChannelSetProdiver = (props: Object) => {
  const [keySets, setKeySets] = useState({} as SetContext);
  const [isNoChannel, setIsNoChannel] = useState(false);
  const [total, setTotal] = useState(0);

  const { isLoading } = useListAllChannel({
    onSuccess: (data) => {
      if (data) {
        const rowCount = data.length;
        setIsNoChannel(rowCount === 0);
        setTotal(rowCount);
        const attributes = _.transform(
          data,
          (result, attribute) => {
            _.forEach(attribute, (value, key) => {
              if (
                typeof value === "string" &&
                key !== "code_snippet" &&
                key !== "status"
              ) {
                if (!result[key]) {
                  result[key] = [];
                }
                if (!result[key].includes(value)) {
                  result[key].push(value);
                }
              }
            });
          },
          {} as ParameterContext
        );
        const custom_attributes = _.transform(
          _.flatMap(data, (channel) => channel.custom_attributes ?? []),
          (result, attribute) => {
            if (!result[attribute.key]) {
              result[attribute.key] = [];
            }
            if (!result[attribute.key].includes(attribute.value_str)) {
              result[attribute.key].push(attribute.value_str);
            }
          },
          {} as ParameterContext
        );

        setKeySets({
          custom_attributes: custom_attributes,
          ...attributes,
          platform: ["web", "android", "ios"],
        });
      }
    },
  });
  return (
    <ChannelSetContext.Provider
      value={{ keySets, isNoChannel, total, isLoading }}
      {...props}
    />
  );
};

const useChannelSet = () => {
  const context = useContext(ChannelSetContext);

  if (context === undefined) {
    throw new Error(`useChannelSet must be used within a ChannelSetProdiver`);
  }
  return context;
};

export { ChannelSetProdiver, useChannelSet };

import { PushNotificationSetProdiver } from "../contexts/PushNotificationSetContext";
import Dashboard, { DashboardPropsType } from "./Dashboard";
import React from "react";
const PushNotificationLayout: React.FunctionComponent<DashboardPropsType> = (
  props
) => {
  return (
    <PushNotificationSetProdiver>
      <Dashboard {...props} />
    </PushNotificationSetProdiver>
  );
};

export default PushNotificationLayout;

import {
  Grid,
  Box as MuiBox,
  Link as MuiLink,
  Popover,
  Typography,
} from "@material-ui/core";
import { formatDistanceDay } from "../../utils/date";
import {
  goToBaseSettingPage,
  goToCampaignSettingPage,
} from "../../utils/routes";
import { listNotifications } from "../../utils/api";
import { notificationObj } from "../../types/notification";
import { useHistory } from "react-router-dom";
import { useInfiniteQuery } from "react-query";
import { useSnackbar } from "notistack";
import {
  useUpdateCampaignFromTable,
  useUpdateNotificatioStatus,
} from "../../utils/queries";
import CampaignCreationDialog from "./CampaignCreationDialog";
import InfiniteScroll from "react-infinite-scroll-component";
import LoadingButton from "../LoadingButton";
import PerfectScrollbar from "react-perfect-scrollbar";
import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import useSessionStorageState from "../../hooks/useSessionStorageState";
export interface NotificationDialogProps {
  notificationCount: number;
  anchorEl: Element | ((element: Element) => Element) | null | undefined;
  setAnchorEl: Function;
  isLimit: boolean;
  setOpenLimitDialog: Function;
}
const Box = styled(MuiBox)`
  ${(props) => props.theme.breakpoints.down("md")} {
    width: 95vw;
  }
  ${(props) => props.theme.breakpoints.up("md")} {
    width: 506px;
  }
`;
const MuiGrid = styled(Grid)`
  height: 143px;
  &:hover {
    background-color: #efefef;
  }
`;

const Link = styled(MuiLink)`
  text-decoration: underline;
  font-weight: bold;
`;

const NotificationDialog: React.FunctionComponent<NotificationDialogProps> = ({
  notificationCount,
  anchorEl,
  setAnchorEl,
  isLimit,
  setOpenLimitDialog,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const [showNotificationCount, setShowNotificationCount] = useState(
    notificationCount
  );
  const [campaignID, setCampaignID] = useState<string>("");
  const [notificationID, setNotificationID] = useState<number>();
  const [totalData, setTotalData] = useState<number>(0);
  const [openCreateDialog, setOpenCreateDialog] = useState<boolean>(false);
  const [limtStorage, setlimtStorage] = useSessionStorageState(
    "showlimtSub",
    false
  );

  useEffect(() => {
    setShowNotificationCount(notificationCount);
  }, [notificationCount]);

  const {
    data,
    hasNextPage,
    fetchNextPage,
    refetch,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    isLoading: isPageLoading,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    isFetching: isPageFetching,
  } = useInfiniteQuery(
    ["list-notifications"],
    ({ pageParam = 1 }) => listNotifications(pageParam),
    {
      getNextPageParam: (lastPage) => {
        if (!anchorEl) {
          return undefined;
        }
        if (!!lastPage.next) {
          const page = lastPage.next.slice(
            lastPage.next.lastIndexOf("?page=") + 6
          );
          return page;
        } else {
          return undefined;
        }
      },
    }
  );

  const {
    mutate: updateCampaign,
    isLoading: startCampaignLoading,
  } = useUpdateCampaignFromTable({
    onSuccess: () => {
      enqueueSnackbar("Start campaign success", {
        variant: "success",
      });
      refetch();
    },
    onError: (data) => {
      if (campaignID) {
        enqueueSnackbar("Start campaign fail", {
          variant: "error",
        });
        goToCampaignSettingPage(history, campaignID);
      }
    },
  });

  const {
    mutate: updateStatus, // eslint-disable-next-line @typescript-eslint/no-unused-vars
    isLoading: isUpdateNotificatioStatusLoading,
  } = useUpdateNotificatioStatus({
    onSuccess: (data) => {
      refetch();
    },
  });

  const openChannelInNewTab = (id: number | string) => {
    if (!!window && id) {
      window.open("/user-bases/" + id + "/settings");
    }
  };

  const openUpdateStatusById = (id: number | string | undefined) => {
    if (id) {
      updateStatus({ channel_notification_ids: [id.toString()] });
      setShowNotificationCount(showNotificationCount - 1);
    }
  };

  useEffect(() => {
    let count = 0;
    if (isLimit && !limtStorage && data && data.pages.length > 0) {
      let subscribersLimit = false;
      data.pages[0]?.results.map(
        (item) =>
          (subscribersLimit =
            subscribersLimit || item.group === "subscribers_quota")
      );
      if (subscribersLimit) {
        setOpenLimitDialog(true);
        setlimtStorage(true);
      }
    }
    data?.pages.map((page) => (count = count + page.results.length));
    setTotalData(count);
  }, [
    data,
    isLimit,
    limtStorage,
    setOpenLimitDialog,
    setlimtStorage,
    totalData,
  ]);

  return (
    <>
      <Popover
        anchorEl={anchorEl}
        keepMounted
        open={data && data?.pages[0].count > 0 ? Boolean(anchorEl) : false}
        onClose={() => setAnchorEl()}
        elevation={8}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Box
          style={{
            background: "#FFFFFF",
            boxShadow: "0px 4px 26px rgba(0, 0, 0, 0.25)",
            borderRadius: "4px",
            padding: 16,
            overflow: "hidden",
          }}
        >
          <Grid container direction="row" justify="space-between">
            <Grid item>
              <Typography
                style={{
                  fontWeight: 500,
                  fontSize: "16px",
                }}
              >
                Notifications
                {showNotificationCount > 0 &&
                  " (" + showNotificationCount + ")"}
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                style={{
                  color: "#2767E9",
                  fontWeight: 500,
                  fontSize: "14px",
                }}
                onClick={() => {
                  updateStatus({ mark_all: true });
                  setShowNotificationCount(0);
                }}
              >
                Mark all as read
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <InfiniteScroll
          dataLength={totalData}
          next={fetchNextPage}
          hasMore={hasNextPage ?? false}
          loader={
            <Box
              display="flex"
              style={{
                justifyContent: "center",
                padding: 10,
              }}
            >
              Loading...
            </Box>
          }
          scrollableTarget="notification-perfect-scrollbar"
        >
          <PerfectScrollbar
            option={{ suppressScrollX: true }}
            id="notification-perfect-scrollbar"
          >
            <Box
              style={{
                maxHeight: "70vh",
              }}
            >
              <Box
                style={{
                  background: "#FFFFFF",
                  boxShadow: "0px 4px 26px rgba(0, 0, 0, 0.25)",
                  borderRadius: "4px",
                  padding: 16,
                  overflow: "hidden",
                }}
              >
                <Grid
                  container
                  direction="column"
                  justify="space-between"
                  spacing={6}
                >
                  {data?.pages.map((page) =>
                    page.results.map((item: notificationObj, index) => (
                      <MuiGrid
                        key={`${item.id}-index`}
                        item
                        style={{
                          height:
                            item.group === "subscribers_quota"
                              ? "90px"
                              : "143px",
                        }}
                      >
                        <Grid
                          container
                          direction="column"
                          justify="space-between"
                          spacing={1}
                        >
                          <Grid item>
                            <Grid
                              container
                              direction="row"
                              justify="space-between"
                            >
                              <Grid item>
                                <Typography
                                  style={{
                                    color: "#656565",
                                    fontSize: "12px",
                                  }}
                                >
                                  {formatDistanceDay(
                                    new Date(item.created_at ?? "")
                                  )}
                                </Typography>
                              </Grid>
                              <Grid item>
                                {item?.status === "PENDING" && (
                                  <svg
                                    width="6"
                                    height="6"
                                    viewBox="0 0 6 6"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <circle
                                      cx="3"
                                      cy="3"
                                      r="3"
                                      fill="#2767E9"
                                    />
                                  </svg>
                                )}
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item>
                            <Grid container direction="row" spacing={5}>
                              {item.group !== "subscribers_quota" && (
                                <Grid item>
                                  <Typography
                                    style={{
                                      fontSize: "14px",
                                      lineHeight: "20px",
                                      fontWeight: 500,
                                    }}
                                  >
                                    First subscriber in
                                  </Typography>
                                </Grid>
                              )}

                              <Grid item>
                                <Typography
                                  style={{
                                    fontSize: "14px",
                                    lineHeight: "20px",
                                    fontWeight: 500,
                                    textDecoration: "underline",
                                  }}
                                >
                                  <Link
                                    href="#"
                                    onClick={() => {
                                      openUpdateStatusById(item.id);
                                      if (item.generic_data.channel_id) {
                                        goToBaseSettingPage(
                                          history,
                                          item.generic_data.channel_id.toString()
                                        );
                                      }
                                    }}
                                  >
                                    {item?.generic_data?.channel_name ?? ""}
                                  </Link>
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid
                            item
                            onClick={() => {
                              openUpdateStatusById(item.id);
                              if (item.generic_data.channel_id) {
                                openChannelInNewTab(
                                  item.generic_data.channel_id
                                );
                              }
                            }}
                          >
                            <Typography>
                              {!!item.message
                                ? item.message
                                : "Now you can start the campaign for this base"}
                            </Typography>
                          </Grid>
                          {item.group !== "subscribers_quota" && (
                            <Grid item>
                              <LoadingButton
                                id={"startCapaign-" + item.id}
                                variant="outlined"
                                fullWidth
                                isLoading={
                                  item?.id &&
                                  notificationID &&
                                  item?.id === notificationID
                                    ? startCampaignLoading
                                    : false
                                }
                                disableElevation
                                onClick={() => {
                                  openUpdateStatusById(item.id);
                                  setNotificationID(item.id);
                                  if (item?.generic_data?.campaign_id) {
                                    setCampaignID(
                                      item?.generic_data?.campaign_id.toString()
                                    );
                                    updateCampaign({
                                      id: item?.generic_data?.campaign_id,
                                      campaign_id:
                                        item?.generic_data?.campaign_id,
                                      is_active: true,
                                      is_draft: false,
                                    });
                                  } else {
                                    setOpenCreateDialog(true);
                                  }
                                }}
                                style={{
                                  width: "160px",
                                  background: "#E5E5E5",
                                  borderRadius: "4px",
                                  marginTop: "8px",
                                  wordWrap: "normal",
                                }}
                                disabled={
                                  item?.generic_data?.campaign_status ===
                                  "active"
                                }
                              >
                                {item?.generic_data?.campaign_id
                                  ? item?.generic_data?.campaign_status ===
                                    "active"
                                    ? "Campaign started"
                                    : "Start campaign"
                                  : "Create campaign"}
                              </LoadingButton>
                            </Grid>
                          )}
                        </Grid>
                        <CampaignCreationDialog
                          channel_name={item?.generic_data?.channel_name}
                          channel_id={item?.generic_data?.channel_id}
                          isOpen={openCreateDialog}
                          handleClose={() => setOpenCreateDialog(false)}
                        />
                      </MuiGrid>
                    ))
                  )}
                </Grid>
              </Box>
            </Box>
          </PerfectScrollbar>
        </InfiniteScroll>
      </Popover>
    </>
  );
};

export default NotificationDialog;

/* eslint-disable sort-imports-es6-autofix/sort-imports-es6 */
import "ag-grid-enterprise";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "./index.scss";

import "react-perfect-scrollbar/dist/css/styles.css";

import { LicenseManager } from "ag-grid-enterprise";
import ReactDOM from "react-dom";
import { AG_GRID_KEY } from "./types/env";
import { Provider } from "react-redux";
import store from "./redux/store/index";

LicenseManager.setLicenseKey(AG_GRID_KEY!);

// eslint-disable-next-line prettier/prettier
// eslint-disable-next-line import/first
import App from "./App";

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);

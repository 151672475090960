import { JourneySettingSetProdiver } from "../contexts/JourneySettingContext";
import { ReactFlowProvider } from "reactflow";
import Dashboard, { DashboardPropsType } from "./Dashboard";
import React from "react";
const JourneyLayout: React.FunctionComponent<DashboardPropsType> = (props) => {
  return (
    <ReactFlowProvider>
      <JourneySettingSetProdiver>
        <Dashboard {...props} footer={false} background="#EDEDEF" />
      </JourneySettingSetProdiver>
    </ReactFlowProvider>
  );
};

export default JourneyLayout;

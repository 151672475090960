import { Box, Fab, Tooltip, makeStyles } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import AddIcon from "@material-ui/icons/Add";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import React from "react";
import routes from "../../constants/routes";
import styled from "styled-components/macro";

const AddButtonContainer = styled(Box)`
  background-color: ${(props) => props.theme.sidebar.background};
`;

const AddButton = styled(Button)`
  flex: 1;
  height: 56px;
  border-radius: 100px;
  margin: 0 auto;
  background-color: white;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  &:hover {
    background-color: rgba(255, 255, 255, 0.9);
  }
`;

const MiniAddButton = styled(Fab)`
  background-color: ${(props) => props.theme.sidebar.badge.color};
`;

const useStyles = makeStyles({
  tooltip: {
    fontSize: "1em",
  },
});

const SidebarAddButton: React.FunctionComponent<{
  drawerOpen: boolean;
  isSuspended: boolean;
  isMobile: boolean;
}> = ({ drawerOpen, isSuspended, isMobile = false }) => {
  const [anchorEl, setAnchorEl] = React.useState<
    Element | ((element: Element) => Element) | null | undefined
  >(null);
  const history = useHistory();
  const classesStyle = useStyles();

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuClick = (route: string) => {
    handleClose();
    history.push(route, {
      openCreateModal: true,
    });
  };

  return (
    <AddButtonContainer
      display="flex"
      alignItems="center"
      paddingX={drawerOpen ? 6 : 2}
      paddingY={isMobile ? 0 : 6}
    >
      {isMobile ? (
        <Fab
          aria-label="Add"
          style={{
            position: "absolute",
            bottom: "34px",
            right: "34px",
            zIndex: 50,
            backgroundColor: "#FFFFFF",
          }}
          onClick={handleClick}
        >
          <AddIcon />
        </Fab>
      ) : drawerOpen ? (
        <AddButton
          id="menuAdd"
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={handleClick}
          disabled={isSuspended}
        >
          + Add
        </AddButton>
      ) : (
        <MiniAddButton aria-label="add" onClick={handleClick}>
          <Tooltip
            title=" Add"
            placement="right-end"
            classes={{ tooltip: classesStyle.tooltip }}
          >
            <AddIcon />
          </Tooltip>
        </MiniAddButton>
      )}
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        elevation={8}
        getContentAnchorEl={null}
        anchorOrigin={
          isMobile
            ? {
                vertical: "top",
                horizontal: "left",
              }
            : drawerOpen
            ? {
                vertical: "bottom",
                horizontal: "center",
              }
            : {
                vertical: "center",
                horizontal: "right",
              }
        }
        transformOrigin={
          isMobile
            ? {
                vertical: "bottom",
                horizontal: "right",
              }
            : drawerOpen
            ? {
                vertical: "top",
                horizontal: "center",
              }
            : {
                vertical: "center",
                horizontal: "left",
              }
        }
        PaperProps={{
          style: {
            width: 194,
          },
        }}
      >
        <MenuItem
          style={{ padding: "14px 16px" }}
          id="menuAddUserBase"
          onClick={() => handleMenuClick(routes.USER_BASES)}
        >
          New database
        </MenuItem>
        <MenuItem
          style={{ padding: "14px 16px" }}
          id="menuAddCampaign"
          onClick={() => handleMenuClick(routes.CAMPAIGNS)}
        >
          Push message
        </MenuItem>
        <MenuItem
          style={{ padding: "14px 16px" }}
          id="menuAddTemplate"
          onClick={() => handleMenuClick(routes.PUSH_NOTIFICATION_CREATE)}
        >
          Push pools
        </MenuItem>
        <MenuItem
          style={{ padding: "14px 16px" }}
          id="menuAddTemplate"
          onClick={() => handleMenuClick(routes.EVENT_CREATE)}
        >
          Event
        </MenuItem>
      </Menu>
    </AddButtonContainer>
  );
};

export default SidebarAddButton;

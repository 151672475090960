import { ParameterContext, SetContext } from "../types/channel";
import { Segments } from "../types/segments";
import { createContext, useContext, useState } from "react";
import { useListAllSegmentsAutocomplete } from "../utils/queries";
import _ from "lodash";

const SegmentsDashboardSetContext = createContext<{
  segments: Segments[];
  total: number;
  isNoSegments: boolean;
  keySets: SetContext;
}>({
  total: 0,
  segments: [],
  isNoSegments: false,
  keySets: {},
});

const SegmentsDashboardSetProdiver = (props: Object) => {
  const [total, setTotal] = useState(0);
  const [segments, setSegments] = useState<Segments[]>([]);
  const [isNoSegments, setIsNoSegments] = useState(false);
  const [keySets, setKeySets] = useState({} as SetContext);

  useListAllSegmentsAutocomplete({
    onSuccess: (data) => {
      if (data) {
        setSegments(data);
        setTotal(data.length);
        setIsNoSegments(data.length === 0);
        const attributes = _.transform(
          data,
          (result, attribute) => {
            _.forEach(attribute, (value, key) => {
              if (typeof value === "string" && key !== "status") {
                if (!result[key]) {
                  result[key] = [];
                }
                if (value && !result[key].includes(value)) {
                  result[key].push(value);
                }
              }
            });
          },
          {} as ParameterContext
        );
        setKeySets(attributes);
      }
    },
  });

  return (
    <>
      <SegmentsDashboardSetContext.Provider
        value={{ total, segments, isNoSegments, keySets }}
        {...props}
      />
    </>
  );
};

const useSegmentsDashboardSet = () => {
  const context = useContext(SegmentsDashboardSetContext);

  if (context === undefined) {
    throw new Error(
      `useSegmentsDashboardSet must be used within a SegmentsDashboardSetProdiver`
    );
  }
  return context;
};

export { SegmentsDashboardSetProdiver, useSegmentsDashboardSet };

import { SetContext } from "../types/channel";
import { useListAllEvents } from "../utils/queries";
import React, { createContext, useContext, useState } from "react";

const EventsSetContext = createContext<{
  keySets: SetContext;
  isNoEvents: boolean;
  total: number;
  isLoading: boolean;
}>({
  keySets: {},
  isNoEvents: false,
  total: 0,
  isLoading: false,
});

const EventsSetProdiver = (props: Object) => {
  const [keySets, setKeySets] = useState({} as SetContext);
  const [isNoEvents, setIsNoEvents] = useState(false);
  const [total, setTotal] = useState(0);

  const { isLoading } = useListAllEvents({
    onSuccess: (data) => {
      if (data) {
        const rowCount = data.length;
        setIsNoEvents(rowCount === 0);
        setTotal(rowCount);
        setKeySets({});
      }
    },
  });
  return (
    <EventsSetContext.Provider
      value={{ keySets, isNoEvents, total, isLoading }}
      {...props}
    />
  );
};

const useEventsSet = () => {
  const context = useContext(EventsSetContext);

  if (context === undefined) {
    throw new Error(`useEventsSet must be used within a EventsSetProdiver`);
  }
  return context;
};

export { EventsSetProdiver, useEventsSet };

import { createContext, useContext, useState } from "react";

const MenuContext = createContext<{
  isOpen: boolean;
  setIsOpen: Function;
}>({
  isOpen: true,
  setIsOpen: () => {},
});

const MenuProdiver = (props: Object) => {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <MenuContext.Provider
      value={{
        isOpen,
        setIsOpen,
      }}
      {...props}
    />
  );
};

const useMenuProdiver = () => {
  const context = useContext(MenuContext);

  if (context === undefined) {
    throw new Error(`useMenuProdiver must be used within a MenuProdiver`);
  }
  return context;
};

export { MenuProdiver, useMenuProdiver };

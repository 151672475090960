enum routes {
  HOME = "/",
  AUTH = "/auth",
  SIGNIN = "/auth/sign-in",
  SIGNUP = "/auth/sign-up",
  THANKS = "/auth/sign-up/thanks",
  RESET_PASSWORD = "/auth/reset-password",
  CONFIRM_SIGNUP = "/auth/confirm-signup",
  PAGE_ERROR_404 = "/auth/404",
  PAGE_ERROR_500 = "/auth/500",
  USER_BASES = "/user-bases",
  USER_BASES_DASHBOARD = "/user-bases/dashboard",
  USER_BASES_SETTING = "/user-bases/:baseId/settings",
  USER_BASES_CODE_SNIPPET = "/user-bases/:baseId/code-snippet",
  USER_BASES_VIEW = "/user-bases/:baseId/view",
  CAMPAIGNS = "/campaigns",
  CAMPAIGN_SETTINGS = "/campaigns/:campaignId/settings",
  CAMPAIGN_CREATE_PICK = "/campaigns/create/:channelId/:campaignName/pick",
  PUSH_NOTIFICATION = "/push-notification",
  PUSH_NOTIFICATION_SETTINGS = "/push-notification/settings/:notificationId",
  PUSH_NOTIFICATION_CREATE = "/push-notification/settings/",
  TOOLS = "/tools",
  TOOL_PUSH_CONTENT_GENERATOR = "/tools/push-content-generator",
  PROFILE = "/profile",
  HELPS = "/helps",
  PRICING_PLAN = "/pricing-plan",
  UPDATE_PAYMENT = "/update-payment/:status",
  PAYMENT_DETAIL_STATUS = "/payment-details/:status",
  PAYMENT_DETAIL = "/payment-details",
  PAYMENT = "/payment/:status",
  INDICATOR_ANALYTICS_ALL = "/indicator-analytics/",
  INDICATOR_ANALYTICS = "/indicator-analytics/:baseId",
  SUBSCRIBERS_SOURCE = "/user-bases/:baseId/subscribers-source/",
  AB_TEST_DASHBARD = "/ab-test/dashboard/",
  AB_TEST_REPORT = "/ab-test/report/:baseId/",
  AB_TEST_SETTINGS = "/ab-test/settings/:abId",
  AB_TEST_CREATE = "/ab-test/settings/",
  TEMPLATES = "/templates",
  SUBSCRIPTION_WINDOWS = "/subscription-windows",
  SUBSCRIPTION_WINDOWS_SETTINGS = "/subscription-windows/settings/:subscriptionWindowsId",
  SUBSCRIPTION_WINDOWS_CREATE = "/subscription-windows/settings/",
  CAMPAIGN_ANALYTICS = "/campaigns/analytics/:campaignId",
  CAMPAIGN_ANALYTICS_ALL = "/campaigns/analytics/",
  ANALYTICS = "/tools/analytics/",
  SET_NEW_PASSWORD = "/users/password/new/:user_uid/:token/",
  CONTENT_INSIGHTS = "/campaigns/:campaignId/content_insights/",
  PUSH_POOLS_CONTENT_INSIGHTS = "/channel/:channelId/content_insights/",
  SEGMENTS_DASHBOARD = "/segments/dashboard/",
  SEGMENTS_SETTINGS = "/segments/settings/:segmentID/",
  SEGMENTS_CREATE = "/segments/settings/",
  FIREBASE_ACCOUNTS_DASHBOARD = "/firebase-accounts/dashboard/",
  EVENTS_DASHBOARD = "/events/dashboard/",
  EVENT_CREATE = "/events/settings/",
  EVENT_SETTINGS = "/events/settings/:eventID/",
  EVENT_ANALYTICS = "/events/analytics/:eventID/",
  VERIFY_EMAIL = "/auth/verify-email",
  JOURNEYS_DASHBOARD = "/journeys/dashboard/",
  JOURNEYS_SETTINGS = "/journeys/settings/:journeyID",
}

export default routes;

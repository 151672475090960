import { Bell, Flag, Tool, Users } from "react-feather";
import { RouteTypeWrapper } from "../types/routes";
import AuthLayout from "../layouts/Auth";
import CampaignLayout from "../layouts/CampaignLayout";
import DashboardLayout from "../layouts/Dashboard";
// revert menu Events
import EventsLayout from "../layouts/EventsLayout";
import FirebaseAccountsLayout from "../layouts/FirebaseAccountsLayout";
import JourneyDashboardLayout from "../layouts/JourneyDashboardLayout";
import JourneyLayout from "../layouts/JourneyLayout";
import PaymentLayout from "../layouts/PaymentLayout";
import PeopleOutlineIcon from "@material-ui/icons/PeopleOutline";
import PushNotificationLayout from "../layouts/PushNotificationLayout";
import SegmentsLayout from "../layouts/SegmentsLayout";
import SubscriptionWindowsLayout from "../layouts/SubscriptionWindowsLayout";
import UserBaseLayout from "../layouts/UserBaseLayout";
import async from "../components/Async";
import routes from "../constants/routes";

// Guards
const AuthGuard = async(() => import("../components/AuthGuard"));
const ReverseAuthGuard = async(() => import("../components/ReverseAuthGuard"));

// Auth components
const SignIn = async(() => import("../pages/auth/SignIn"));
const SignUp = async(() => import("../pages/auth/SignUp"));
const ResetPassword = async(() => import("../pages/auth/ResetPassword"));
const SetNewPassword = async(() => import("../pages/auth/SetNewPassword"));
const Page404 = async(() => import("../pages/auth/Page404"));
const Page500 = async(() => import("../pages/auth/Page500"));
const VerifyEmail = async(() => import("../pages/auth/VerifyEmail"));

// Main pages
const CampaignsPage = async(() => import("../pages/campaign/dashboard"));
const CampaignSettingsPage = async(() => import("../pages/campaign/settings"));
const CampaignCreatePage = async(() => import("../pages/campaign/create"));
const UserBaseDashboardPage = async(
  () => import("../pages/user-bases/dashboard")
);
const UserBaseViewPage = async(() => import("../pages/user-bases/view"));
const UserBaseSettingPage = async(() => import("../pages/user-bases/settings"));
const UserBaseSnippetPage = async(
  () => import("../pages/user-bases/code-snippet")
);
const ProfilePage = async(() => import("../pages/profile"));
const PushNotificationPage = async(() => import("../pages/push-pools"));

const PushNotificationSettingPage = async(
  () => import("../pages/push-pools/settings")
);
const HelpPage = async(() => import("../pages/help/HelpPage"));

const PaymentDetailsPage = async(
  () => import("../pages/payment/PaymentDetailsPage")
);

const PaymentStatusPage = async(() => import("../pages/payment/PaymentPage"));

const IndicatorsAnalyticsPage = async(
  () => import("../pages/tool/indicators-analytics/index")
);
const ToolsPage = async(() => import("../pages/tool/index"));

const UserSubscribersSourcePage = async(
  () => import("../pages/user-bases/subscribers-source/index")
);

const ABTestDashboardPage = async(
  () => import("../pages/content/subscription-windows-pools/dashboard/index")
);

const ABTestReportPage = async(
  () => import("../pages/content/subscription-windows-pools/report/index")
);

const ABTestSettingPage = async(
  () => import("../pages/content/subscription-windows-pools/settings/index")
);

const TemplatesPage = async(() => import("../pages/content/index"));

const SubscriptionWindowsPage = async(
  () => import("../pages/content/subscription-window-template")
);

const SubscriptionWindowsSettingPage = async(
  () => import("../pages/content/subscription-window-template/settings")
);

const CampaignAnalyticsPage = async(
  () => import("../pages/campaign/campaign-analytics/index")
);

const ContentInsightsPage = async(
  () => import("../pages/campaign/content_insights/index")
);
const AnalyticsPage = async(() => import("../pages/tool/analytics/index"));

const SegmentsDashboardPage = async(
  () => import("../pages/segments/dashboard")
);

const SegmentsSettingPage = async(
  () => import("../pages/segments/settings/index")
);

const PushPoolsContentInsightsPage = async(
  () => import("../pages/user-bases/content_insights/index")
);

const FirebaseAccountsDashboardPage = async(
  () => import("../pages/firebase/index")
);

const EventDashboardPage = async(() => import("../pages/events/dashboard"));

const EventSettingPage = async(() => import("../pages/events/settings/index"));
const EventAnalyticsPage = async(
  () => import("../pages/events/analytics/index")
);

const JourneySettingPage = async(
  () => import("../pages/journey/settings/index")
);
const JourneysDashboardPage = async(() => import("../pages/journey/dashboard"));

// Routes visible in the sidebar
export const sidebarRoutes = [
  {
    id: "User Databases",
    path: routes.USER_BASES,
    icon: <PeopleOutlineIcon />,
    component: UserBaseDashboardPage,
    children: null,
    guard: AuthGuard,
    autoId: "menuUserBases",
  },
  {
    id: "Push messages",
    path: routes.CAMPAIGNS,
    icon: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2 22V4C2 3.45 2.19583 2.97917 2.5875 2.5875C2.97917 2.19583 3.45 2 4 2H20C20.55 2 21.0208 2.19583 21.4125 2.5875C21.8042 2.97917 22 3.45 22 4V16C22 16.55 21.8042 17.0208 21.4125 17.4125C21.0208 17.8042 20.55 18 20 18H6L2 22ZM5.15 16H20V4H4V17.125L5.15 16Z"
          fill="white"
        />
      </svg>
    ),
    component: CampaignsPage,
    children: null,
    guard: AuthGuard,
    autoId: "menuPushMessages",
  },

  {
    id: "Automation",
    path: "",
    icon: (
      <svg width="24" height="24" viewBox="0 0 24 24">
        <path
          d="M2 19.9917L9.5 12.4817L13.5 16.4817L20.59 8.51172L22 9.92172L13.5 19.4817L9.5 15.4817L3.5 21.4917L2 19.9917ZM3.5 15.4917L9.5 9.48172L13.5 13.4817L22 3.92172L20.59 2.51172L13.5 10.4817L9.5 6.48172L2 13.9917L3.5 15.4917Z"
          fill="white"
        />
      </svg>
    ),
    component: TemplatesPage,
    children: [
      {
        id: "Events",
        name: "Events",
        path: routes.EVENTS_DASHBOARD,
        component: EventDashboardPage,
        guard: AuthGuard,
        autoId: "menuEvents",
      },
      {
        id: "journeys",
        path: routes.JOURNEYS_DASHBOARD,
        component: JourneysDashboardPage,
        name: "Journeys",
        guard: AuthGuard,
        autoId: "menuSubscriptionWindowPools",
      },
    ],
    guard: AuthGuard,
    autoId: "menuAutomation",
  },
  {
    id: "Segments",
    path: routes.SEGMENTS_DASHBOARD,
    icon: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13 5.07687C16.06 5.51688 18.48 7.93687 18.92 10.9969H21.95C21.48 6.27688 17.72 2.51687 13 2.04688V5.07687ZM18.92 12.9969C18.48 16.0569 16.06 18.4769 13 18.9169V21.9469C17.72 21.4769 21.48 17.7169 21.95 12.9969H18.92ZM11 18.9169C7.61 18.4269 5 15.5169 5 11.9969C5 8.47687 7.61 5.56687 11 5.07687V2.04688C5.95 2.54688 2 6.80687 2 11.9969C2 17.1869 5.95 21.4469 11 21.9469V18.9169Z"
          fill="white"
        />
      </svg>
    ),
    component: SegmentsDashboardPage,
    children: null,
    guard: AuthGuard,
    autoId: "menuSegments",
  },
  // {
  //   id: "Events",
  //   path: routes.EVENTS_DASHBOARD,
  //   icon: (
  //     <svg
  //       xmlns="http://www.w3.org/2000/svg"
  //       width="24"
  //       height="24"
  //       viewBox="0 0 24 24"
  //       fill="none"
  //     >
  //       <path
  //         d="M14.5 18C13.8 18 13.2083 17.7583 12.725 17.275C12.2417 16.7917 12 16.2 12 15.5C12 14.8 12.2417 14.2083 12.725 13.725C13.2083 13.2417 13.8 13 14.5 13C15.2 13 15.7917 13.2417 16.275 13.725C16.7583 14.2083 17 14.8 17 15.5C17 16.2 16.7583 16.7917 16.275 17.275C15.7917 17.7583 15.2 18 14.5 18ZM5 22C4.45 22 3.97917 21.8042 3.5875 21.4125C3.19583 21.0208 3 20.55 3 20V6C3 5.45 3.19583 4.97917 3.5875 4.5875C3.97917 4.19583 4.45 4 5 4H6V2H8V4H16V2H18V4H19C19.55 4 20.0208 4.19583 20.4125 4.5875C20.8042 4.97917 21 5.45 21 6V20C21 20.55 20.8042 21.0208 20.4125 21.4125C20.0208 21.8042 19.55 22 19 22H5ZM5 20H19V10H5V20ZM5 8H19V6H5V8Z"
  //         fill="white"
  //       />
  //     </svg>
  //   ),
  //   component: EventDashboardPage,
  //   children: null,
  //   guard: AuthGuard,
  //   autoId: "menuEvents",
  // },
  // {
  //   id: "Events",
  //   path: routes.EVENTS_DASHBOARD,
  //   icon: (
  //     <svg
  //       xmlns="http://www.w3.org/2000/svg"
  //       width="24"
  //       height="24"
  //       viewBox="0 0 24 24"
  //       fill="none"
  //     >
  //       <path
  //         d="M14.5 18C13.8 18 13.2083 17.7583 12.725 17.275C12.2417 16.7917 12 16.2 12 15.5C12 14.8 12.2417 14.2083 12.725 13.725C13.2083 13.2417 13.8 13 14.5 13C15.2 13 15.7917 13.2417 16.275 13.725C16.7583 14.2083 17 14.8 17 15.5C17 16.2 16.7583 16.7917 16.275 17.275C15.7917 17.7583 15.2 18 14.5 18ZM5 22C4.45 22 3.97917 21.8042 3.5875 21.4125C3.19583 21.0208 3 20.55 3 20V6C3 5.45 3.19583 4.97917 3.5875 4.5875C3.97917 4.19583 4.45 4 5 4H6V2H8V4H16V2H18V4H19C19.55 4 20.0208 4.19583 20.4125 4.5875C20.8042 4.97917 21 5.45 21 6V20C21 20.55 20.8042 21.0208 20.4125 21.4125C20.0208 21.8042 19.55 22 19 22H5ZM5 20H19V10H5V20ZM5 8H19V6H5V8Z"
  //         fill="white"
  //       />
  //     </svg>
  //   ),
  //   component: EventDashboardPage,
  //   children: null,
  //   guard: AuthGuard,
  //   autoId: "menuEvents",
  // },
  {
    id: "Content",
    path: "",
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M4 14V12H11V14H4ZM4 10V8H15V10H4ZM4 6V4H15V6H4ZM13 20V16.925L18.525 11.425C18.675 11.275 18.8417 11.1667 19.025 11.1C19.2083 11.0333 19.3917 11 19.575 11C19.775 11 19.9667 11.0375 20.15 11.1125C20.3333 11.1875 20.5 11.3 20.65 11.45L21.575 12.375C21.7083 12.525 21.8125 12.6917 21.8875 12.875C21.9625 13.0583 22 13.2417 22 13.425C22 13.6083 21.9667 13.7958 21.9 13.9875C21.8333 14.1792 21.725 14.35 21.575 14.5L16.075 20H13ZM14.5 18.5H15.45L18.475 15.45L18.025 14.975L17.55 14.525L14.5 17.55V18.5ZM18.025 14.975L17.55 14.525L18.475 15.45L18.025 14.975Z"
          fill="white"
        />
      </svg>
    ),
    component: TemplatesPage,
    children: [
      {
        id: "PushPools",
        name: "Push pools",
        path: routes.PUSH_NOTIFICATION,
        component: PushNotificationPage,
        guard: AuthGuard,
        autoId: "menuPushes",
      },
      {
        id: "SubscriptionWindowPools",
        path: routes.AB_TEST_DASHBARD,
        component: ABTestDashboardPage,
        name: "Subscription window pools",
        guard: AuthGuard,
        autoId: "menuSubscriptionWindowPools",
      },
      {
        id: "SubscriptionWindowTemplates",
        path: routes.SUBSCRIPTION_WINDOWS,
        component: SubscriptionWindowsPage,
        name: "Subscription window templates",
        guard: AuthGuard,
        autoId: "menuSubscriptionWindowTemplates",
        onlyForAdmin: true,
      },
    ],
    guard: AuthGuard,
    autoId: "menuSubscriptionWindows",
  },
  {
    id: "Analytics",
    path: routes.ANALYTICS,
    name: "Analytics",
    component: AnalyticsPage,
    guard: AuthGuard,
    children: null,
    onlyForAdmin: true,
    icon: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3ZM19 19H5V5H19V19Z"
          fill="white"
        />
        <path d="M9 12H7V17H9V12Z" fill="white" />
        <path d="M17 7H15V17H17V7Z" fill="white" />
        <path d="M13 14H11V17H13V14Z" fill="white" />
        <path d="M13 10H11V12H13V10Z" fill="white" />
      </svg>
    ),
  },
];

// Routes visible in the sidebar
export const HelpRoute = {
  id: "Help",
  path: routes.HELPS,
  icon: (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.45 4.46L18.04 3.05L16.25 4.85L17.66 6.26L19.45 4.46ZM12 0.549999H10V3.5H12V0.549999ZM19 12.5H22V10.5H19V12.5ZM8 16.69V21.5H14V16.69C15.79 15.65 17 13.72 17 11.5C17 8.19 14.31 5.5 11 5.5C7.69 5.5 5 8.19 5 11.5C5 13.72 6.21 15.65 8 16.69ZM3 12.5V10.5L0 10.5V12.5L3 12.5ZM5.76 4.84L3.97 3.04L2.56 4.45L4.36 6.24L5.76 4.84Z"
        fill="white"
      />
    </svg>
  ),
  component: HelpPage,
  children: null,
  guard: AuthGuard,
  autoId: "menuHelps",
};

export const UpdatePaymentRoute = {
  id: "updatePayment",
  path: routes.UPDATE_PAYMENT,
  icon: (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.45 4.46L18.04 3.05L16.25 4.85L17.66 6.26L19.45 4.46ZM12 0.549999H10V3.5H12V0.549999ZM19 12.5H22V10.5H19V12.5ZM8 16.69V21.5H14V16.69C15.79 15.65 17 13.72 17 11.5C17 8.19 14.31 5.5 11 5.5C7.69 5.5 5 8.19 5 11.5C5 13.72 6.21 15.65 8 16.69ZM3 12.5V10.5L0 10.5V12.5L3 12.5ZM5.76 4.84L3.97 3.04L2.56 4.45L4.36 6.24L5.76 4.84Z"
        fill="white"
      />
    </svg>
  ),
  component: PaymentDetailsPage,
  children: null,
  guard: AuthGuard,
  autoId: "menuPricingPlan",
};

export const PricingPlanRoute = {
  id: "PricingPlan",
  path: routes.PRICING_PLAN,
  icon: (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.45 4.46L18.04 3.05L16.25 4.85L17.66 6.26L19.45 4.46ZM12 0.549999H10V3.5H12V0.549999ZM19 12.5H22V10.5H19V12.5ZM8 16.69V21.5H14V16.69C15.79 15.65 17 13.72 17 11.5C17 8.19 14.31 5.5 11 5.5C7.69 5.5 5 8.19 5 11.5C5 13.72 6.21 15.65 8 16.69ZM3 12.5V10.5L0 10.5V12.5L3 12.5ZM5.76 4.84L3.97 3.04L2.56 4.45L4.36 6.24L5.76 4.84Z"
        fill="white"
      />
    </svg>
  ),
  component: PaymentStatusPage,
  children: null,
  guard: AuthGuard,
  autoId: "menuPricingPlanStatuss",
};

// export const PaymentPricingPlanRoute = {
//   id: "PaymentCancel",
//   path: routes.PRICING_PLAN,
//   icon: (
//     <svg
//       width="22"
//       height="22"
//       viewBox="0 0 22 22"
//       fill="none"
//       xmlns="http://www.w3.org/2000/svg"
//     >
//       <path
//         d="M19.45 4.46L18.04 3.05L16.25 4.85L17.66 6.26L19.45 4.46ZM12 0.549999H10V3.5H12V0.549999ZM19 12.5H22V10.5H19V12.5ZM8 16.69V21.5H14V16.69C15.79 15.65 17 13.72 17 11.5C17 8.19 14.31 5.5 11 5.5C7.69 5.5 5 8.19 5 11.5C5 13.72 6.21 15.65 8 16.69ZM3 12.5V10.5L0 10.5V12.5L3 12.5ZM5.76 4.84L3.97 3.04L2.56 4.45L4.36 6.24L5.76 4.84Z"
//         fill="white"
//       />
//     </svg>
//   ),
//   component: PaymentPricingPlanPage,
//   children: null,
//   guard: AuthGuard,
//   autoId: "menuPaymentsPricingPlan",
// };

// export const PaymentSuccessRoute = {
//   id: "PaymentSuccess",
//   path: routes.PAYMENT_SUCCESS,
//   icon: (
//     <svg
//       width="22"
//       height="22"
//       viewBox="0 0 22 22"
//       fill="none"
//       xmlns="http://www.w3.org/2000/svg"
//     >
//       <path
//         d="M19.45 4.46L18.04 3.05L16.25 4.85L17.66 6.26L19.45 4.46ZM12 0.549999H10V3.5H12V0.549999ZM19 12.5H22V10.5H19V12.5ZM8 16.69V21.5H14V16.69C15.79 15.65 17 13.72 17 11.5C17 8.19 14.31 5.5 11 5.5C7.69 5.5 5 8.19 5 11.5C5 13.72 6.21 15.65 8 16.69ZM3 12.5V10.5L0 10.5V12.5L3 12.5ZM5.76 4.84L3.97 3.04L2.56 4.45L4.36 6.24L5.76 4.84Z"
//         fill="white"
//       />
//     </svg>
//   ),
//   component: PaymentSuccessPage,
//   children: null,
//   guard: AuthGuard,
//   autoId: "menuPaymentsSuccess",
// };

export const PaymentDetailsRoute = {
  id: "PaymentDetails",
  path: routes.PAYMENT_DETAIL,
  icon: (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.5 20C2.08333 20 1.72917 19.8542 1.4375 19.5625C1.14583 19.2708 1 18.9167 1 18.5V7H2.5V18.5H20V20H2.5ZM5.5 17C5.08333 17 4.72917 16.8542 4.4375 16.5625C4.14583 16.2708 4 15.9167 4 15.5V5.5C4 5.08333 4.14583 4.72917 4.4375 4.4375C4.72917 4.14583 5.08333 4 5.5 4H21.5C21.9167 4 22.2708 4.14583 22.5625 4.4375C22.8542 4.72917 23 5.08333 23 5.5V15.5C23 15.9167 22.8542 16.2708 22.5625 16.5625C22.2708 16.8542 21.9167 17 21.5 17H5.5ZM8 15.5C8 14.8 7.75833 14.2083 7.275 13.725C6.79167 13.2417 6.2 13 5.5 13V15.5H8ZM19 15.5H21.5V13C20.8 13 20.2083 13.2417 19.725 13.725C19.2417 14.2083 19 14.8 19 15.5ZM13.5 13.5C14.3333 13.5 15.0417 13.2083 15.625 12.625C16.2083 12.0417 16.5 11.3333 16.5 10.5C16.5 9.66667 16.2083 8.95833 15.625 8.375C15.0417 7.79167 14.3333 7.5 13.5 7.5C12.6667 7.5 11.9583 7.79167 11.375 8.375C10.7917 8.95833 10.5 9.66667 10.5 10.5C10.5 11.3333 10.7917 12.0417 11.375 12.625C11.9583 13.2083 12.6667 13.5 13.5 13.5ZM5.5 8C6.2 8 6.79167 7.75833 7.275 7.275C7.75833 6.79167 8 6.2 8 5.5H5.5V8ZM21.5 8V5.5H19C19 6.2 19.2417 6.79167 19.725 7.275C20.2083 7.75833 20.8 8 21.5 8Z"
        fill="black"
      />
    </svg>
  ),
  component: PaymentDetailsPage,
  children: null,
  guard: AuthGuard,
  autoId: "menuPaymentDetails",
};

export const PaymentDetailsStausRoute = {
  id: "PaymentDetailsStatus",
  path: routes.PAYMENT_DETAIL_STATUS,
  icon: (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.5 20C2.08333 20 1.72917 19.8542 1.4375 19.5625C1.14583 19.2708 1 18.9167 1 18.5V7H2.5V18.5H20V20H2.5ZM5.5 17C5.08333 17 4.72917 16.8542 4.4375 16.5625C4.14583 16.2708 4 15.9167 4 15.5V5.5C4 5.08333 4.14583 4.72917 4.4375 4.4375C4.72917 4.14583 5.08333 4 5.5 4H21.5C21.9167 4 22.2708 4.14583 22.5625 4.4375C22.8542 4.72917 23 5.08333 23 5.5V15.5C23 15.9167 22.8542 16.2708 22.5625 16.5625C22.2708 16.8542 21.9167 17 21.5 17H5.5ZM8 15.5C8 14.8 7.75833 14.2083 7.275 13.725C6.79167 13.2417 6.2 13 5.5 13V15.5H8ZM19 15.5H21.5V13C20.8 13 20.2083 13.2417 19.725 13.725C19.2417 14.2083 19 14.8 19 15.5ZM13.5 13.5C14.3333 13.5 15.0417 13.2083 15.625 12.625C16.2083 12.0417 16.5 11.3333 16.5 10.5C16.5 9.66667 16.2083 8.95833 15.625 8.375C15.0417 7.79167 14.3333 7.5 13.5 7.5C12.6667 7.5 11.9583 7.79167 11.375 8.375C10.7917 8.95833 10.5 9.66667 10.5 10.5C10.5 11.3333 10.7917 12.0417 11.375 12.625C11.9583 13.2083 12.6667 13.5 13.5 13.5ZM5.5 8C6.2 8 6.79167 7.75833 7.275 7.275C7.75833 6.79167 8 6.2 8 5.5H5.5V8ZM21.5 8V5.5H19C19 6.2 19.2417 6.79167 19.725 7.275C20.2083 7.75833 20.8 8 21.5 8Z"
        fill="black"
      />
    </svg>
  ),
  component: PaymentDetailsPage,
  children: null,
  guard: AuthGuard,
  autoId: "menuPaymentDetails",
};

export const appRoutes: Array<RouteTypeWrapper> = [
  {
    Layout: AuthLayout,
    route: {
      id: "Auth",
      path: routes.AUTH,
      icon: <Users />,
      children: [
        {
          path: routes.SIGNIN,
          name: "Sign In",
          component: SignIn,
          guard: ReverseAuthGuard,
        },
        {
          path: routes.VERIFY_EMAIL,
          name: "Verify Email",
          component: VerifyEmail,
          guard: ReverseAuthGuard,
        },
        {
          path: routes.SIGNUP,
          name: "Sign Up",
          component: SignUp,
          guard: ReverseAuthGuard,
        },
        {
          path: routes.THANKS,
          name: "Thanks",
          component: SignUp,
          guard: ReverseAuthGuard,
        },
        {
          path: routes.RESET_PASSWORD,
          name: "Reset Password",
          component: ResetPassword,
          guard: ReverseAuthGuard,
        },
        {
          path: routes.PAGE_ERROR_404,
          name: "404 Page",
          component: Page404,
        },
        {
          path: routes.PAGE_ERROR_500,
          name: "500 Page",
          component: Page500,
        },
        {
          path: routes.SET_NEW_PASSWORD,
          name: "Set New Password",
          component: SetNewPassword,
          guard: ReverseAuthGuard,
        },
      ],
      component: null,
    },
  },
  {
    Layout: UserBaseLayout,
    route: {
      id: "home",
      path: routes.HOME,
      component: UserBaseDashboardPage,
      children: null,
      guard: AuthGuard,
    },
  },
  {
    Layout: UserBaseLayout,
    route: {
      id: "User bases",
      path: routes.USER_BASES,
      icon: <Users />,
      component: UserBaseDashboardPage,
      children: null,
      guard: AuthGuard,
    },
  },
  {
    Layout: UserBaseLayout,
    route: {
      id: "user-base-settings",
      path: routes.USER_BASES_SETTING,
      component: UserBaseSettingPage,
      children: null,
      guard: AuthGuard,
    },
  },
  {
    Layout: UserBaseLayout,
    route: {
      id: "user-base-view",
      path: routes.USER_BASES_VIEW,
      component: UserBaseViewPage,
      children: null,
      guard: AuthGuard,
    },
  },
  {
    Layout: UserBaseLayout,
    route: {
      id: "user-base-code-snippet",
      path: routes.USER_BASES_CODE_SNIPPET,
      component: UserBaseSnippetPage,
      children: null,
      guard: AuthGuard,
    },
  },
  {
    Layout: UserBaseLayout,
    route: {
      id: "subscribers-source",
      path: routes.SUBSCRIBERS_SOURCE,
      component: UserSubscribersSourcePage,
      children: null,
      guard: AuthGuard,
    },
  },
  {
    Layout: CampaignLayout,
    route: {
      id: "campaigns",
      path: routes.CAMPAIGNS,
      icon: <Flag />,
      component: CampaignsPage,
      children: null,
      guard: AuthGuard,
    },
  },
  {
    Layout: DashboardLayout,
    route: {
      id: "campaign-settings",
      path: routes.CAMPAIGN_SETTINGS,
      icon: <Flag />,
      component: CampaignSettingsPage,
      children: null,
      guard: AuthGuard,
    },
  },
  {
    Layout: DashboardLayout,
    route: {
      id: "campaign-create-pick",
      path: routes.CAMPAIGN_CREATE_PICK,
      icon: <Flag />,
      component: CampaignCreatePage,
      children: null,
      guard: AuthGuard,
    },
  },
  {
    Layout: DashboardLayout,
    route: {
      id: "profile",
      path: routes.PROFILE,
      component: ProfilePage,
      children: null,
      guard: AuthGuard,
    },
  },
  {
    Layout: PushNotificationLayout,
    route: {
      id: "push-notification",
      path: routes.PUSH_NOTIFICATION,
      icon: <Bell />,
      component: PushNotificationPage,
      children: null,
      guard: AuthGuard,
    },
  },
  {
    Layout: PushNotificationLayout,
    route: {
      id: "push-notification-settings",
      path: routes.PUSH_NOTIFICATION_SETTINGS,
      component: PushNotificationSettingPage,
      children: null,
      guard: AuthGuard,
    },
  },
  {
    Layout: PushNotificationLayout,
    route: {
      id: "push-notification-create",
      path: routes.PUSH_NOTIFICATION_CREATE,
      component: PushNotificationSettingPage,
      children: null,
      guard: AuthGuard,
    },
  },
  {
    Layout: DashboardLayout,
    route: {
      id: "Tools",
      path: routes.TOOLS,
      icon: <Tool />,
      component: ToolsPage,
      guard: AuthGuard,
      children: [
        {
          path: routes.INDICATOR_ANALYTICS_ALL,
          name: "Indicators analytics",
          component: IndicatorsAnalyticsPage,
          guard: AuthGuard,
        },
      ],
    },
  },
  {
    Layout: DashboardLayout,
    route: {
      id: "help",
      path: routes.HELPS,
      component: HelpPage,
      children: null,
      guard: AuthGuard,
    },
  },
  {
    Layout: PaymentLayout,
    route: {
      id: "payment",
      path: routes.PAYMENT,
      component: PaymentStatusPage,
      children: null,
      guard: AuthGuard,
    },
  },
  {
    Layout: PaymentLayout,
    route: {
      id: "updatePaymentPage",
      path: routes.UPDATE_PAYMENT,
      component: PaymentDetailsPage,
      children: null,
      guard: AuthGuard,
    },
  },
  {
    Layout: PaymentLayout,
    route: {
      id: "PricingPlan",
      path: routes.PRICING_PLAN,
      component: PaymentStatusPage,
      children: null,
      guard: AuthGuard,
    },
  },
  // {
  //   Layout: DashboardLayout,
  //   route: {
  //     id: "paymentSuccess",
  //     path: routes.PAYMENT_SUCCESS,
  //     component: PaymentSuccessPage,
  //     children: null,
  //     guard: AuthGuard,
  //   },
  // },
  {
    Layout: PaymentLayout,
    route: {
      id: "paymentDetails",
      path: routes.PAYMENT_DETAIL,
      component: PaymentDetailsPage,
      children: null,
      guard: AuthGuard,
    },
  },
  {
    Layout: PaymentLayout,
    route: {
      id: "paymentDetailsSatus",
      path: routes.PAYMENT_DETAIL_STATUS,
      component: PaymentDetailsPage,
      children: null,
      guard: AuthGuard,
    },
  },
  {
    Layout: DashboardLayout,
    route: {
      id: "indicators-analytics",
      path: routes.INDICATOR_ANALYTICS,
      component: IndicatorsAnalyticsPage,
      children: null,
      guard: AuthGuard,
    },
  },
  {
    Layout: DashboardLayout,
    route: {
      id: "A-B-test-dashboard",
      path: routes.AB_TEST_DASHBARD,
      component: ABTestDashboardPage,
      children: null,
      guard: AuthGuard,
    },
  },
  {
    Layout: DashboardLayout,
    route: {
      id: "A-B-test-report",
      path: routes.AB_TEST_REPORT,
      component: ABTestReportPage,
      children: null,
      guard: AuthGuard,
    },
  },
  {
    Layout: DashboardLayout,
    route: {
      id: "ab-test-settings",
      path: routes.AB_TEST_SETTINGS,
      component: ABTestSettingPage,
      children: null,
      guard: AuthGuard,
    },
  },
  {
    Layout: DashboardLayout,
    route: {
      id: "ab-test-create",
      path: routes.AB_TEST_CREATE,
      component: ABTestSettingPage,
      children: null,
      guard: AuthGuard,
    },
  },
  {
    Layout: DashboardLayout,
    route: {
      id: "templates",
      path: routes.TEMPLATES,
      component: TemplatesPage,
      children: null,
      guard: AuthGuard,
    },
  },
  {
    Layout: DashboardLayout,
    route: {
      id: "subscription-windows-settings",
      path: routes.SUBSCRIPTION_WINDOWS_SETTINGS,
      component: SubscriptionWindowsSettingPage,
      children: null,
      guard: AuthGuard,
    },
  },
  {
    Layout: DashboardLayout,
    route: {
      id: "subscription-windows-create",
      path: routes.SUBSCRIPTION_WINDOWS_CREATE,
      component: SubscriptionWindowsSettingPage,
      children: null,
      guard: AuthGuard,
    },
  },
  {
    Layout: DashboardLayout,
    route: {
      id: "campaign-analytics",
      path: routes.CAMPAIGN_ANALYTICS,
      component: CampaignAnalyticsPage,
      children: null,
      guard: AuthGuard,
    },
  },
  {
    Layout: DashboardLayout,
    route: {
      id: "analytics",
      path: routes.ANALYTICS,
      component: AnalyticsPage,
      children: [
        {
          path: routes.CAMPAIGN_ANALYTICS_ALL,
          name: "Push Messages Analytics",
          component: CampaignAnalyticsPage,
          guard: AuthGuard,
        },
        {
          path: routes.INDICATOR_ANALYTICS_ALL,
          name: "Indicators analytics",
          component: IndicatorsAnalyticsPage,
          guard: AuthGuard,
        },
      ],
      guard: AuthGuard,
    },
  },
  {
    Layout: DashboardLayout,
    route: {
      id: "content_insights",
      path: routes.CONTENT_INSIGHTS,
      component: ContentInsightsPage,
      children: null,
      guard: AuthGuard,
    },
  },
  {
    Layout: DashboardLayout,
    route: {
      id: "content",
      path: routes.TEMPLATES,
      component: TemplatesPage,
      children: [
        {
          path: routes.PUSH_POOLS_CONTENT_INSIGHTS,
          name: "push-pools",
          component: PushPoolsContentInsightsPage,
          guard: AuthGuard,
        },
        {
          path: routes.AB_TEST_DASHBARD,
          component: ABTestDashboardPage,
          name: "Subscription window pools",
          guard: AuthGuard,
        },
      ],
      guard: AuthGuard,
    },
  },
  {
    Layout: SubscriptionWindowsLayout,
    route: {
      id: "subscription-windows",
      path: routes.SUBSCRIPTION_WINDOWS,
      component: SubscriptionWindowsPage,
      guard: AuthGuard,
      children: null,
    },
  },
  {
    Layout: SegmentsLayout,
    route: {
      id: "segments",
      path: routes.SEGMENTS_DASHBOARD,
      icon: (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M13 5.07687C16.06 5.51688 18.48 7.93687 18.92 10.9969H21.95C21.48 6.27688 17.72 2.51687 13 2.04688V5.07687ZM18.92 12.9969C18.48 16.0569 16.06 18.4769 13 18.9169V21.9469C17.72 21.4769 21.48 17.7169 21.95 12.9969H18.92ZM11 18.9169C7.61 18.4269 5 15.5169 5 11.9969C5 8.47687 7.61 5.56687 11 5.07687V2.04688C5.95 2.54688 2 6.80687 2 11.9969C2 17.1869 5.95 21.4469 11 21.9469V18.9169Z"
            fill="white"
          />
        </svg>
      ),
      component: SegmentsDashboardPage,
      children: null,
      guard: AuthGuard,
    },
  },
  {
    Layout: DashboardLayout,
    route: {
      id: "segments-settings",
      path: routes.SEGMENTS_SETTINGS,
      component: SegmentsSettingPage,
      children: null,
      guard: AuthGuard,
    },
  },
  {
    Layout: DashboardLayout,
    route: {
      id: "segments-create",
      path: routes.SEGMENTS_CREATE,
      component: SegmentsSettingPage,
      children: null,
      guard: AuthGuard,
    },
  },
  {
    Layout: EventsLayout,
    route: {
      id: "Events",
      path: routes.EVENTS_DASHBOARD,
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M14.5 18C13.8 18 13.2083 17.7583 12.725 17.275C12.2417 16.7917 12 16.2 12 15.5C12 14.8 12.2417 14.2083 12.725 13.725C13.2083 13.2417 13.8 13 14.5 13C15.2 13 15.7917 13.2417 16.275 13.725C16.7583 14.2083 17 14.8 17 15.5C17 16.2 16.7583 16.7917 16.275 17.275C15.7917 17.7583 15.2 18 14.5 18ZM5 22C4.45 22 3.97917 21.8042 3.5875 21.4125C3.19583 21.0208 3 20.55 3 20V6C3 5.45 3.19583 4.97917 3.5875 4.5875C3.97917 4.19583 4.45 4 5 4H6V2H8V4H16V2H18V4H19C19.55 4 20.0208 4.19583 20.4125 4.5875C20.8042 4.97917 21 5.45 21 6V20C21 20.55 20.8042 21.0208 20.4125 21.4125C20.0208 21.8042 19.55 22 19 22H5ZM5 20H19V10H5V20ZM5 8H19V6H5V8Z"
            fill="white"
          />
        </svg>
      ),
      component: EventDashboardPage,
      children: null,
      guard: AuthGuard,
    },
  },
  {
    Layout: DashboardLayout,
    route: {
      id: "events-create",
      path: routes.EVENT_CREATE,
      component: EventSettingPage,
      children: null,
      guard: AuthGuard,
    },
  },
  {
    Layout: DashboardLayout,
    route: {
      id: "events-settings",
      path: routes.EVENT_SETTINGS,
      component: EventSettingPage,
      children: null,
      guard: AuthGuard,
    },
  },
  {
    Layout: DashboardLayout,
    route: {
      id: "events-analytics",
      path: routes.EVENT_ANALYTICS,
      component: EventAnalyticsPage,
      children: null,
      guard: AuthGuard,
    },
  },
  {
    Layout: JourneyLayout,
    route: {
      id: "journey-settings",
      path: routes.JOURNEYS_SETTINGS,
      component: JourneySettingPage,
      children: null,
      guard: AuthGuard,
    },
  },
  {
    Layout: EventsLayout,
    route: {
      id: "Events",
      path: routes.EVENTS_DASHBOARD,
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M14.5 18C13.8 18 13.2083 17.7583 12.725 17.275C12.2417 16.7917 12 16.2 12 15.5C12 14.8 12.2417 14.2083 12.725 13.725C13.2083 13.2417 13.8 13 14.5 13C15.2 13 15.7917 13.2417 16.275 13.725C16.7583 14.2083 17 14.8 17 15.5C17 16.2 16.7583 16.7917 16.275 17.275C15.7917 17.7583 15.2 18 14.5 18ZM5 22C4.45 22 3.97917 21.8042 3.5875 21.4125C3.19583 21.0208 3 20.55 3 20V6C3 5.45 3.19583 4.97917 3.5875 4.5875C3.97917 4.19583 4.45 4 5 4H6V2H8V4H16V2H18V4H19C19.55 4 20.0208 4.19583 20.4125 4.5875C20.8042 4.97917 21 5.45 21 6V20C21 20.55 20.8042 21.0208 20.4125 21.4125C20.0208 21.8042 19.55 22 19 22H5ZM5 20H19V10H5V20ZM5 8H19V6H5V8Z"
            fill="white"
          />
        </svg>
      ),
      component: EventDashboardPage,
      children: null,
      guard: AuthGuard,
    },
  },
  {
    Layout: DashboardLayout,
    route: {
      id: "events-create",
      path: routes.EVENT_CREATE,
      component: EventSettingPage,
      children: null,
      guard: AuthGuard,
    },
  },
  {
    Layout: DashboardLayout,
    route: {
      id: "events-settings",
      path: routes.EVENT_SETTINGS,
      component: EventSettingPage,
      children: null,
      guard: AuthGuard,
    },
  },
  {
    Layout: DashboardLayout,
    route: {
      id: "events-analytics",
      path: routes.EVENT_ANALYTICS,
      component: EventAnalyticsPage,
      children: null,
      guard: AuthGuard,
    },
  },
  {
    Layout: FirebaseAccountsLayout,
    route: {
      id: "firebase-accounts",
      path: routes.FIREBASE_ACCOUNTS_DASHBOARD,
      component: FirebaseAccountsDashboardPage,
      children: null,
      guard: AuthGuard,
    },
  },
  {
    Layout: JourneyDashboardLayout,
    route: {
      id: "journeys-dashboard",
      path: routes.JOURNEYS_DASHBOARD,
      component: JourneysDashboardPage,
      children: null,
      guard: AuthGuard,
    },
  },
];

import {
  Channel,
  ChannelDetail,
  SubscriptionWindow,
  Timeline,
} from "../types/channel";
import { FilterModel } from "../types/query";
import { UseFormMethods } from "react-hook-form";
import { WindowsPools } from "../types/windowsPools";
import { checkMax } from "./indicatorsAnalyticsService";
import { convertDataObject, convertWindowsPools } from "./windowsPoolsService";
import { endOfDay, startOfDay } from "date-fns";
import { fancyTimeFormat } from "./subscriptionWindowService";
import { formatIsoUtcDate, parseDateTimeString } from "./date";
import { omit } from "lodash";
import { registerField } from "./forms";
import _ from "lodash";

export function difference(object: object, base: object) {
  function changes(changedObject: object, changedBase: object) {
    if (_.isArray(changedObject) && _.isArray(changedBase)) {
      return changedObject;
    }

    return _.transform(
      changedObject,
      function (result: { [key: string]: any }, value, key) {
        if (!_.isEqual(value, changedBase[key])) {
          result[key] =
            _.isObject(value) && _.isObject(changedBase[key])
              ? changes(value, changedBase[key])
              : value;
        }
      },
      {}
    );
  }
  return changes(object, base);
}

export function removeEmptyValue(object: { [key: string]: any }) {
  _.forOwn(object.subscription_window, (value, key) => {
    if ((_.isObject(value) && _.isEmpty(value)) || value === "") {
      delete object.subscription_window[key];
    }
  });
  if (object.custom_attributes && _.isArray(object.custom_attributes)) {
    _.remove(object.custom_attributes, (attribute) => attribute.key === "");
  }
  _.forOwn(object, (value, key) => {
    if ((_.isObject(value) && _.isEmpty(value)) || value === "") {
      delete object[key];
    } else if (Array.isArray(value)) {
      object[key] = _.compact(value) ?? [];
    }
  });
  return object;
}

export function provideChannelKeys(
  object: { [key: string]: any },
  channel: Channel
): Channel {
  return {
    name: channel.name,
    channel_id: channel.channel_id,
    ...object,
  };
}

export function provideDefaultValue(
  channel: Channel,
  window_appearance_period: any,
  window_show_after_cancel_period: any
) {
  if (channel.window_appearance_timeout && !!window_appearance_period) {
    channel.window_appearance_timeout = fancyTimeFormat(
      channel.window_appearance_timeout,
      window_appearance_period
    );
  }
  if (channel.window_show_after_cancel && !!window_show_after_cancel_period) {
    channel.window_show_after_cancel = fancyTimeFormat(
      channel.window_show_after_cancel,
      window_show_after_cancel_period
    );
  }
  return channel;
}

export function makePureChannel(
  object: Channel,
  base: Channel,
  window_appearance_period: any,
  window_show_after_cancel_period: any
): Channel {
  if (!object.window_pool_data) {
    object.window_pool = null;
  }
  if (
    !!object.subscription_window &&
    object?.subscription_window?.cancel_button_text === ""
  ) {
    object.subscription_window.cancel_button_text = "Cancel";
  } else if (
    !!object.subscription_window &&
    !object?.subscription_window?.cancel_button_text &&
    !!base?.subscription_window?.cancel_button_text
  ) {
    object.subscription_window.cancel_button_text = null;
  }
  if (
    !!object.subscription_window &&
    object?.subscription_window?.confirm_button_text === ""
  ) {
    object.subscription_window.confirm_button_text = "Allow";
  }

  if (object.window_pool_data && (object?.channel_id || object?.id)) {
    object.window_pool_data = convertWindowsPools(object.window_pool_data, [
      object.channel_id ?? object.id ?? 0,
    ]);
  }
  const diffObject = difference(
    provideDefaultValue(
      object,
      window_appearance_period,
      window_show_after_cancel_period
    ),
    base
  );
  const result = removeEmptyValue(diffObject);
  const channel = provideChannelKeys(result, base);

  return omit(
    channel,
    "option",
    "window_show_after_cancel_period",
    "window_appearance_period"
  );
}

export function hasChanged(object: object, base: object): boolean {
  const diffObject = difference(object, base);
  const result = removeEmptyValue(diffObject);
  return !_.isEmpty(result);
}

export function showError(errors: any, onError: (message: string) => void) {
  Object.keys(errors).forEach((error) => {
    if (errors[error]?.message) onError(errors[error]?.message);
    else if (typeof errors[error] === "object")
      showError(errors[error], onError);
  });
}

export function convertDateModel(filterModel: FilterModel) {
  const result = {} as FilterModel;
  _.forEach(filterModel, (model, key) => {
    if (model.filterType === "date" && key !== "date") {
      result[key] = {
        ...model,
        dateFrom: model.dateFrom
          ? formatIsoUtcDate(startOfDay(parseDateTimeString(model.dateFrom)))
          : model.dateFrom,
        dateTo: model.dateTo
          ? formatIsoUtcDate(endOfDay(parseDateTimeString(model.dateTo)))
          : model.dateTo,
      };
    } else result[key] = model;
  });
  return result;
}

export const convertChannelDetailToChannel = (
  channelDetail: ChannelDetail
): Channel => {
  return {
    name: channelDetail.name,
    website: channelDetail.website,
    status: channelDetail.status,
    channel_token: channelDetail.channel_token,
    code_snippet: channelDetail.code_snippet?.html,
    custom_attributes:
      channelDetail.custom_attributes &&
      channelDetail.custom_attributes.length > 0
        ? channelDetail.custom_attributes
        : [{ key: "", value_str: "" }],
    subscription_window: channelDetail.subscription_window ?? {},
    channel_id: channelDetail.id ?? channelDetail.code_snippet?.channel_id,
    subscription_window_tmp: channelDetail.subscription_window_tmp,
    window_pool_data: channelDetail.window_pool_data,
    window_pool: channelDetail.window_pool,
    window_appearance_period: channelDetail?.window_appearance_timeout
      ? convertPeriodFields(channelDetail?.window_appearance_timeout ?? 0)
      : "seconds",
    window_show_after_cancel_period: convertPeriodFields(
      channelDetail?.window_show_after_cancel ?? 0
    ),
    window_appearance_timeout: !!channelDetail?.window_appearance_timeout
      ? convertTimeFields(channelDetail?.window_appearance_timeout ?? 0)
      : undefined,
    window_show_after_cancel: !!channelDetail?.window_show_after_cancel
      ? convertTimeFields(channelDetail?.window_show_after_cancel ?? 0)
      : undefined,
    disable_window: channelDetail?.disable_window,
    android: channelDetail?.android,
    active_window_type: channelDetail?.active_window_type,
    platform: channelDetail?.platform,
    ios_credentials: channelDetail?.ios_credentials,
    firebase_app: channelDetail?.firebase_app,
    firebase_app_data: channelDetail?.firebase_app_data,
    icon: channelDetail?.icon,
    newborn: channelDetail?.newborn,
  };
};

export function checkMaxValue(data: Timeline[]) {
  let maxValue = Math.max(
    ...data.map((item) =>
      Math.max(item.subscribe_count, item.unsubscribe_count)
    ),
    1
  );

  return checkMax(maxValue);
}
export const registerChannelFields = (
  data: Channel,
  form: UseFormMethods<Record<string, any> | any>,
  baseId: string
) => {
  form.reset(data);
  registerField(form, "channel_id", data.channel_id ?? baseId);
  registerField(form, "status", data.status);
  registerField(
    form,
    "subscription_window_tmp",
    data?.subscription_window?.id ?? data.subscription_window_id
  );
  registerField(form, "window_pool", data.window_pool);
  if (data?.subscription_window) {
    registerSubscriptionWindowFields(data?.subscription_window, form);
  }
  registerField(
    form,
    "option.subscription_create",
    !data?.subscription_window?.name
  );
  registerField(form, "option.is_subscription", true);
  registerField(
    form,
    "option.subscription_form",
    !data?.subscription_window?.name ? "create" : "select"
  );
  registerWindowsPoolsFields(data?.window_pool_data, form);

  registerField(
    form,
    "window_appearance_period",
    data?.window_appearance_period
  );
  registerField(
    form,
    "window_show_after_cancel_period",
    data?.window_show_after_cancel_period
  );

  registerField(
    form,
    "window_appearance_timeout",
    data?.window_appearance_timeout ?? null
  );
  registerField(
    form,
    "window_show_after_cancel",
    data?.window_show_after_cancel ?? null
  );
  registerField(
    form,
    "disable_window",
    data?.active_window_type === "native" && !data?.newborn
      ? true
      : data?.disable_window
  );
  registerField(form, "android", data?.android);
  registerField(
    form,
    "active_window_type",
    data?.newborn ? "window" : data?.active_window_type
  );
  registerField(
    form,
    "option.window_pool_form",
    !data?.window_pool ? "create" : "select"
  );
  registerField(form, "window_pool_tmp", data?.window_pool);
  registerField(form, "ios_credentials", data?.ios_credentials);
  registerField(form, "firebase_app", data?.firebase_app);
  registerField(form, "firebase_app_data", data?.firebase_app_data);
  registerField(form, "icon", data?.icon);
  registerField(form, "newborn", data?.newborn);
};

export const registerSubscriptionWindowFields = (
  data: SubscriptionWindow | undefined,
  form: UseFormMethods<Record<string, any> | any>
) => {
  const windowStyles = data?.styles;

  registerField(form, "subscription_window.image_url", data?.image_url);
  registerField(form, "subscription_window.window_image", data?.window_image);
  registerField(
    form,
    "subscription_window.styles.confirm_button_color",
    windowStyles?.confirm_button_color ?? "#2B67E8"
  );
  registerField(
    form,
    "subscription_window.styles.confirm_text_color",
    windowStyles?.confirm_text_color ?? "#FFFFFF"
  );
  registerField(
    form,
    "subscription_window.styles.cancel_button_color",
    windowStyles?.cancel_button_color ?? "#FFFFFF"
  );
  registerField(
    form,
    "subscription_window.styles.cancel_text_color",
    windowStyles?.cancel_text_color ?? "#000000"
  );
  registerField(
    form,
    "subscription_window.cancel_button_text",
    data?.cancel_button_text ?? null
  );
  registerField(
    form,
    "subscription_window.confirm_button_text",
    data?.confirm_button_text ?? ""
  );

  registerField(form, "subscription_window.name", data?.name);
  registerField(form, "subscription_window.description", data?.description);
};

export const registerWindowsPoolsFields = (
  data: WindowsPools | undefined | null,
  form: UseFormMethods<Record<string, any> | any>
) => {
  if (!!data?.cancel_buttons) {
    data.cancel_buttons.forEach((item) => {
      if (!item?.styles) {
        item.styles = {
          cancel_button_color: "#FFFFFF",
          cancel_text_color: "#000000",
        };
      } else {
        if (!item?.styles?.cancel_button_color) {
          item.styles.cancel_button_color = "#FFFFFF";
        }
        if (!item?.styles?.cancel_text_color) {
          item.styles.cancel_text_color = "#000000";
        }
      }
    });
  }
  if (!!data?.confirm_buttons) {
    data.confirm_buttons.forEach((item) => {
      if (!item?.styles) {
        item.styles = {
          confirm_button_color: "#2B67E8",
          confirm_text_color: "#FFFFFF",
        };
      } else {
        if (!item?.styles?.confirm_button_color) {
          item.styles.confirm_button_color = "#2B67E8";
        }
        if (!item?.styles?.confirm_text_color) {
          item.styles.confirm_text_color = "#FFFFFF";
        }
      }
    });
  }
  if (data?.id) {
    registerField(form, "window_pool_data.id", data?.id);
  }
  registerField(form, "window_pool_data.images", data?.images ?? []);
  registerField(form, "window_pool_data.name", data?.name ?? "");
  registerField(
    form,
    "window_pool_data.action_messages",
    convertDataObject(data?.action_messages)
  );

  registerField(
    form,
    "window_pool_data.cancel_buttons",
    data?.cancel_buttons ?? [
      {
        value: "",
        styles: {
          cancel_text_color: "#000000",
          cancel_button_color: "#FFFFFF",
        },
      },
    ]
  );
  registerField(
    form,
    "window_pool_data.confirm_buttons",
    data?.confirm_buttons ?? [
      {
        value: "",
        styles: {
          confirm_button_color: "#2B67E8",
          confirm_text_color: "#FFFFFF",
        },
      },
    ]
  );
  registerField(form, "window_pool_data.content_mix", data?.content_mix);
  if (data?.images) {
    registerField(
      form,
      "window_pool_data.imagesTmp",
      data?.images.map((item) => item.value)
    );
  } else {
    registerField(form, "window_pool_data.imagesTmp", []);
  }
  registerField(form, "window_pool_data.status", data?.status ?? "draft");
  registerField(
    form,
    "option.is_subscription",
    data?.status === "draft" || !data?.id
  );
};

export const convertTimeFields = (data: number) => {
  if (data % 86400 === 0) {
    return data / 86400;
  } else if (data % 3600 === 0) {
    return data / 3600;
  } else if (data % 60 === 0) {
    return data / 60;
  } else {
    return data;
  }
};
export const convertPeriodFields = (data: number) => {
  if (data % 86400 === 0) {
    return "days";
  } else if (data % 3600 === 0) {
    return "hours";
  } else if (data % 60 === 0) {
    return "minutes";
  } else {
    return "seconds";
  }
};

export const reRegisterWindowsPoolsFields = (
  data: WindowsPools | undefined | null,
  form: UseFormMethods<Record<string, any> | any>
) => {
  if (!!data?.cancel_buttons) {
    data.cancel_buttons.forEach((item) => {
      if (!item?.styles) {
        item.styles = {
          cancel_button_color: "#FFFFFF",
          cancel_text_color: "#000000",
        };
      } else {
        if (!item?.styles?.cancel_button_color) {
          item.styles.cancel_button_color = "#FFFFFF";
        }
        if (!item?.styles?.cancel_text_color) {
          item.styles.cancel_text_color = "#000000";
        }
      }
    });
  }
  if (!!data?.confirm_buttons) {
    data.confirm_buttons.forEach((item) => {
      if (!item?.styles) {
        item.styles = {
          confirm_button_color: "#2B67E8",
          confirm_text_color: "#FFFFFF",
        };
      } else {
        if (!item?.styles?.confirm_button_color) {
          item.styles.confirm_button_color = "#2B67E8";
        }
        if (!item?.styles?.confirm_text_color) {
          item.styles.confirm_text_color = "#FFFFFF";
        }
      }
    });
  }
  registerField(form, "window_pool_data.images", data?.images ?? []);
  registerField(form, "window_pool_data.name", data?.name ?? "");
  registerField(
    form,
    "window_pool_data.action_messages",
    convertDataObject(data?.action_messages)
  );
  registerField(
    form,
    "window_pool_data.cancel_buttons",
    data?.cancel_buttons ?? [
      {
        value: "",
        styles: {
          cancel_text_color: "#000000",
          cancel_button_color: "#FFFFFF",
        },
      },
    ]
  );
  registerField(
    form,
    "window_pool_data.confirm_buttons",
    data?.confirm_buttons ?? [
      {
        value: "",
        styles: {
          confirm_button_color: "#2B67E8",
          confirm_text_color: "#FFFFFF",
        },
      },
    ]
  );
  registerField(form, "window_pool_data.content_mix", data?.content_mix);
  if (data?.images) {
    registerField(
      form,
      "window_pool_data.imagesTmp",
      data?.images.map((item) => item.value)
    );
  } else {
    registerField(form, "window_pool_data.imagesTmp", []);
  }
  registerField(form, "window_pool_data.status", "draft");
};

export function makeSubmitChannel(
  channel: any,
  originChannel?: Channel
): Channel {
  let res = channel;
  if (channel.disable_window && channel.active_window_type === "native") {
    res = makePureChannel(
      omit(
        channel,
        "window_pool_data",
        "subscription_window",
        "subscription_window_tmp",
        "source_link_params"
      ),
      omit(
        originChannel,
        "window_pool_data",
        "subscription_window",
        "subscription_window_tmp",
        "source_link_params"
      ),
      channel.window_appearance_period,
      channel.window_show_after_cancel_period
    );
  } else {
    if (channel.active_window_type === "pool" && channel.window_pool_data) {
      res = {
        ...omit(
          makePureChannel(
            omit(
              channel,
              "subscription_window",
              "subscription_window_tmp",
              "window_pool_data.name",
              "source_link_params"
            ),
            omit(
              originChannel,
              "window_pool_data",
              "subscription_window",
              "subscription_window_tmp",
              "source_link_params"
            ),
            channel.window_appearance_period,
            channel.window_show_after_cancel_period
          ),
          "window_pool_data",
          "window_pool_tmp"
        ),
        window_pool: channel.window_pool_tmp,
      };
    } else if (channel.active_window_type === "window") {
      if (channel.option.subscription_create) {
        res = makePureChannel(
          omit(
            channel,
            "subscription_window_tmp",
            "window_pool_data",
            "source_link_params"
          ),
          omit(
            originChannel,
            "subscription_window",
            "subscription_window_tmp",
            "window_pool_data",
            "source_link_params"
          ),
          channel.window_appearance_period,
          channel.window_show_after_cancel_period
        );
      } else {
        res = makePureChannel(
          omit(
            channel,
            "subscription_window",
            "window_pool_data",
            "source_link_params"
          ),
          omit(
            originChannel,
            "subscription_window",
            "subscription_window_tmp",
            "window_pool_data",
            "source_link_params"
          ),
          channel.window_appearance_period,
          channel.window_show_after_cancel_period
        );
      }
    }
  }
  if (originChannel?.platform !== "web") {
    res = omit(
      {
        ...res,
        ios_credentials:
          !!res.ios_credentials && originChannel?.platform === "ios"
            ? {
                ...res.ios_credentials,
                sandbox:
                  !!res.ios_credentials?.sandbox &&
                  res.ios_credentials?.sandbox !== null
                    ? res.ios_credentials?.sandbox
                    : false,
                is_ios_app: true,
              }
            : undefined,
      },
      "subscription_window",
      "subscription_window_tmp",
      "window_pool_data",
      "source_link_params",
      "window_appearance_timeout",
      "window_show_after_cancel",
      "website",
      "disable_window"
    );
  }
  return omit(
    {
      ...res,
      disable_window: channel.disable_window ? true : false,
    },
    "active_window_type",
    "firebase_app_data"
  );
}

export const convertTimeWithPeriodFields = (data: number, period: string) => {
  if (period === "days") {
    return data * 86400;
  } else if (period === "hours") {
    return data * 3600;
  } else if (period === "minutes") {
    return data * 60;
  } else {
    return data;
  }
};

import { Campaign, ClickAction, MessagePool } from "../types/campaign";
import { ParameterContext, SetContext } from "../types/campaign";
import { createContext, useContext, useEffect, useState } from "react";
import { forEach, transform } from "lodash";
import { useListAllCampaign, useListAllMessage } from "../utils/queries";
import _ from "lodash";

const CampaignSetContext = createContext<{
  campaigns: Campaign[];
  total: number;
  isNoCampaign: boolean;
  keySets: SetContext;
  isLoading: boolean;
}>({
  total: 0,
  campaigns: [],
  keySets: {},
  isNoCampaign: false,
  isLoading: false,
});

const CampaignSetProdiver = (props: Object) => {
  const [keySets, setKeySets] = useState({} as SetContext);
  const [tmpKeySets, setTmpKeySets] = useState({} as SetContext);
  const [urlKeySets, setUrlKeySets] = useState({} as SetContext);
  const [total, setTotal] = useState(0);
  const [campaigns, setCampaigns] = useState<Campaign[]>([]);
  const [isNoCampaign, setIsNoCampaign] = useState(false);

  const { isLoading } = useListAllCampaign(true, {
    onSuccess: (data) => {
      if (data) {
        setTotal(data.length);
        setCampaigns(data);
        setIsNoCampaign(data.length === 0);
        const attributes = transform(
          data,
          (result, attribute) => {
            forEach(attribute, (value, key) => {
              if (typeof value === "string" && key !== "status") {
                if (!result[key]) {
                  result[key] = [];
                }
                if (!result[key].includes(value)) {
                  result[key].push(value);
                }
              }
              if (_.isArray(value) && key === "target_urls") {
                if (!result[key]) {
                  result[key] = [];
                }
                if (!result[key].includes((value as string[])[0])) {
                  result[key].push((value as string[])[0]);
                }
              }
              if (typeof value === "object" && key === "message_pool") {
                if (!result[key]) {
                  result[key] = [];
                }
                if (
                  value &&
                  !result[key].includes((value as MessagePool).name ?? "")
                ) {
                  result[key].push((value as MessagePool).name ?? "");
                }
              }
            });
          },
          {} as ParameterContext
        );
        setTmpKeySets(attributes);
      }
    },
  });

  const { isLoading: isLoadingMessage } = useListAllMessage({
    onSuccess: (data) => {
      if (data) {
        const attributes = transform(
          data,
          (result, attribute) => {
            forEach(attribute, (value, key) => {
              if (_.isArray(value) && key === "click_actions") {
                if (!result["target_urls"]) {
                  result["target_urls"] = [];
                }
                let tmpValue = value as Array<ClickAction>;
                if (
                  value &&
                  tmpValue[0] &&
                  tmpValue[0].click_action_data &&
                  !result["target_urls"].includes(
                    tmpValue[0].click_action_data ?? ""
                  )
                ) {
                  result["target_urls"].push(
                    tmpValue[0].click_action_data ?? ""
                  );
                }
              }
            });
          },
          {} as ParameterContext
        );
        setUrlKeySets(attributes);
      }
    },
  });
  useEffect(() => {
    if (!_.isEmpty(tmpKeySets) && !_.isEmpty(urlKeySets)) {
      setKeySets({ ...tmpKeySets, ...urlKeySets });
    }
  }, [tmpKeySets, urlKeySets]);

  return (
    <CampaignSetContext.Provider
      value={{
        total,
        campaigns,
        keySets,
        isNoCampaign,
        isLoading: isLoading || isLoadingMessage,
      }}
      {...props}
    />
  );
};

const useCampaignSet = () => {
  const context = useContext(CampaignSetContext);

  if (context === undefined) {
    throw new Error(`useCampaignSet must be used within a CampaignSetProdiver`);
  }
  return context;
};

export { CampaignSetProdiver, useCampaignSet };

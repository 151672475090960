import { EventsSetProdiver } from "../contexts/EventsSetContext";
import Dashboard, { DashboardPropsType } from "./Dashboard";
import React from "react";
const EventsLayout: React.FunctionComponent<DashboardPropsType> = (props) => {
  return (
    <EventsSetProdiver>
      <Dashboard {...props} />
    </EventsSetProdiver>
  );
};

export default EventsLayout;

import * as React from "react";
import styled from "styled-components/macro";

import {
  Grid,
  Hidden,
  List,
  ListItem as MuiListItem,
  ListItemProps as MuiListItemProps,
  ListItemText as MuiListItemText,
} from "@material-ui/core";
import { NASHPUSH_DASH_VERSION } from "../types/env";

interface ListItemProps extends MuiListItemProps {
  component?: string;
  href?: string;
  button: boolean | undefined;
}

const Wrapper = styled.div`
  padding: ${(props) => props.theme.spacing(1) / 4}px
    ${(props) => props.theme.spacing(4)}px;
  background: ${(props) => props.theme.footer.background};
  position: relative;
  ${(props) => props.theme.breakpoints.down("sm")} {
    display: none;
  }
`;

const ListItem = styled(MuiListItem)<ListItemProps>`
  display: inline-block;
  width: auto;
  padding-left: ${(props) => props.theme.spacing(2)}px;
  padding-right: ${(props) => props.theme.spacing(2)}px;

  &,
  &:hover,
  &:active {
    color: #ff0000;
  }
`;

const ListItemText = styled(MuiListItemText)`
  span {
    color: #000000;
    font-size: 12px;
    opacity: 0.4;
  }
`;

function Footer() {
  return (
    <Wrapper>
      <Grid container spacing={0}>
        <Hidden smDown>
          <Grid container item xs={12} md={6}>
            <List>
              <ListItem
                button={true}
                component="a"
                href="https://www.nashpush.com/privacy"
              >
                <ListItemText primary="Privacy" />
              </ListItem>
              <ListItem
                button={true}
                component="a"
                href="https://www.nashpush.com/terms"
              >
                <ListItemText primary="Terms of use" />
              </ListItem>
              <ListItem
                button={true}
                component="a"
                href="https://www.nashpush.com/cookies"
              >
                <ListItemText primary="Cookies" />
              </ListItem>
            </List>
          </Grid>
        </Hidden>
        <Grid container item xs={12} md={6} justify="flex-end">
          <List>
            <MuiListItem>
              <ListItemText
                primary={`Version ${
                  NASHPUSH_DASH_VERSION ?? "1.0.0"
                }  © ${new Date().getFullYear()} - Nashpush`}
              />
            </MuiListItem>
          </List>
        </Grid>
      </Grid>
    </Wrapper>
  );
}

export default Footer;

import { Helmet } from "react-helmet";
import { Button as MuiButton, Typography } from "@material-ui/core";
import { MuiButtonSpacingType } from "../../types/styles";
import { spacing } from "@material-ui/system";
import { useHistory } from "react-router-dom";
import routes from "../../constants/routes";
import styled from "styled-components/macro";

const Button = styled(MuiButton)<MuiButtonSpacingType>(spacing);

const Wrapper = styled.div`
  padding: ${(props) => props.theme.spacing(6)}px;
  text-align: center;
  background: transparent;

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)}px;
  }
`;

function Page500({
  error,
  resetErrorBoundary,
}: {
  error: Error;
  resetErrorBoundary?: (...args: Array<unknown>) => void;
}) {
  const history = useHistory();

  return (
    <Wrapper>
      <Helmet title="500 Error" />
      <Typography component="h1" variant="h1" align="center" gutterBottom>
        500
      </Typography>
      <Typography component="h2" variant="h5" align="center" gutterBottom>
        Internal server error.
      </Typography>
      <Typography component="h2" variant="body1" align="center" gutterBottom>
        {error?.message ?? "Something went wrong in the server-side"}
      </Typography>

      <Button
        variant="contained"
        color="secondary"
        mt={2}
        onClick={() => {
          history.push(routes.HOME);
          resetErrorBoundary && resetErrorBoundary();
        }}
      >
        Return to website
      </Button>
    </Wrapper>
  );
}

export default Page500;

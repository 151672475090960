import { Box, Button, Grid, TextField, Typography } from "@material-ui/core";
import { Campaign } from "../../types/campaign";
import { Channel } from "../../types/channel";
import { campaignCreationSchema } from "../../schemas/campaignSchema";
import { format } from "date-fns";
import { goToCampaignCreatePickPage } from "../../utils/routes";
import { useCampaignSet } from "../../contexts/CampaignSetContext";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { useLimitations } from "../../contexts/LimitationsContext";
import { yupResolver } from "@hookform/resolvers/yup";
import ChannelAutoComplete from "../campaigns/ChannelAutoComplete";
import CustomDialog from "../customizeds/CustomDialog";
import DialogActions from "../customizeds/DialogActions";
import DialogContent from "../customizeds/DialogContent";
import DialogTitle from "../customizeds/DialogTitle";
import LoadingButton from "../LoadingButton";
import React, { useEffect, useState } from "react";

interface CampaignCreationDialogProps {
  channel_id?: number;
  channel_name?: string;
  isOpen: boolean;
  handleClose: () => void;
}

const CampaignCreationDialog: React.FunctionComponent<CampaignCreationDialogProps> = ({
  channel_id,
  channel_name,
  isOpen,
  handleClose,
}) => {
  const history = useHistory();
  const { total } = useCampaignSet();
  const [campaignName, setCampaignName] = useState<string>("");

  const { checkLimit: isCreateCampaign } = useLimitations();
  const { register, errors, handleSubmit, formState, setValue } = useForm({
    mode: "onChange",
    resolver: yupResolver(campaignCreationSchema),
  });

  useEffect(() => {
    register("channel_id");
  }, [register]);

  const { isDirty, isValid } = formState;

  const [selectedOption, setSelectedOption] = useState<Channel>();

  useEffect(() => {
    if (selectedOption) {
      setValue("channel_id", selectedOption.channel_id ?? selectedOption.id);
    } else {
      setValue("channel_id", "");
    }
  }, [register, selectedOption, setValue]);

  const onSubmit = async (campaign: Campaign) => {
    const isLimit = await isCreateCampaign("campaign");
    if (!isLimit) {
      goToCampaignCreatePickPage(
        history,
        campaign.channel_id,
        encodeURIComponent(campaign.name ?? "")
      );
    }
  };

  const onClose = () => {
    setCampaignName("");
    handleClose();
  };

  useEffect(() => {
    if (channel_name && channel_id) {
      setSelectedOption({
        name: channel_name,
        channel_id: channel_id,
        id: channel_id,
      });
    }
  }, [channel_id, channel_name]);

  return (
    <CustomDialog
      id="campaignCreationDialog"
      open={isOpen}
      onClose={handleClose}
      fullWidth
      maxWidth="md"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle textId="title" onClose={onClose}>
          Push messages creation
        </DialogTitle>
        <DialogContent>
          <Grid container direction="column" spacing={4}>
            <Grid item>
              <Typography variant="subtitle1" id="currentDate">
                Push messages #{total + 1} from{" "}
                {format(new Date(), "dd.MM.yyyy")}
              </Typography>
            </Grid>
            <Grid item>
              <Typography gutterBottom>Push Messages Name</Typography>
              <TextField
                id="campaignNameField"
                variant="outlined"
                placeholder="Try to name Push messages in a meaningful way"
                name="name"
                required
                fullWidth
                defaultValue={campaignName}
                inputRef={register}
                error={!!errors.name}
                helperText={errors.name?.message}
                margin="dense"
                FormHelperTextProps={{ id: "campaignNameError" }}
              />
              <Box display="flex" alignItems="center" marginTop="10px">
                <Typography color="textSecondary">
                  It is good practice to give the name of the database so that
                  it is convenient to find and distinguish it from the rest
                </Typography>
              </Box>
            </Grid>
            <Grid item>
              <Typography gutterBottom>Related database</Typography>
              <ChannelAutoComplete
                defaultValue={
                  channel_name
                    ? {
                        name: channel_name,
                        channel_id: channel_id,
                        id: channel_id,
                      }
                    : undefined
                }
                onSelected={setSelectedOption}
                errors={errors.segment?.payload_json}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{ justifyContent: "flex-start" }}>
          <LoadingButton
            id="create"
            color="primary"
            isLoading={false}
            disabled={!isDirty || !isValid}
            type="submit"
            style={{ width: "90px" }}
          >
            Create
          </LoadingButton>
          <Button
            id="cancel"
            variant="outlined"
            disableElevation
            onClick={onClose}
            style={{ width: "90px" }}
          >
            Cancel
          </Button>
        </DialogActions>
      </form>
    </CustomDialog>
  );
};

export default CampaignCreationDialog;

import { DialogContent, Grid, Typography } from "@material-ui/core";
import { upperFirst } from "lodash";
import { useArchiveRelated } from "../../contexts/ArchiveRelatedContext";
import ArchiveTooltip from "../renders/ArchiveTooltip";
import CustomDialog from "../customizeds/CustomDialog";
import DialogTitle from "../customizeds/DialogTitle";
import React from "react";
export interface ArchiveWarningDialogProps {
  open: boolean;
  onClose: () => void;
}

const ArchiveWarningDialog: React.FunctionComponent<ArchiveWarningDialogProps> = ({
  open,
  onClose,
}) => {
  const {
    currentArchiveName,
    currentArchive,
    archiveRelatedObj,
  } = useArchiveRelated();

  return (
    <CustomDialog maxWidth="xl" open={open} onClose={onClose}>
      <DialogTitle id="limitWarningDialogTitle" onClose={onClose}>
        <Typography
          align="center"
          style={{
            fontSize: "24px",
            fontWeight: 400,
            lineHeight: "40px",
            marginBottom: "21px",
          }}
        >
          Can not archive related {currentArchive}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Grid
          container
          direction="column"
          justify="flex-start"
          spacing={4}
          style={{
            marginBottom: "32px",
          }}
        >
          {archiveRelatedObj?.affected_campaigns &&
          archiveRelatedObj?.affected_campaigns?.length > 0 ? (
            <Grid item xs>
              <Typography
                align="left"
                id="alert-dialog-description"
                style={{
                  fontSize: "18px",
                  fontWeight: 400,
                  lineHeight: "24px",
                }}
              >
                <span>
                  {`${upperFirst(currentArchive)} `}
                  <span
                    style={{
                      fontWeight: 600,
                      textDecoration: "underline",
                    }}
                  >
                    {currentArchiveName}
                  </span>
                  {` is related to a `}
                  <span
                    style={{
                      fontWeight: 600,
                      textDecoration: "underline",
                    }}
                  >
                    {archiveRelatedObj?.affected_campaigns[0]}
                  </span>

                  {archiveRelatedObj?.affected_campaigns?.length > 1 ? (
                    <>
                      <span
                        style={{
                          fontWeight: 600,
                        }}
                      >
                        {` and `}
                      </span>
                      <ArchiveTooltip
                        targetComponent={
                          <span
                            style={{
                              fontWeight: 600,
                              textDecoration: "underline",
                            }}
                          >{`+${archiveRelatedObj?.affected_campaigns?.length} more`}</span>
                        }
                        tooltip={archiveRelatedObj?.affected_campaigns}
                      />
                    </>
                  ) : (
                    ""
                  )}
                  {` campaign and can not be archived.`}
                </span>
              </Typography>
            </Grid>
          ) : undefined}
          {archiveRelatedObj?.affected_segments &&
          archiveRelatedObj?.affected_segments?.length > 0 ? (
            <Grid item xs>
              <Typography
                align="left"
                id="alert-dialog-description"
                style={{
                  fontSize: "18px",
                  fontWeight: 400,
                  lineHeight: "24px",
                }}
              >
                <span>
                  {`${upperFirst(currentArchive)} `}
                  <span
                    style={{
                      fontWeight: 600,
                      textDecoration: "underline",
                    }}
                  >
                    {currentArchiveName}
                  </span>
                  {` is related to a `}
                  <span
                    style={{
                      fontWeight: 600,
                      textDecoration: "underline",
                    }}
                  >
                    {archiveRelatedObj?.affected_segments[0]}
                  </span>

                  {archiveRelatedObj?.affected_segments?.length > 1 ? (
                    <>
                      <span
                        style={{
                          fontWeight: 600,
                        }}
                      >
                        {` and `}
                      </span>
                      <ArchiveTooltip
                        targetComponent={
                          <span
                            style={{
                              fontWeight: 600,
                              textDecoration: "underline",
                            }}
                          >{`+${archiveRelatedObj?.affected_segments?.length} more`}</span>
                        }
                        tooltip={archiveRelatedObj?.affected_segments}
                      />
                    </>
                  ) : (
                    ""
                  )}
                  {` segment and can not be archived.`}
                </span>
              </Typography>
            </Grid>
          ) : undefined}
          {archiveRelatedObj?.affected_channels &&
          archiveRelatedObj?.affected_channels?.length > 0 ? (
            <Grid item xs>
              <Typography
                align="left"
                id="alert-dialog-description"
                style={{
                  fontSize: "18px",
                  fontWeight: 400,
                  lineHeight: "24px",
                }}
              >
                <span>
                  {`${upperFirst(currentArchive)} `}
                  <span
                    style={{
                      fontWeight: 600,
                      textDecoration: "underline",
                    }}
                  >
                    {currentArchiveName}
                  </span>
                  {` is related to a `}
                  <span
                    style={{
                      fontWeight: 600,
                      textDecoration: "underline",
                    }}
                  >
                    {archiveRelatedObj?.affected_channels[0]}
                  </span>

                  {archiveRelatedObj?.affected_channels?.length > 1 ? (
                    <>
                      <span
                        style={{
                          fontWeight: 600,
                        }}
                      >
                        {` and `}
                      </span>
                      <ArchiveTooltip
                        targetComponent={
                          <span
                            style={{
                              fontWeight: 600,
                              textDecoration: "underline",
                            }}
                          >{`+${archiveRelatedObj?.affected_channels?.length} more`}</span>
                        }
                        tooltip={archiveRelatedObj?.affected_channels}
                      />
                    </>
                  ) : (
                    ""
                  )}
                  {` database and can not be archived.`}
                </span>
              </Typography>
            </Grid>
          ) : undefined}
        </Grid>
      </DialogContent>
    </CustomDialog>
  );
};

export default ArchiveWarningDialog;
// Segment Segment_Name is related to a Campaign_name111 and +5 more
// campaigns and can not be archived.

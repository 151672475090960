import { Box, Popover, Typography } from "@material-ui/core";
import { Theme, makeStyles } from "@material-ui/core/styles";
import React from "react";

interface ArchiveTooltipProps {
  targetComponent: React.ReactNode;
  tooltip: string[];
  width?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  popover: { pointerEvents: "none" },
  paper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    padding: "4px 8px",
    gap: "8px",
    wordBreak: "break-word",
    background: "rgba(0, 0, 0, 0.6)",
    borderRadius: "4px",
  },
}));

const ArchiveTooltip: React.FunctionComponent<ArchiveTooltipProps> = ({
  targetComponent,
  tooltip,
  width = "316px",
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<SVGSVGElement | null>(null);
  const open = Boolean(anchorEl);
  return (
    <Box
      component="span"
      alignItems="center"
      aria-owns={open ? "mouse-over-popover" : undefined}
      aria-haspopup="true"
      onMouseEnter={(event: any) => setAnchorEl(event.currentTarget)}
      onMouseLeave={() => setAnchorEl(null)}
      style={{ cursor: "pointer" }}
    >
      {targetComponent}

      <Popover
        id="mouse-over-popover"
        open={open}
        anchorEl={anchorEl}
        classes={{ root: classes.popover, paper: classes.paper }}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
        onClose={() => setAnchorEl(null)}
        disableRestoreFocus
      >
        {tooltip.map((item) => {
          return (
            <Typography
              style={{
                fontSize: 14,
                lineHeight: "20px",
                wordBreak: "break-word",
                color: "white",
                textAlign: "left",
              }}
            >
              {item}
            </Typography>
          );
        })}
      </Popover>
    </Box>
  );
};

export default ArchiveTooltip;

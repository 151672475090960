import { Edge, Node } from "reactflow";
import { EventObject } from "../types/events";
import { Journey, JourneyElements, TimerElement } from "../types/journey";
import { JourneyNodeType } from "../pages/journey/settings/enum";
import { UseFormMethods } from "react-hook-form";
import { convertPeriodFields, convertTimeFields } from "./channelServices";
import { getNewDateWithFormat } from "./date";
import { registerField } from "./forms";

export const registerJourneyFields = (
  data: Journey,
  form: UseFormMethods<Record<string, any>>
) => {
  form.reset(data);
  registerField(form, "id", data.id);
  registerField(form, "name", data.name);
  registerField(form, "channel", data.channel);
  registerField(form, "segment", data.segment);
  registerField(form, "error", {});
  registerField(form, "status", data.status);
};

export const convertToEdgeReactFlow = (data: JourneyElements[]) => {
  const noParentNode = data.find((item) => !item.parent_id);
  const baseData = [
    {
      id: `edge--${JourneyNodeType.eventEntry}--1`,
      type: "straight",
      source: JourneyNodeType.start,
      target: `${noParentNode ? checkNodeType(noParentNode) : "event-entry"}--${
        noParentNode?.id
      }--1`,
      sourceHandle: "start-node",
      markerEnd: undefined,
    } as Edge,
  ];
  // eslint-disable-next-line array-callback-return
  data.map((item) => {
    const nodeType = checkNodeType(item);
    if (nodeType === JourneyNodeType.triggerCheck) {
      const child = data.filter((i) => i.parent_id === item.id);
      if (child.length === 0) {
        baseData.push(
          {
            id: `edge-${item.id}--yes`,
            type: "trigger-line",
            source: `${nodeType}--${item.id}--1`,
            target: `${JourneyNodeType.end}--${item.id}--yes`,
            sourceHandle: `${nodeType}-node-source-yes`,
          },
          {
            id: `edge-${item.id}--no`,
            type: "trigger-line",
            source: `${nodeType}--${item.id}--1`,
            target: `${JourneyNodeType.end}--${item.id}--no`,
            sourceHandle: `${nodeType}-node-source-no`,
          }
        );
      } else if (child.length === 1) {
        baseData.push(
          {
            id: `edge-${item.id}--${child[0].branch ? "yes" : "no"}`,
            type: "trigger-line",
            source: `${nodeType}--${item.id}--1`,
            target: `${checkNodeType(child[0])}--${child[0]?.id}--1`,
            sourceHandle: `${nodeType}-node-source-${
              child[0].branch ? "yes" : "no"
            }`,
          },
          {
            id: `edge-${item.id}--${!child[0].branch ? "yes" : "no"}`,
            type: "trigger-line",
            source: `${nodeType}--${item.id}--1`,
            target: `${JourneyNodeType.end}--${item.id}--${
              !child[0].branch ? "yes" : "no"
            }`,
            sourceHandle: `${nodeType}-node-source-${
              !child[0].branch ? "yes" : "no"
            }`,
          }
        );
      } else {
        child.map((i) =>
          baseData.push({
            id: `edge-${item.id}--${i.branch ? "yes" : "no"}`,
            type: "trigger-line",
            source: `${nodeType}--${item.id}--1`,
            target: `${checkNodeType(i)}--${i?.id}--1`,
            sourceHandle: `${nodeType}-node-source-${i.branch ? "yes" : "no"}`,
          })
        );
      }
    } else {
      const child = data.find((i) => i.parent_id === item.id);
      baseData.push({
        id: `edge-${item.id}--1`,
        type: `${!child ? "new-entity" : "arrow-line"}`,
        source: `${nodeType}--${item.id}--1`,
        target: child?.id
          ? `${checkNodeType(child)}--${child?.id}--1`
          : `${JourneyNodeType.end}--${item.id}--1`,
        sourceHandle: `${JourneyNodeType.eventEntry}-node-source`,
      } as Edge);
    }
  });
  return baseData;
};

export const convertToNodeReactFlow = (data: JourneyElements[]) => {
  const baseData: Node[] = [
    {
      id: JourneyNodeType.start,
      type: JourneyNodeType.start,
      position: { x: 0, y: 100 },
      data: {},
    },
  ];
  const listParent = data.map((item) => item.parent_id);

  data.map((item) => {
    const nodeType = checkNodeType(item);
    baseData.push({
      id: `${nodeType}--${item.id}--1`,
      type: nodeType,
      position: { x: 100, y: 87 },
      data: { ...item, isHasChild: !listParent.includes(item.id) },
    } as Node);
    return item.parent_id;
  });

  data.map((item) => {
    if (item.event && !!item.event.timer) {
      baseData.push(
        {
          id: `${JourneyNodeType.end}--${item.id}--yes`,
          type: JourneyNodeType.end,
          position: { x: 0, y: 100 },
          data: {},
        } as Node,
        {
          id: `${JourneyNodeType.end}--${item.id}--no`,
          type: JourneyNodeType.end,
          position: { x: 0, y: 100 },
          data: {},
        } as Node
      );
    } else {
      if (!listParent.includes(item.id)) {
        baseData.push({
          id: `${JourneyNodeType.end}--${item.id}--1`,
          type: JourneyNodeType.end,
          position: { x: 0, y: 100 },
          data: {},
        } as Node);
      }
    }
    return item.parent_id;
  });
  return baseData;
};

export const initialNodes: Node[] = [
  {
    id: JourneyNodeType.start,
    type: JourneyNodeType.start,
    position: { x: 12, y: 25 },
    positionAbsolute: { x: 0, y: 100 },
    data: {},
  },
  {
    id: `${JourneyNodeType.eventEntry}--1`,
    type: JourneyNodeType.eventEntry,
    position: { x: 172, y: 12 },
    positionAbsolute: { x: 0, y: 100 },
    data: undefined,
  },
  {
    id: `${JourneyNodeType.end}--1`,
    type: JourneyNodeType.end,
    position: { x: 416, y: 36.5 },
    positionAbsolute: { x: 0, y: 100 },
    data: {},
  },
];

export const initialEdges: Edge[] = [
  {
    id: `edge--${JourneyNodeType.eventEntry}--1`,
    type: "straight",
    source: JourneyNodeType.start,
    target: `${JourneyNodeType.eventEntry}--1`,
    sourceHandle: "start-node",
    markerEnd: undefined,
  },
  {
    id: `edge-${JourneyNodeType.end}--1`,
    type: "new-entity",
    source: `${JourneyNodeType.eventEntry}--1`,
    target: `${JourneyNodeType.end}--1`,
    sourceHandle: `${JourneyNodeType.eventEntry}-node-source`,
  },
];

export const checkNodeType = (item: JourneyElements) => {
  return item.event
    ? !!item.event.timer
      ? JourneyNodeType.triggerCheck
      : JourneyNodeType.eventEntry
    : item.send
    ? JourneyNodeType.sendPush
    : item.wait
    ? JourneyNodeType.timeDelay
    : item.exit
    ? JourneyNodeType.exit
    : JourneyNodeType.exit;
};

export const getParentId = (inputId?: number | string) => {
  return Number((inputId ?? "").toString().split("--")[1]);
};

export const getWaitOption = (wait: TimerElement) => {
  if (wait.till) {
    return "till";
  } else if (
    wait.delta_parameters &&
    wait.delta_parameters.event &&
    wait.delta_parameters.event?.event_id
  ) {
    return "event";
  }
  return "delta";
};

export const getNameTimeDelay = (
  events: EventObject[],
  wait?: TimerElement
) => {
  if (wait) {
    if (wait.till) {
      return `Until ${getNewDateWithFormat(
        new Date(wait.till),
        "dd/MM/yyyy hh:mm"
      )}`;
    } else if (
      wait.delta_parameters &&
      wait.delta_parameters.event &&
      wait.delta_parameters.event?.event_id
    ) {
      const currentEvent = events?.find(
        (item) => item.id === wait?.delta_parameters?.event?.event_id
      );
      return `${
        wait.delta && convertTimeFields(wait.delta) * (wait.delta > 0 ? 1 : -1)
      } ${wait.delta && convertPeriodFields(wait.delta)} ${
        wait.delta && wait.delta > 0 ? "after" : "before"
      } ${currentEvent?.name ?? ""}`;
    }
    if (wait.delta) {
      return `${convertTimeFields(wait.delta)} ${convertPeriodFields(
        wait.delta
      )}`;
    }
  }

  return "";
};

import { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import axios from "./axios";
import routes from "../constants/routes";

type Option = {
  method?: "GET" | "POST" | "PUT" | "DELETE" | "PATCH";
  payload?: any;
  withAuth?: boolean;
  contentType?: string;
  headerParameter?: any;
};

export const fetcher = async <T>(
  url: string,
  options: AxiosRequestConfig
): Promise<T> =>
  new Promise((resolve, reject) => {
    axios({
      url,
      ...options,
    })
      .then((response: AxiosResponse<T>) => resolve(response.data))
      .catch((error: AxiosError<T>) => {
        if (error.response) {
          const { status, data } = error.response;
          if (status === 400) {
            error.message = Object.values(data).toString();
          } else if (status === 401 || status === 403 || status === 404) {
            error.message = data
              ? ((data as unknown) as { detail: string }).detail
              : error.message;
          } else if (
            status === 500 &&
            typeof data === "string" &&
            data === "<h1>Server Error (500)</h1>"
          ) {
            window.location.href = routes.PAGE_ERROR_500;
          }
          error.code = status.toString();
        }
        reject(error);
      });
  });

const getToken = () => {
  return JSON.parse(window.localStorage.getItem("auth") || "");
};

export const makeOptions = ({
  method = "GET",
  payload,
  withAuth,
  contentType = "application/json",
  headerParameter = {},
}: Option): AxiosRequestConfig => {
  const { token } = getToken();
  const options: AxiosRequestConfig = {
    method,
    headers: {
      "Content-Type": contentType,
      Accept: "application/json",
      ...headerParameter,
    },
  };

  if (token && withAuth) {
    options.headers["Authorization"] = `Bearer ${token}`;
  }

  if (payload) {
    options.data = payload;
  }

  return options;
};

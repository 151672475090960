import { DatePickerRange } from "../types/channel";
import { UserSubscription } from "../types/auth";
import { createContext, useContext, useState } from "react";
import { dateDiff } from "../utils/date";
import { endOfDay, startOfDay, subDays } from "date-fns";
import { useAuth } from "./AuthContext";
import {
  useGetSubscriptionDetails,
  useGetUserInfoWithToken,
  useGetUserQuotas,
} from "../utils/queries";
import { userQuotas } from "../types/userInfo";

const LimitationsContext = createContext<{
  isLimit: boolean;
  userSubscription: UserSubscription | undefined;
  openLimitDialog: boolean;
  setOpenLimitDialog: Function;
  selectRangeDate: (startDate?: Date, endDate?: Date) => boolean;
  checkLimit: (value?: string) => Promise<boolean>;
  defaultDateFilter: DatePickerRange;
  isSuspended: boolean;
  setIsSuspended: Function;
  isLoadingLimitationsContext: boolean;
  staffMemberStorageLimit: boolean;
}>({
  isLimit: true,
  openLimitDialog: false,
  userSubscription: undefined,
  setOpenLimitDialog: () => {},
  selectRangeDate: () => false,
  async checkLimit(value?: string): Promise<boolean> {
    return false;
  },
  defaultDateFilter: {
    startDate: startOfDay(subDays(new Date(), 2)),
    endDate: endOfDay(new Date()),
    label: "Last 3 days",
  },
  isSuspended: false,
  setIsSuspended: () => {},
  isLoadingLimitationsContext: false,
  staffMemberStorageLimit: false,
});

const LimitationsProdiver = (props: Object) => {
  const { auth } = useAuth();
  const [isLimit, setIsLimit] = useState(true);
  const [staffMemberStorageLimit, setStaffMemberStorageLimit] = useState(false);
  const [openLimitDialog, setOpenLimitDialog] = useState(false);
  const [userSubscription, setUserSubscription] = useState<UserSubscription>();
  const [defaultDateFilter, setDefaultDateFilter] = useState<DatePickerRange>({
    startDate: startOfDay(subDays(new Date(), 2)),
    endDate: endOfDay(new Date()),
    label: "Last 3 days",
  });
  const [isSuspended, setIsSuspended] = useState(false);
  const { refetch: refetchUserQuotas, data: userQuotasData } = useGetUserQuotas(
    auth.token,
    {
      onSuccess: (data) => {
        if (data) {
          // setCurrUserQuotas(data);
        }
      },
    }
  );

  const { refetch: refetchUserInfoWithToken } = useGetUserInfoWithToken(
    auth.token,
    {
      onSuccess: (data) => {
        setStaffMemberStorageLimit(data?.is_staff === true);
        if (data?.is_staff === true) {
          setIsLimit(false);
          setDefaultDateFilter({
            startDate: startOfDay(subDays(new Date(), 6)),
            endDate: endOfDay(new Date()),
            label: "Last 7 days",
          });
        } else {
          setDefaultDateFilter({
            startDate: startOfDay(subDays(new Date(), 2)),
            endDate: endOfDay(new Date()),
            label: "Last 3 days",
          });
        }
      },
    }
  );

  const { isLoading: isLoadingLimitationsContext } = useGetSubscriptionDetails(
    auth.token,
    {
      onSuccess: async (data) => {
        if (
          !staffMemberStorageLimit &&
          (!data?.price || data?.price?.price_lookup === "free")
        ) {
          setDefaultDateFilter({
            startDate: startOfDay(subDays(new Date(), 2)),
            endDate: endOfDay(new Date()),
            label: "Last 3 days",
          });
          setIsLimit(true);
          refetchUserQuotas();
        } else {
          setIsLimit(false);
          setDefaultDateFilter({
            startDate: startOfDay(subDays(new Date(), 6)),
            endDate: endOfDay(new Date()),
            label: "Last 7 days",
          });
        }
        await refetchUserInfoWithToken();
        setUserSubscription(data);
      },
      onError: (e) => {},
    }
  );

  const checkLimit = async (value?: string) => {
    if (staffMemberStorageLimit) {
      return false;
    }
    let limit = false;
    await refetchUserQuotas();
    limit = await checkValue(value, userQuotasData);
    setOpenLimitDialog(limit);
    return limit;
  };

  const checkValue = async (value?: string, currQuotas?: userQuotas) => {
    if (currQuotas) {
      // check campaign codition
      if (
        value === "campaign" &&
        currQuotas.campaigns_cur &&
        currQuotas.campaigns_max
      ) {
        return currQuotas.campaigns_cur >= currQuotas.campaigns_max;
      } else if (
        value === "windowsPools" &&
        currQuotas.window_pools_cur &&
        currQuotas.window_pools_max
      ) {
        return currQuotas.window_pools_cur >= currQuotas.window_pools_max;
      } else if (
        value === "segments" &&
        currQuotas.segments_cur &&
        currQuotas.segments_max
      ) {
        return currQuotas.segments_cur >= currQuotas.segments_max;
      } else if (
        value === "databases" &&
        currQuotas.channels_cur &&
        currQuotas.channels_max
      ) {
        return currQuotas.channels_cur >= currQuotas.channels_max;
      } else if (
        value === "pushpools" &&
        currQuotas.push_pools_cur &&
        currQuotas.push_pools_max
      ) {
        return currQuotas.push_pools_cur >= currQuotas.push_pools_max;
      }
    }

    return false;
  };

  const selectRangeDate = (startDate?: Date, endDate?: Date) => {
    if (staffMemberStorageLimit) {
      return false;
    }
    const limitDate = parseFloat(
      userSubscription?.price?.features?.analytics_days?.toString() ?? "365"
    );
    if (!!startDate && !!endDate) {
      const count = dateDiff(startDate, endDate);
      if (isLimit && count > limitDate) {
        return true;
      }
    }
    return false;
  };

  return (
    <LimitationsContext.Provider
      value={{
        isLimit,
        openLimitDialog,
        userSubscription,
        setOpenLimitDialog,
        selectRangeDate,
        checkLimit,
        defaultDateFilter,
        isSuspended,
        setIsSuspended,
        isLoadingLimitationsContext,
        staffMemberStorageLimit,
      }}
      {...props}
    />
  );
};

const useLimitations = () => {
  const context = useContext(LimitationsContext);

  if (context === undefined) {
    throw new Error(`useLimitations must be used within a LimitationsProdiver`);
  }
  return context;
};

export { LimitationsProdiver, useLimitations };

import "../../vendor/perfect-scrollbar.css";
import {
  Box,
  Chip,
  IconButton,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
  makeStyles,
} from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { NavLink, useHistory } from "react-router-dom";
import { RouteChildType } from "../../types/routes";
import { rgba } from "polished";
import React from "react";
import styled from "styled-components/macro";

type SidebarCategoryPropsType = {
  name: string;
  icon: JSX.Element;
  classes?: string;
  isOpen?: boolean;
  isCollapsable?: boolean;
  badge?: string | number;
  activeClassName?: string;
  onClick?: (event?: React.MouseEvent) => void;
  to?: string;
  component?: typeof NavLink;
  exact?: boolean;
  id?: string;
  miniCategory?: boolean;
  routeChildren?: RouteChildType[];
  path?: string;
  orderIcon?: JSX.Element;
  orderOnClick?: (event?: React.MouseEvent) => void;
  isSuspended: boolean;
};

type CategoryType = {
  activeClassName?: string;
  button?: boolean;
  onClick?: (event?: React.MouseEvent) => void;
  to?: string;
  component?: typeof NavLink;
  exact?: boolean;
};

const Category = styled(ListItem)<CategoryType>`
  height: 56px;

  ${(props) => props.theme.breakpoints.down("md")} {
    padding-top: ${(props) => props.theme.spacing(5)}px;
    padding-bottom: ${(props) => props.theme.spacing(5)}px;
    padding-left: ${(props) => props.theme.spacing(6)}px;
  }
  ${(props) => props.theme.breakpoints.up("md")} {
    padding-top: ${(props) => props.theme.spacing(4)}px;
    padding-bottom: ${(props) => props.theme.spacing(4)}px;
    padding-left: ${(props) => props.theme.spacing(6)}px;
  }

  padding-right: ${(props) => props.theme.spacing(7)}px;
  font-weight: ${(props) => props.theme.typography.fontWeightRegular};
  align-items: center;

  svg {
    color: ${(props) => props.theme.sidebar.color};
    font-size: 20px;
    width: 20px;
    height: 20px;
  }

  &:hover {
    background-color: #353535;
  }

  &.${(props) => props.activeClassName} {
    background-color: #353535;

    span {
      color: ${(props) => props.theme.sidebar.color};
    }
  }
`;

const CategoryText = styled(ListItemText)`
  margin: 0;
  span {
    color: ${(props) => props.theme.sidebar.color};
    font-size: ${(props) => props.theme.typography.subtitle2.fontSize}px;
    padding: 0 ${(props) => props.theme.spacing(3)}px;
    line-height: 1;
  }
`;

const CategoryIconLess = styled(ExpandLess)`
  color: ${(props) => rgba(props.theme.sidebar.color, 0.5)};
`;

const CategoryIconMore = styled(ExpandMore)`
  color: ${(props) => rgba(props.theme.sidebar.color, 0.5)};
`;

export const LinkBadge = styled(Chip)`
  font-size: 11px;
  font-weight: ${(props) => props.theme.typography.fontWeightBold};
  height: 20px;
  position: absolute;
  right: 28px;
  top: 8px;
  background: ${(props) => props.theme.sidebar.badge.background};

  span.MuiChip-label,
  span.MuiChip-label:hover {
    cursor: pointer;
    color: ${(props) => props.theme.sidebar.badge.color};
    padding-left: ${(props) => props.theme.spacing(2)}px;
    padding-right: ${(props) => props.theme.spacing(2)}px;
  }
`;

const CategoryBadge = styled(LinkBadge)`
  top: 12px;
`;

const CustomBox = styled(Box)`
  display: flex;
  padding-right: ${(props) => props.theme.spacing(3)}px;
  svg {
    color: ${(props) => props.theme.sidebar.color};
    font-size: 20px;
    width: 20px;
    height: 20px;
  }
  align-items: center;
`;

const useStyles = makeStyles({
  tooltip: {
    fontSize: "1em",
  },
});

const SidebarCategory: React.FC<SidebarCategoryPropsType> = ({
  path,
  name,
  icon,
  classes,
  isOpen,
  isCollapsable = false,
  badge,
  miniCategory = false,
  onClick,
  routeChildren,
  orderIcon,
  orderOnClick,
  isSuspended,
  ...rest
}) => {
  const [anchorEl, setAnchorEl] = React.useState<
    Element | ((element: Element) => Element) | null | undefined
  >(null);
  const history = useHistory();
  const classesStyle = useStyles();

  const handleClick = (event?: React.MouseEvent<Element, MouseEvent>) => {
    setAnchorEl(event?.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuClick = (route: string) => {
    handleClose();
    history.push(route, {
      openCreateModal: false,
    });
  };
  return (
    <>
      <Box display="flex">
        <Category
          disabled={isSuspended}
          button={true}
          onClick={miniCategory && routeChildren ? handleClick : onClick}
          style={!!orderIcon && !!orderOnClick ? { paddingRight: 0 } : {}}
          {...rest}
        >
          <Tooltip
            title={!miniCategory ? "" : name}
            placement="right-end"
            classes={{ tooltip: classesStyle.tooltip }}
          >
            {icon}
          </Tooltip>
          {!miniCategory && (
            <CategoryText
              onClick={() => (!!path ? handleMenuClick(path) : handleClick)}
            >
              {name}
            </CategoryText>
          )}
          {isCollapsable && !miniCategory ? (
            isOpen ? (
              <CategoryIconMore />
            ) : (
              <CategoryIconLess />
            )
          ) : null}
          {badge ? <CategoryBadge label={badge} /> : ""}
        </Category>

        {!!orderIcon && !!orderOnClick && !miniCategory && (
          <CustomBox>
            <IconButton onClick={orderOnClick}>{orderIcon}</IconButton>
          </CustomBox>
        )}
      </Box>

      {miniCategory && (
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={miniCategory && Boolean(anchorEl)}
          onClose={handleClose}
          elevation={8}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: "center",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "center",
            horizontal: "left",
          }}
          PaperProps={{
            style: {
              width: 250,
            },
          }}
        >
          {routeChildren?.map((route) => (
            <MenuItem
              key={route.name}
              onClick={() => handleMenuClick(route.path)}
              disabled={isSuspended || !route.path}
            >
              {route.name}
            </MenuItem>
          ))}
        </Menu>
      )}
    </>
  );
};

export default SidebarCategory;

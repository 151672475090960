import { SegmentsDashboardSetProdiver } from "../contexts/SegmentsDashboardSetContext";
import Dashboard, { DashboardPropsType } from "./Dashboard";
import React from "react";
const SegmentsLayout: React.FunctionComponent<DashboardPropsType> = (props) => {
  return (
    <SegmentsDashboardSetProdiver>
      <Dashboard {...props} />
    </SegmentsDashboardSetProdiver>
  );
};

export default SegmentsLayout;

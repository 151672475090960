import * as yup from "yup";
// import { compareAsc } from "date-fns";
// import { createCurrentDateWithTimezone } from "../utils/date";

export const campaignCreationSchema = yup.object().shape({
  name: yup
    .string()
    .required("Push messages name is required")
    .max(50, "Push messages length must be less than 50 symbols"),
  segment: yup
    .object()
    .shape({
      id: yup.number(),
      payload_json: yup.object().notRequired().nullable(),
      name: yup.string(),
    })
    .nullable()
    .notRequired(),
});

export const campaignSaveActiveSchema = yup
  .object()
  .shape({
    // message_pool: yup.number().nullable().required("Template is required"),
    is_active: yup.boolean().nullable(),
    is_draft: yup.boolean().nullable(),
    auto_content_management: yup.boolean().nullable(),
    pool_size_value: yup.number().nullable(),
    target_url: yup
      .string()
      .required("This field is required to start the campaign.")
      .matches(
        /^((http|https):\/\/){1}[a-zA-Z\u0400-\u04FF0-9]+([-.]{1}[a-zA-Z\u0400-\u04FF0-9]+)*\.[a-zA-Z\u0400-\u04FF]{2,256}(:[0-9]{1,5})?(\/.*)?$/,
        "Must be a URL"
      )
      .max(500),
    tracking_field_names: yup
      .array(
        yup.object().shape({
          name: yup.string().required("Variable name is required").min(0),
          tracker_func_name: yup.string().when("name", {
            is: (val: string) => val !== "",
            then: yup
              .string()
              .required("Variable value must not empty")
              .nullable(),
            otherwise: yup.string().nullable(),
          }),
        })
      )
      .nullable(),
    message_button: yup
      .array(
        yup.object().shape({
          title: yup.string().required("Button name is required").nullable(),
          click_action_data: yup
            .string()
            .required("Button URL is required")
            .matches(
              /^((http|https):\/\/)?[a-zA-Z\u0400-\u04FF0-9]+([-.]{1}[a-zA-Z\u0400-\u04FF0-9]+)*\.[a-zA-Z\u0400-\u04FF]{2,256}(:[0-9]{1,5})?(\/.*)?$/,
              "Must be a URL"
            )
            .nullable(),
        })
      )
      .nullable(),
    triggered_delivery: yup
      .object()
      .shape({
        // interval: yup
        //   .object()
        //   .shape({
        //     every: yup
        //       .number()
        //       .required("Fill the field to proceed")
        //       .integer("Interval value must be a integer")
        //       .moreThan(0, "Interval value must be greater than 0"),
        //     period: yup
        //       .string()
        //       .required("Interval period value is required")
        //       .oneOf(
        //         ["days", "hours", "minutes", "seconds", "microseconds"],
        //         `Interval period value must be one of "days", "hours", "minutes", "seconds" or "microseconds"`
        //       ),
        //   })
        //   .nullable(),
        // message_instance: yup
        //   .object()
        //   .shape({
        //     title: yup
        //       .string()
        //       .required("Title Template period value is required"),
        //     body: yup.string().required("Message Template  value is required"),
        //   })
        //   .nullable(),
        // start_time: yup
        //   .string()
        //   .nullable()
        //   .test(
        //     "start_time",
        //     "Unable to select the time from the past",
        //     (value, obj) => {
        //       if (value) {
        //         if (
        //           compareAsc(
        //             Date.parse(value),
        //             Date.parse(
        //               (
        //                 createCurrentDateWithTimezone(
        //                   obj?.parent?.parent?.timezone?.name
        //                 ) ?? new Date()
        //               ).toISOString()
        //             )
        //           ) >= 0
        //         ) {
        //           return true;
        //         }
        //         return false;
        //       }
        //       return true;
        //     }
        //   ),
      })
      .required("This Triggered Deliveries is required to start the campaign."),
  })
  .concat(campaignCreationSchema);

export const campaignSaveDraftSchema = yup.object().shape({
  tracking_field_names: yup
    .array(
      yup.object().shape({
        name: yup.string().required("Variable name is required").min(0),
        tracker_func_name: yup.string().when("name", {
          is: (val: string) => val !== "",
          then: yup
            .string()
            .required("Variable value must not empty")
            .nullable(),
          otherwise: yup.string().nullable(),
        }),
      })
    )
    .nullable(),
  target_url: yup
    .string()
    .required("This field is required to start the campaign.")
    .matches(
      /^((http|https):\/\/){1}[a-zA-Z\u0400-\u04FF0-9]+([-.]{1}[a-zA-Z\u0400-\u04FF0-9]+)*\.[a-zA-Z\u0400-\u04FF]{2,256}(:[0-9]{1,5})?(\/.*)?$/,
      "Must be a URL"
    )
    .max(500),
  triggered_delivery: yup
    .object()
    .shape({
      // interval: yup
      //   .object()
      //   .shape({
      //     every: yup
      //       .number()
      //       .required("Fill the field to proceed")
      //       .integer("Interval value must be a integer")
      //       .moreThan(0, "Interval value must be greater than 0"),
      //     period: yup
      //       .string()
      //       .required("Interval period value is required")
      //       .oneOf(
      //         ["days", "hours", "minutes", "seconds", "microseconds"],
      //         `Interval period value must be one of "days", "hours", "minutes", "seconds" or "microseconds"`
      //       ),
      //   })
      //   .nullable(),
      // message_instance: yup
      //   .object()
      //   .shape({
      //     title: yup
      //       .string()
      //       .required("Title Template period value is required"),
      //     body: yup.string().required("Message Template  value is required"),
      //   })
      //   .nullable(),
      // start_time: yup
      //   .string()
      //   .nullable()
      //   .test(
      //     "start_time",
      //     "Unable to select the time from the past",
      //     (value, obj) => {
      //       if (value) {
      //         if (
      //           compareAsc(
      //             Date.parse(value),
      //             Date.parse(
      //               (
      //                 createCurrentDateWithTimezone(
      //                   obj?.parent?.parent?.timezone?.name
      //                 ) ?? new Date()
      //               ).toISOString()
      //             )
      //           ) >= 0
      //         ) {
      //           return true;
      //         }
      //         return false;
      //       }
      //       return true;
      //     }
      //   ),
    })
    .required("This Triggered Deliveries is required to start the campaign."),
});

export const campaignSchema = yup
  .object()
  .shape({
    // interval: yup.object().shape({
    //   every: yup
    //     .number()
    //     .required("Fill the field to proceed")
    //     .integer("Interval value must be a integer")
    //     .moreThan(0, "Interval value must be greater than 0"),
    //   period: yup
    //     .string()
    //     .required("Interval period value is required")
    //     .oneOf(
    //       ["days", "hours", "minutes", "seconds", "microseconds"],
    //       `Interval period value must be one of "days", "hours", "minutes", "seconds" or "microseconds"`
    //     ),
    // }),
    // start_time: yup
    //   .string()
    //   .nullable()
    //   .test(
    //     "start_time",
    //     "Unable to select the time from the past",
    //     (value, obj) => {
    //       if (value) {
    //         if (
    //           compareAsc(
    //             Date.parse(value),
    //             Date.parse(
    //               (
    //                 createCurrentDateWithTimezone(obj.parent?.timezone?.name) ??
    //                 new Date()
    //               ).toISOString()
    //             )
    //           ) >= 0
    //         ) {
    //           return true;
    //         }
    //         return false;
    //       }
    //       return true;
    //     }
    //   ),
  })
  .concat(campaignSaveActiveSchema);

import { Dialog, DialogProps, Theme, useMediaQuery } from "@material-ui/core";
import makeStyles from "@material-ui/styles/makeStyles";

const useStyle = makeStyles((theme: Theme) => ({
  paper: {
    padding: 16,
    width: "696px",
    maxHeight: "550px",
    boxShadow: "0px 8px 32px rgba(0, 0, 0, 0.32)",
    overflow: "hidden",
  },
  paperMobile: {
    padding: 24,
    width: "100vw",
    maxHeight: "100vh",
    boxShadow: "0px 8px 32px rgba(0, 0, 0, 0.32)",
    overflow: "hidden",
    height: "100vh",
    margin: 0,
    overflowX: "hidden",
  },
}));

const CustomDialog: React.FunctionComponent<DialogProps> = ({
  children,
  PaperProps,
  ...props
}) => {
  const mdScreen = useMediaQuery("(min-width:768px)");
  const classes = useStyle();
  return (
    <Dialog
      PaperProps={
        PaperProps ?? {
          className: mdScreen ? classes.paper : classes.paperMobile,
        }
      }
      {...props}
    >
      {children}
    </Dialog>
  );
};

export default CustomDialog;

import { CampaignSetProdiver } from "../contexts/CampaignSetContext";
import Dashboard, { DashboardPropsType } from "./Dashboard";
import React from "react";
const CampaignLayout: React.FunctionComponent<DashboardPropsType> = (props) => {
  return (
    <CampaignSetProdiver>
      <Dashboard {...props} />
    </CampaignSetProdiver>
  );
};

export default CampaignLayout;

import {
  AnalyticsQuery,
  IndicatorsAnalytics,
} from "../types/indicatorsAnalytics";
import { FilterModel, TextFilterModel } from "../types/query";
import { formatIsoUtcDate, parseDateTimeString } from "./date";
import _ from "lodash";

export function convertCustomizeModel(filterModel: FilterModel) {
  const result = {} as AnalyticsQuery;
  const tmpFilterModel = { ...filterModel };
  _.forEach(tmpFilterModel, (model, key) => {
    if (model.filterType === "date") {
      if (model.dateFrom !== "all" && !!model.dateTo) {
        result.start = model.dateFrom
          ? formatIsoUtcDate(parseDateTimeString(model.dateFrom))
          : model.dateFrom;
        result.end = model.dateTo
          ? formatIsoUtcDate(parseDateTimeString(model.dateTo))
          : model.dateTo;
      }
    } else if (key === "channel_id") {
      let channel = (model as TextFilterModel).filter;
      result.channels = channel ? [channel] : undefined;
    }
  });
  return result;
}

export function convertData(data: any, sortModel?: any) {
  let data_channels: IndicatorsAnalytics[] = data;
  if (!!sortModel && sortModel.length > 0) {
    return _.orderBy(
      data_channels,
      [sortModel[0].colId],
      [sortModel[0].sort ?? false]
    );
  }
  return data_channels;
}

export function checkMaxValue(data: IndicatorsAnalytics[]) {
  let maxValue = Math.max(
    ...data.map((item) =>
      Math.max(
        item.page_visits,
        item.subscribers,
        item.sent_messages,
        item.clicked_messages
      )
    ),
    1
  );

  return checkMax(maxValue);
}

export function checkMax(value: number) {
  let unit = "";
  let maxValue = 1;
  if (value > 1000000000) {
    maxValue = 1000000000;
    unit = "B";
  } else if (value > 1000000) {
    maxValue = 1000000;
    unit = "M";
  } else if (value > 100) {
    maxValue = 1000;
    unit = "K";
  } else {
    maxValue = 1;
  }

  return { maxValue, unit };
}

export function getLable(value: number) {
  let unit = "";
  let maxValue = 1;
  if (value > 1000000000) {
    maxValue = 1000000000;
    unit = "B";
  } else if (value > 1000000) {
    maxValue = 1000000;
    unit = "M";
  } else if (value > 100) {
    maxValue = 1000;
    unit = "K";
  } else {
    maxValue = 1;
  }

  return (maxValue > 1 ? (value / maxValue).toFixed(2) : value) + unit;
}

import { FirebaseAccounts } from "../types/firebaseAccounts";
import { GridApi } from "@ag-grid-enterprise/all-modules";
import { createContext, useContext, useState } from "react";
import { useGetUserFirebaseCredentialsList } from "../utils/queries";

const FirebaseAccountsDashboardSetContext = createContext<{
  firebaseAccounts: FirebaseAccounts[];
  total: number;
  isNoFirebaseAccounts: boolean;
  handleRefresh: Function;
  gridApi: GridApi | null;
  setGridApi: Function;
  isOpen: boolean;
  setIsOpen: Function;
  isOpenDelete: boolean;
  setIsOpenDelete: Function;
}>({
  total: 0,
  firebaseAccounts: [],
  isNoFirebaseAccounts: false,
  handleRefresh: () => {},
  gridApi: null,
  setGridApi: () => {},
  isOpen: false,
  setIsOpen: () => {},
  isOpenDelete: false,
  setIsOpenDelete: () => {},
});

const FirebaseAccountsDashboardSetProdiver = (props: Object) => {
  const [total, setTotal] = useState(0);
  const [firebaseAccounts, setFirebaseAccounts] = useState<FirebaseAccounts[]>(
    []
  );
  const [isNoFirebaseAccounts, setIsNoFirebaseAccounts] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [gridApi, setGridApi] = useState<GridApi | null>(null);

  const { refetch } = useGetUserFirebaseCredentialsList({
    onSuccess: (data) => {
      if (data) {
        setIsNoFirebaseAccounts(data.length === 0);
        setFirebaseAccounts(data);
        setTotal(data.length);
      }
    },
  });
  const handleRefresh = () => {
    if (gridApi) {
      gridApi?.refreshServerSideStore({});
    }
    refetch();
  };

  return (
    <>
      <FirebaseAccountsDashboardSetContext.Provider
        value={{
          total,
          firebaseAccounts,
          isNoFirebaseAccounts,
          handleRefresh,
          setGridApi,
          gridApi,
          isOpen,
          setIsOpen,
          isOpenDelete,
          setIsOpenDelete,
        }}
        {...props}
      />
    </>
  );
};

const useFirebaseAccountsDashboardSet = () => {
  const context = useContext(FirebaseAccountsDashboardSetContext);

  if (context === undefined) {
    throw new Error(
      `useFirebaseAccountsDashboardSet must be used within a FirebaseAccountsDashboardSetProdiver`
    );
  }
  return context;
};

export {
  FirebaseAccountsDashboardSetProdiver,
  useFirebaseAccountsDashboardSet,
};

import { Autocomplete } from "@material-ui/lab";
import { Channel } from "../../types/channel";
import { CircularProgress, TextField } from "@material-ui/core";
import { useDebouncedCallback } from "use-debounce/lib";
import { useSearchChannel } from "../../utils/queries";
import React, { useEffect, useState } from "react";

export interface ChannelAutoCompleteProps {
  onSelected: (channel?: Channel) => void;
  errors: any;
  defaultValue?: Channel;
  size?: "small" | "medium";
  is_use_campaign?: boolean;
  exclude?: string;
  status?: string;
  required?: boolean;
}

const ChannelAutoComplete: React.FunctionComponent<ChannelAutoCompleteProps> = ({
  onSelected,
  errors,
  defaultValue,
  size,
  is_use_campaign,
  exclude,
  status,
  required = true,
}) => {
  const [options, setOptions] = useState<Channel[]>([]);
  const [selected, setSelected] = useState(defaultValue ?? null);
  const [searchText, setSearchText] = useState("");

  const { mutate: searchChannel, isLoading: searchLoading } = useSearchChannel({
    onSuccess: (data) => {
      if (data && data.length > 0) {
        setOptions(data);
      } else setOptions([]);
    },
    ignoreLoading: true,
  });

  const searchChannelName = useDebouncedCallback((searchText: string) => {
    searchChannel({
      searchString: searchText,
      is_used_campaigns: is_use_campaign,
      exclude: exclude,
      status: status,
    });
  }, 500);

  const onChannelSelected = (_e: any, data: Channel | null) => {
    if (data && typeof data === "object") {
      searchChannelName.cancel();
      setSelected(data);
      onSelected(data);
      setSearchText("");
    }
  };

  const onInutChanged = (_e: any, newInputValue: string) => {
    searchChannelName.cancel();
    setSearchText(newInputValue);
    if (newInputValue.length > 0) {
      searchChannelName.callback(newInputValue);
    } else {
      onSelected(undefined);
      setOptions([]);
    }
  };

  useEffect(() => {
    if (defaultValue) {
      setSelected(defaultValue);
      onChannelSelected({}, defaultValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue]);

  return (
    <Autocomplete
      id="searField"
      size={size}
      options={options}
      value={selected}
      onChange={onChannelSelected}
      onInputChange={onInutChanged}
      noOptionsText={
        "There's no user databases matched your search, please try again"
      }
      loading={searchLoading}
      loadingText="Searching user Database..."
      getOptionLabel={(option) => `${option?.name}`}
      getOptionSelected={(option, value) =>
        option.channel_id === value.channel_id
      }
      clearOnBlur
      handleHomeEndKeys
      open={searchText.length !== 0}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={"Search from your databases"}
          fullWidth={true}
          variant={"outlined"}
          required={required}
          error={!!errors}
          helperText={errors?.message}
          autoComplete="off"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {searchLoading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
          margin="dense"
        />
      )}
    />
  );
};

export default ChannelAutoComplete;

import { format, formatDistanceToNow, parse } from "date-fns";
import { getTimezone } from "countries-and-timezones";

export const getDateTitle = (date: Date) => format(date, "MMMM dd, yyyy");
export const getDateTitleSub = (date: Date) => format(date, "MMM dd yyyy");
export const getDate = (date: Date) => format(date, "yyyy-MM-dd");
export const getUtcDate = (date: Date) => {
  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds()
  );
};
export const formatIsoUtcDate = (date: Date) => {
  var now_utc = getUtcDate(date);
  return format(now_utc, "yyyy-MM-dd'T'HH:mm:ss'Z'");
};

export const concatIsoDate = (date: Date, offset?: string): string => {
  return offset
    ? format(date, "yyyy-MM-dd'T'HH:mm:ss") + offset
    : getUserIsoDate(date);
};

export const getIsoUtcDateNow = (offset?: string) => {
  var now_utc = getUtcDate(new Date());
  return concatIsoDate(now_utc, offset);
};

export const getTimezoneName = () =>
  Intl.DateTimeFormat().resolvedOptions().timeZone;

export const getUserTimezone = () => getTimezone(getTimezoneName());

export const getUserIsoDate = (date: Date) => {
  const userTimezone = getUserTimezone();
  return concatIsoDate(date, userTimezone?.utcOffsetStr);
};

export const createDateWithTimezone = (
  dateString: string,
  timeZone: string
) => {
  const date = new Date(dateString);
  const dateTimezoneString = date.toLocaleString("en-US", { timeZone });
  return new Date(dateTimezoneString);
};

export const convertDateToTimezone = (
  dateString: string,
  timeZone: string,
  newTimezone: string
) => {
  const oldStartTime = createDateWithTimezone(dateString, timeZone);
  const dateTimezoneString = oldStartTime.toLocaleString("en-US", {
    timeZone: newTimezone,
  });
  return new Date(dateTimezoneString);
};

export const parseDateTimeString = (date: string) => {
  return parse(date, "yyyy-MM-dd HH:mm:ss", new Date());
};

export const parseDateString = (date: string) => {
  return parse(date, "yyyy-MM-dd", new Date());
};
export const getDateToString = (date: Date) =>
  format(date, "yyyy-MM-dd HH:mm:ss");

export const createCurrentDateWithTimezone = (timeZone: string) => {
  const date = new Date();
  const dateTimezoneString = date.toLocaleString("en-US", { timeZone });
  return new Date(dateTimezoneString);
};

export const getDateLable = (date: Date) => format(date, "dd/MM/yyyy");
export const getDateFileName = (dateStart: Date, dateEnd: Date) =>
  format(dateStart, "MMM_dd_yyyy") + "_" + format(dateEnd, "MMM_dd_yyyy");

export const formatDistanceDay = (date: Date) => {
  const oneDay = 1000 * 3600 * 24;
  const distance = Date.now() - date.getTime();
  if (distance < oneDay && distance > 0) {
    return formatDistanceToNow(date, { addSuffix: true });
  }
  return getDateTitle(date);
};
export const getNewDateWithFormat = (date: Date, newFormat: string) =>
  format(date, newFormat);

export const dateDiff = (dateStart: Date, dateEnd: Date) => {
  var diff = dateEnd.getTime() - dateStart.getTime();
  var days = diff / (1000 * 60 * 60 * 24);

  return days;
};

export function convertNumtoDateInWeek(num: number) {
  switch (num) {
    case 1:
      return "Monday";
    case 2:
      return "Tuesday";
    case 3:
      return "Wednesday";
    case 4:
      return "Thursday";
    case 5:
      return "Friday";
    case 6:
      return "Saturday";
    case 0:
      return "Sunday";
    default:
      return "Every day";
  }
}

import {
  CssBaseline,
  Divider,
  Grid,
  Paper as MuiPaper,
  Typography,
} from "@material-ui/core";
import { GlobalStyleProps } from "../types/styles";
import { spacing } from "@material-ui/system";
import AuthAppBar from "../components/AuthAppBar";
import React from "react";
import styled, { createGlobalStyle } from "styled-components/macro";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const GlobalStyle = createGlobalStyle<GlobalStyleProps>`
  html,
  body,
  #root {
    height: 100%;
  }

  body {
    background: ${(props) => props.theme.palette.background.default};
  }
`;

const Root = styled.div`
  display: flex;
  min-height: 98vh;
`;

export const AppContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
`;

const Paper = styled(MuiPaper)(spacing);

export const MainContent = styled(Paper)`
  flex: 1;
  background: ${(props) => props.theme.palette.background.default};
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  display: flex;

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }

  .MuiPaper-root .MuiPaper-root {
    box-shadow: none;
  }
`;

const CopyrightContent = styled(Grid)`
  padding: 24px 80px;
  width: 100%;
`;

const Coppyright: React.FC = () => {
  const mdScreen = useMediaQuery("(min-width:768px)");
  return (
    <CopyrightContent
      container
      justify="space-between"
      alignItems="center"
      wrap={mdScreen ? "nowrap" : "wrap"}
      spacing={4}
    >
      {mdScreen && (
        <Grid item xs={10}>
          <Typography color="textSecondary">Copyright © 2023</Typography>
        </Grid>
      )}
      <Grid item xs>
        <Grid
          container
          justify="space-around"
          alignItems="center"
          wrap="nowrap"
          spacing={4}
        >
          <Grid item>
            <Typography
              color="textSecondary"
              component="a"
              href="https://www.nashpush.com/privacy"
              style={{ textDecoration: "none" }}
            >
              Privacy
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              color="textSecondary"
              component="a"
              href="https://www.nashpush.com/terms"
              style={{ textDecoration: "none", whiteSpace: "nowrap" }}
            >
              Terms of use
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              color="textSecondary"
              component="a"
              href="https://www.nashpush.com/cookies"
              style={{ textDecoration: "none" }}
            >
              Cookies
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      {!mdScreen && (
        <Grid item xs={12}>
          <Typography color="textSecondary" style={{ textAlign: "center" }}>
            Copyright © 2023
          </Typography>
        </Grid>
      )}
    </CopyrightContent>
  );
};

const Auth: React.FC = ({ children }) => {
  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />
      <AppContent>
        <AuthAppBar />
        <MainContent>{children}</MainContent>
        <Divider />
        <Coppyright />
      </AppContent>
    </Root>
  );
};

export default Auth;

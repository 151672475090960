import { Grid, LinearProgress, useMediaQuery } from "@material-ui/core";
import {
  Theme,
  WithStyles,
  createStyles,
  withStyles,
} from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import React from "react";
import Typography from "@material-ui/core/Typography";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      marginLeft: "5px",
      padding: theme.spacing(4),
    },
    rootMobile: {
      margin: "0px",
      padding: 0,
    },
  });

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id?: string;
  children: React.ReactNode;
  action?: React.ReactNode;
  onClose?: () => void;
  loading?: boolean;
  textId?: string;
  style?: any;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const {
    children,
    classes,
    onClose,
    action,
    loading,
    textId,
    ...other
  } = props;
  const mdScreen = useMediaQuery("(min-width:768px)");
  return (
    <>
      <MuiDialogTitle
        disableTypography
        className={mdScreen ? classes.root : classes.rootMobile}
        {...other}
      >
        <Grid container justify="space-between" alignItems="center">
          <Grid item>
            <Typography
              variant="h1"
              id={textId}
              style={
                mdScreen
                  ? {}
                  : {
                      fontWeight: 400,
                      fontSize: "17px",
                      lineHeight: "24px",
                    }
              }
            >
              {children}
            </Typography>
          </Grid>
          <Grid item>
            <Grid container alignItems="center">
              {action && <Grid item>{action}</Grid>}
              {onClose && (
                <Grid item>
                  <IconButton aria-label="close" onClick={onClose}>
                    <CloseIcon color="action" />
                  </IconButton>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </MuiDialogTitle>
      {loading && <LinearProgress />}
    </>
  );
});

export default DialogTitle;

import {
  Button,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
} from "@material-ui/core";
import { goToPaymentPricingPlan } from "../../utils/routes";
import { useHistory } from "react-router-dom";
import CustomDialog from "../customizeds/CustomDialog";
import DialogTitle from "../customizeds/DialogTitle";
import React from "react";
export interface LimitWarningDialogProps {
  open: boolean;
  onClose: () => void;
  isFree: boolean;
}

const LimitWarningDialog: React.FunctionComponent<LimitWarningDialogProps> = ({
  open,
  onClose,
  isFree,
}) => {
  const history = useHistory();
  return (
    <CustomDialog maxWidth="xl" open={open} onClose={onClose}>
      <DialogTitle id="limitWarningDialogTitle" onClose={onClose}>
        <Typography
          align="center"
          style={{
            fontSize: "24px",
            fontWeight: 400,
            lineHeight: "40px",
            marginBottom: "21px",
          }}
        >
          Limited access
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container justify="flex-start" spacing={4}>
          <Grid item xs>
            <Typography
              align="left"
              id="alert-dialog-description"
              style={{ fontSize: "18px", fontWeight: 400, lineHeight: "24px" }}
            >
              {isFree
                ? "We are sorry, but this feature is available only for the PRO subscribers."
                : "We are sorry, but this feature is unavailable within your subscription."}
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions
        style={{
          justifyContent: "flex-start",
          padding: "8px 24px",
          marginBottom: "10px",
          marginTop: "32px",
        }}
      >
        <Button
          id="payment"
          color="primary"
          variant="contained"
          onClick={() => {
            goToPaymentPricingPlan(history);
            onClose();
          }}
        >
          Upgrade your subscription
        </Button>
        <Button
          id="cancel"
          variant="outlined"
          disableElevation
          onClick={onClose}
        >
          Cancel
        </Button>
      </DialogActions>
    </CustomDialog>
  );
};

export default LimitWarningDialog;

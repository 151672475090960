import { ParameterContext, SetContext } from "../types/campaign";
import { PushMessageTemplate } from "../types/pushNotification";
import { createContext, useContext, useState } from "react";
import { useListMessageTemplate } from "../utils/queries";
import _ from "lodash";

const PushNotificationSetContext = createContext<{
  pushMessageTemplate: PushMessageTemplate[];
  total: number;
  keySets: SetContext;
  isNoPushNotification: boolean;
}>({
  total: 0,
  pushMessageTemplate: [],
  keySets: {},
  isNoPushNotification: true,
});

const PushNotificationSetProdiver = (props: Object) => {
  const [keySets, setKeySets] = useState({} as SetContext);
  const [total, setTotal] = useState(0);
  const [pushMessageTemplate, setPushMessageTemplate] = useState<
    PushMessageTemplate[]
  >([]);
  const [isNoPushNotification, setIsNoPushNotification] = useState(false);
  useListMessageTemplate({
    onSuccess: (data) => {
      if (data) {
        setPushMessageTemplate(data);
        setTotal(data.length);
        setIsNoPushNotification(data.length === 0);
        const attributes = _.transform(
          data,
          (result, attribute) => {
            _.forEach(attribute, (value, key) => {
              if (typeof value === "string" && key !== "status") {
                if (!result[key]) {
                  result[key] = [];
                }
                if (value && !result[key].includes(value)) {
                  result[key].push(value);
                }
              }
            });
          },
          {} as ParameterContext
        );
        setKeySets(attributes);
      }
    },
  });

  return (
    <>
      <PushNotificationSetContext.Provider
        value={{ total, pushMessageTemplate, keySets, isNoPushNotification }}
        {...props}
      />
    </>
  );
};

const usePushNotificationSet = () => {
  const context = useContext(PushNotificationSetContext);

  if (context === undefined) {
    throw new Error(
      `usePushNotificationSet must be used within a PushNotificationSetProdiver`
    );
  }
  return context;
};

export { PushNotificationSetProdiver, usePushNotificationSet };

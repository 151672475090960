import { DialogContentProps } from "@material-ui/core";
import { Theme, createStyles, withStyles } from "@material-ui/core/styles";
import MuiDialogContent from "@material-ui/core/DialogContent";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      marginTop: 15,
    },
  });

const DialogContent = withStyles(styles)(
  ({ children, ...props }: DialogContentProps) => {
    return <MuiDialogContent {...props}>{children}</MuiDialogContent>;
  }
);

export default DialogContent;

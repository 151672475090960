import {
  Dialog,
  DialogTitle,
  Grid,
  IconButton,
  Theme,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import makeStyles from "@material-ui/styles/makeStyles";

import React from "react";
export interface ThanksSignUpDialogProps {
  open: boolean;
  onClose: () => void;
}
const useStyle = makeStyles((theme: Theme) => ({
  paper: {
    width: "624px",
    maxHeight: "240px",
    boxShadow: "0px 8px 32px rgba(0, 0, 0, 0.32)",
    overflow: "hidden",
    padding: "40px",
    borderRadius: "16px",
  },
  paperMobile: {
    padding: 24,
    width: "100vw",
    maxHeight: "100vh",
    boxShadow: "0px 8px 32px rgba(0, 0, 0, 0.32)",
    overflow: "hidden",
    height: "100vh",
    margin: 0,
    overflowX: "hidden",
  },
  root: {
    margin: "0px",
    padding: 0,
  },
}));

const ThanksSignUpDialog: React.FunctionComponent<ThanksSignUpDialogProps> = ({
  open,
  onClose,
}) => {
  const mdScreen = useMediaQuery("(min-width:768px)");
  const classes = useStyle();
  return (
    <Dialog
      PaperProps={{ className: mdScreen ? classes.paper : classes.paperMobile }}
      open={open}
      onClose={onClose}
    >
      <DialogTitle disableTypography className={classes.root}>
        <Grid container justify="space-between" alignItems="center">
          <Grid item>
            <Typography
              align="center"
              style={{
                fontFamily: "Work Sans",
                fontSize: "30px",
                fontWeight: 700,
                lineHeight: "40px",
              }}
            >
              Welcome to Nashpush 💚
            </Typography>
          </Grid>
          <Grid item>
            <Grid container alignItems="center">
              {onClose && (
                <Grid item>
                  <IconButton aria-label="close" onClick={onClose}>
                    <CloseIcon color="action" />
                  </IconButton>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </DialogTitle>
      <Typography
        align="left"
        style={{
          fontFamily: "Work Sans",
          fontSize: "22px",
          fontWeight: 400,
          lineHeight: "32px",
          marginTop: "21px",
          marginBottom: "32px",
        }}
      >
        Thanks for joining Nashpush!
      </Typography>
      <Typography
        align="left"
        style={{
          fontFamily: "Work Sans",
          fontSize: "22px",
          fontWeight: 400,
          lineHeight: "32px",
        }}
      >
        Please, check your inbox and confirm your email.
      </Typography>
    </Dialog>
  );
};

export default ThanksSignUpDialog;

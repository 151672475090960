/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  ParameterContext,
  SetContext,
} from "../types/subscriptionWindowsTemplate";
import { SubscriptionWindow } from "../types/channel";
import { createContext, useContext, useState } from "react";
import { useListAllSubscriptionWindowsDashboard } from "../utils/queries";
import { useListMessageTemplate } from "../utils/queries";
import _ from "lodash";

const SubscriptionWindowsSetContext = createContext<{
  subscriptionWindows: SubscriptionWindow[];
  total: number;
  keySets: SetContext;
  isNoSubscriptionWindows: boolean;
}>({
  total: 0,
  subscriptionWindows: [],
  keySets: {},
  isNoSubscriptionWindows: true,
});

const SubscriptionWindowsSetProdiver = (props: Object) => {
  const [keySets, setKeySets] = useState({} as SetContext);
  const [total, setTotal] = useState(0);
  const [subscriptionWindows, setSubscriptionWindows] = useState<
    SubscriptionWindow[]
  >([]);
  const [isNoSubscriptionWindows, setIsNoSubscriptionWindows] = useState(false);
  useListAllSubscriptionWindowsDashboard({
    onSuccess: (data) => {
      if (data) {
        setTotal(data?.rowCount);
        setIsNoSubscriptionWindows(!data?.rowCount || data?.rowCount === 0);
      }
    },
  });

  return (
    <>
      <SubscriptionWindowsSetContext.Provider
        value={{ total, subscriptionWindows, keySets, isNoSubscriptionWindows }}
        {...props}
      />
    </>
  );
};

const useSubscriptionWindowsSet = () => {
  const context = useContext(SubscriptionWindowsSetContext);

  if (context === undefined) {
    throw new Error(
      `useSubscriptionWindowsSet must be used within a SubscriptionWindowsSetProdiver`
    );
  }
  return context;
};

export { SubscriptionWindowsSetProdiver, useSubscriptionWindowsSet };

import { ErrorBoundary } from "react-error-boundary";
import { Helmet } from "react-helmet";
import { ReactQueryDevtools } from "react-query/devtools";
import { BrowserRouter as Router } from "react-router-dom";
import { SnackbarProvider } from "notistack";
import { useSelector } from "react-redux";
import DateFnsUtils from "@date-io/date-fns";
import React from "react";
import styled, {
  ThemeProvider,
  createGlobalStyle,
} from "styled-components/macro";

import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import {
  ThemeProvider as MuiThemeProvider,
  StylesProvider,
} from "@material-ui/core/styles";
import { QueryClient, QueryClientProvider } from "react-query";

import { AppStateType } from "./redux/reducers";
import {
  ArchiveRelatedProdiver,
  useArchiveRelated,
} from "./contexts/ArchiveRelatedContext";
import { AuthProvider, useAuth } from "./contexts/AuthContext";
import { CssBaseline } from "@material-ui/core";
import { GlobalStyleProps } from "./types/styles";
import {
  LimitationsProdiver,
  useLimitations,
} from "./contexts/LimitationsContext";
import { LoadingProdiver } from "./contexts/LoadingContext";
import { MenuProdiver, useMenuProdiver } from "./contexts/MenuContext";
import { NASHPUSH_ENVIRONMENT } from "./types/env";
import { PageConfigsProdiver } from "./contexts/PageConfigsContext";
import ArchiveWarningDialog from "./components/dialogs/ArchiveWarningDialog";
import LimitWarningDialog from "./components/dialogs/LimitWarningDialog";
import NavMenu from "./components/sidebars/NavMenu";
import Page500 from "./pages/auth/Page500";
import Routes from "./routes/Routes";
import Sidebar from "./components/sidebars/Sidebar";
import createTheme from "./theme";

const drawerWidth = 318;

const GlobalStyle = createGlobalStyle<GlobalStyleProps>`
  html,
  body,
  #root {
    height: 100%;
  }

  body {
    background: ${(props) => props.theme.palette.background.default};
  }

  .MuiCardHeader-action .MuiIconButton-root {
    padding: 4px;
    width: 28px;
    height: 28px;
  }
`;

const Root = styled.div`
  display: flex;
  min-height: 98vh;
`;

const Drawer = styled.div`
  ${(props) => props.theme.breakpoints.up("sm")} {
    flex-shrink: 0;
    display: flex;
  }
  ${(props) => props.theme.breakpoints.down("sm")} {
    display: none;
  }
`;

const Body = styled.div`
  ${(props) => props.theme.breakpoints.up("sm")} {
    width: 100%;
  }
  ${(props) => props.theme.breakpoints.down("sm")} {
    width: 100% !important;
  }
`;

const queryClient = new QueryClient();

function App() {
  const theme = useSelector((state: AppStateType) => state.themeReducer);

  return (
    <React.Fragment>
      <Helmet titleTemplate="%s | Nashpush" defaultTitle="Nashpush" />
      {NASHPUSH_ENVIRONMENT === "production" ? (
        <Helmet>
          <script>{` window.dataLayer = window.dataLayer || [];`}</script>
          <script>{`(function(w,d,s,l,i){w[l] = w[l] || [];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','GTM-KT38SBM');`}</script>
        </Helmet>
      ) : undefined}
      <QueryClientProvider client={queryClient}>
        <StylesProvider injectFirst>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <MuiThemeProvider theme={createTheme(theme.currentTheme)}>
              <ThemeProvider theme={createTheme(theme.currentTheme)}>
                <SnackbarProvider
                  maxSnack={3}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  autoHideDuration={3000}
                >
                  <LoadingProdiver>
                    <Router>
                      <ErrorBoundary
                        FallbackComponent={Page500}
                        onReset={() => {
                          window.localStorage.clear();
                          window.location.reload();
                        }}
                      >
                        <AuthProvider>
                          <PageConfigsProdiver>
                            <ArchiveRelatedProdiver>
                              <LimitationsProdiver>
                                <MenuProdiver>
                                  <AppRoutes />
                                </MenuProdiver>
                              </LimitationsProdiver>
                            </ArchiveRelatedProdiver>
                          </PageConfigsProdiver>
                        </AuthProvider>
                      </ErrorBoundary>
                    </Router>
                  </LoadingProdiver>
                </SnackbarProvider>
              </ThemeProvider>
            </MuiThemeProvider>
          </MuiPickersUtilsProvider>
        </StylesProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </React.Fragment>
  );
}

const AppRoutes = () => {
  const { auth, isEmailVerified } = useAuth();
  const { openArchiveRelatedDialog, handleClose } = useArchiveRelated();
  const { openLimitDialog, setOpenLimitDialog, isLimit } = useLimitations();
  const { isOpen } = useMenuProdiver();

  if (auth.token && isEmailVerified) {
    return (
      <Root>
        <CssBaseline />
        <GlobalStyle />
        <Drawer>
          <Sidebar
            PaperProps={{
              style: { width: drawerWidth },
            }}
            variant="temporary"
          />
        </Drawer>
        <Body
          style={{
            width: isOpen ? "calc(100% - 318px)" : "calc(100% - 80px)",
            height: "100vh",
          }}
        >
          <NavMenu />
          <Routes />
        </Body>
        <ArchiveWarningDialog
          open={openArchiveRelatedDialog}
          onClose={() => handleClose()}
        />
        <LimitWarningDialog
          open={openLimitDialog}
          onClose={() => setOpenLimitDialog(false)}
          isFree={isLimit}
        />
      </Root>
    );
  }
  return <Routes />;
};

export default App;

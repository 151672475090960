import { JourneyDashboardSetProdiver } from "../contexts/JourneyDashboardSetContext";
import Dashboard, { DashboardPropsType } from "./Dashboard";
import React from "react";
const JourneyDashboardLayout: React.FunctionComponent<DashboardPropsType> = (
  props
) => {
  return (
    <JourneyDashboardSetProdiver>
      <Dashboard {...props} />
    </JourneyDashboardSetProdiver>
  );
};

export default JourneyDashboardLayout;

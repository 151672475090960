import { IGNORE_QUERIES_STATUS } from "../constants";
import { createContext, useContext } from "react";
import { useIsFetching, useIsMutating, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";

const LoadingContext = createContext<{
  isLoading: boolean;
  onError: (msg: string) => void;
  onSuccess: (msg?: string) => void;
  onWarning: (msg?: string) => void;
}>({
  isLoading: false,
  onError: () => null,
  onWarning: () => null,
  onSuccess: () => null,
});

const LoadingProdiver = (props: Object) => {
  const { enqueueSnackbar: snackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const isFetching = useIsFetching({
    predicate: (fetch) => {
      return !IGNORE_QUERIES_STATUS.includes(fetch.queryKey[0] + "");
    },
  });
  const isMutating = useIsMutating({
    predicate: (mutation) => {
      return mutation.options.mutationKey !== "ignoreMutation";
    },
  });

  const onError = (msg: string) => snackbar(msg, { variant: "error" });
  const onSuccess = (msg?: string) => snackbar(msg, { variant: "success" });
  const onWarning = (msg?: string) => snackbar(msg, { variant: "warning" });
  const onErrorQuery = (error: any) => {
    error.message &&
      typeof error.message === "string" &&
      onError(error.message);
  };
  queryClient.setDefaultOptions({
    queries: { onError: onErrorQuery },
    mutations: { onError: onErrorQuery },
  });

  return (
    <LoadingContext.Provider
      value={{
        isLoading: isFetching > 0 || isMutating > 0,
        onError,
        onSuccess,
        onWarning,
      }}
      {...props}
    />
  );
};

const useLoading = () => {
  const context = useContext(LoadingContext);

  if (context === undefined) {
    throw new Error(`useLoading must be used within a LoadingProdiver`);
  }
  return context;
};

export { LoadingProdiver, useLoading };
